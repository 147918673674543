import {
    SET_BILLING_DETAILS,
    SET_DELETE_CONFIRMATION,
    SET_BILLING_COMPONENT_MODE,
    RESET_BILLING,
    SET_SELECTED_ADDRESS,
    SET_GUEST_BILLING_DETAILS
} from '../actions/billing.action';
import { billingIS } from '../initialState';
import store from '../../interfaces/store.Interface';

function billingHandler(state: store['billing'], action: any): store['billing'] {
    switch (action.type) {
        case SET_BILLING_DETAILS:
            return { ...state, billingAddress: [...action.data] };
        case SET_DELETE_CONFIRMATION:
            return { ...state, deleteBillingAddressId: action.addressId };
        case SET_BILLING_COMPONENT_MODE:
            return { ...state, mode: action.mode, editBillingAddressId: action.addressId };
        case SET_SELECTED_ADDRESS:
            return { ...state, selectedAddress: { ...state.selectedAddress, ...action.data } };
        case SET_GUEST_BILLING_DETAILS:
            return { ...state, guestBillingAddress: { ...action.data } };
        case RESET_BILLING:
            return { ...billingIS };
        default:
            return { ...state };
    }
}
export default billingHandler;
