import { startLoader, stopLoader, setToastMessage } from '../actions/app.action';
import {
    MSG_SET_DEFAULT_ADDRESS,
    TASK_GET_ADDRESS,
    TASK_SAVE_ADDRESS,
    TASK_UPDATE_ADDRESS,
    TASK_SET_DEFAULT_ADDRESS,
    TASK_DELETE_ADDRESS,
    MSG_DELETE_ADDRESS,
    TASK_SAVE_GUEST_ADDRESS
} from '../../utils/commonConstants';
import {
    getBillingDetailsAction,
    setBillingComponentModeAction,
    setBillingDetailsAction,
    setDeleteConfirmationAction,
    setGuestBillingDeatilsAction,
    setSelectedAddressAction
} from '../actions/billing.action';
import { setViewStatusAction } from '../actions/checkout.action';
import { getBillingAddress, setDefaultBillingAddress, deleteAddress, addBillingAddress, updateBillingAddress, addGuestBillingAddress } from '../../utils/ApiUrlMapping';
import { onTaskError } from './common.Middleware';
import addBillingAddressRequest from '../../interfaces/buyerBillingAddress.Interface';
import customTypes from '../../interfaces/customTypes.Interface';

export const getBillingDetailsMiddleware = (dispatch: CallableFunction, getState: customTypes['getState']): Promise<void> => {
    dispatch(startLoader());
    return getBillingAddress()
        .then((response) => {
            onTaskSuccess(dispatch, response, TASK_GET_ADDRESS, getState);
        })
        .catch((error) => {
            onTaskError(dispatch, error, TASK_GET_ADDRESS);
        });
};

export const setDefaultBillingAddressMiddleware = (dispatch: CallableFunction, getState: customTypes['getState'], addressId: string): Promise<void> => {
    dispatch(startLoader());
    return setDefaultBillingAddress(addressId)
        .then((response) => {
            onTaskSuccess(dispatch, response, TASK_SET_DEFAULT_ADDRESS, getState);
        })
        .catch((error) => {
            onTaskError(dispatch, error, TASK_SET_DEFAULT_ADDRESS);
        });
};

export const deleteAddressMiddleware = (dispatch: CallableFunction, getState: customTypes['getState'], addressId: string): Promise<void> => {
    dispatch(startLoader());
    return deleteAddress(addressId)
        .then((response) => {
            onTaskSuccess(dispatch, response, TASK_DELETE_ADDRESS, getState, { addressId });
        })
        .catch((error) => {
            onTaskError(dispatch, error, TASK_DELETE_ADDRESS);
        });
};

const checkBillingPageMode = (dispatch: CallableFunction, getState: customTypes['getState']): void => {
    if (getState().billing.billingAddress.length <= 0) {
        dispatch(setBillingComponentModeAction('add'));
    }
};

export const addBillingDetailsMiddleware = (addBillingRequest: addBillingAddressRequest, dispatch: CallableFunction, getState: customTypes['getState']): Promise<void> => {
    dispatch(startLoader());
    return addBillingAddress(addBillingRequest)
        .then((response) => {
            onTaskSuccess(dispatch, response, TASK_SAVE_ADDRESS, getState);
        })
        .catch((error) => {
            onTaskError(dispatch, error, TASK_SAVE_ADDRESS);
        });
};

export const addGuestBillingDetailsMiddleware = (billingAddress: addBillingAddressRequest, dispatch: CallableFunction, getState: customTypes['getState']): Promise<void> => {
    dispatch(startLoader());
    return addGuestBillingAddress(billingAddress)
        .then((response) => {
            onTaskSuccess(dispatch, response, TASK_SAVE_GUEST_ADDRESS, getState);
        })
        .catch((error) => {
            onTaskError(dispatch, error, TASK_SAVE_GUEST_ADDRESS);
        });
};

export const updateBillingDetailsMiddleware = (addBillingRequest: addBillingAddressRequest, dispatch: CallableFunction, getState: customTypes['getState']): Promise<void> => {
    dispatch(startLoader());
    return updateBillingAddress(addBillingRequest)
        .then((response) => {
            onTaskSuccess(dispatch, response, TASK_UPDATE_ADDRESS, getState);
        })
        .catch((error) => {
            onTaskError(dispatch, error, TASK_UPDATE_ADDRESS);
        });
};

const onTaskSuccess = (dispatch: CallableFunction, response: any, task: number, getState: customTypes['getState'], request?: any): void => {
    if (response.data.responseCode === 0) {
        dispatch(stopLoader());
        switch (task) {
            case TASK_GET_ADDRESS:
                dispatch(setBillingDetailsAction(response.data.responseObject.billingAddress));
                checkBillingPageMode(dispatch, getState);
                break;
            case TASK_SAVE_ADDRESS:
            case TASK_UPDATE_ADDRESS:
                dispatch(getBillingDetailsAction());
                dispatch(setBillingComponentModeAction('view'));
                break;
            case TASK_SAVE_GUEST_ADDRESS:
                dispatch(setGuestBillingDeatilsAction(response.data.responseObject));
                if (getState().checkout.viewStatus.deliveryMethodComponent === 'disabled') {
                    dispatch(setViewStatusAction({ billingComponent: 'completed', deliveryMethodComponent: 'enabled' }));
                } else {
                    dispatch(setViewStatusAction({ billingComponent: 'completed' }));
                }
                dispatch(setBillingComponentModeAction('view'));
                break;
            case TASK_SET_DEFAULT_ADDRESS:
                dispatch(getBillingDetailsAction());
                dispatch(setToastMessage(MSG_SET_DEFAULT_ADDRESS));
                break;
            case TASK_DELETE_ADDRESS:
                if (request?.hasOwnProperty('addressId')) {
                    let billingAddress = [...getState().billing.billingAddress];
                    billingAddress = billingAddress.filter((item): any => {
                        return item.addressId !== request.addressId;
                    });
                    let selectedBillingId = { ...getState().billing.selectedAddress };
                    if (request.addressId === selectedBillingId.addressId) {
                        dispatch(setSelectedAddressAction({ addressId: 0 }));
                    }
                    dispatch(setBillingDetailsAction(billingAddress));
                    dispatch(setToastMessage(MSG_DELETE_ADDRESS));
                    dispatch(setDeleteConfirmationAction(0));
                    checkBillingPageMode(dispatch, getState);
                }
                break;
            default:
                break;
        }
    } else {
        onTaskError(dispatch, response, task);
    }
};
