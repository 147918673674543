import { getShowsByIdsActionMiddleware, redirectToTicketListMiddleware } from '../middlewares/showList.Middleware';
import { showDetailsObject } from '../../interfaces/showList.Interface';
import customTypes from '../../interfaces/customTypes.Interface';

/**
 * action types
 */

export const SET_SHOW_LIST = 'SET_SHOW_LIST';
export const RESET_SHOW_LIST = 'RESET_SHOW_LIST';

/**
 * action creators
 */
export function setShowList(showList: showDetailsObject) {
    return {
        type: SET_SHOW_LIST,
        showList
    };
}

export function resetShowListAction() {
    return {
        type: RESET_SHOW_LIST
    };
}
export function getShowsByIdsAction(eventId: number) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return getShowsByIdsActionMiddleware(dispatch, eventId);
    };
}

export function redirectToTicketListAction(showId: number, eventId: number, categoryId: any) {
    return (dispatch: CallableFunction) => {
        return redirectToTicketListMiddleware(dispatch, showId, eventId, categoryId);
    };
}
