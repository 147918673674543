import { SET_BRANDING_DETAILS, RESET_BRANDING_DETAILS } from '../actions/brandingDetails';
import store from '../../interfaces/store.Interface';

function brandingDetailsHandler(state: store['brandingDetails'], action: any): store['brandingDetails'] {
    switch (action.type) {
        case SET_BRANDING_DETAILS:
            return { ...state, ...action.brandingDetails };
        case RESET_BRANDING_DETAILS:
            return { ...state, ...action.brandingIS };
        default:
            return { ...state };
    }
}

export default brandingDetailsHandler;
