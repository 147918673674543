import { SET_ORDER_HISTORY, RESET_ORDER_HISTORY } from '../actions/orderHistory';
import store from '../../interfaces/store.Interface';
import { orderHistoryIS } from '../initialState';

function orderHistoryHandler(state: store['orderHistory'], action: any): store['orderHistory'] {
    switch (action.type) {
        case SET_ORDER_HISTORY:
            return { ...state, totalOrders: action.orderHistory.totalOrders, buyerOrderHistoryVOs: [...action.orderHistory.buyerOrderHistoryVOs] };
        case RESET_ORDER_HISTORY:
            return { ...orderHistoryIS };
        default:
            return { ...state };
    }
}
export default orderHistoryHandler;
