import { stopLoader, setValidationMessage, setToastMessage } from '../actions/app.action';
import {
    TASK_GET_ADDRESS,
    ERROR_GET_ADDRESS,
    TASK_SAVE_ADDRESS,
    ERROR_SAVE_ADDRESS,
    TASK_UPDATE_ADDRESS,
    TASK_SET_DEFAULT_ADDRESS,
    ERROR_SET_DEFAULT_ADDRESS,
    TASK_DELETE_ADDRESS,
    ERROR_DELETE_ADDRESS,
    ERROR_UNABLE_PROCESS,
    TASK_GET_SQUARE_MERCHANT_DETAILS,
    TASK_SEND_SUPPORT,
    TASK_SAVE_GUEST_ADDRESS,
    TASK_GET_ORDER_HISTORY_ID,
    TASK_GET_ORDER_HISTORY_DATE,
    TASK_GET_BRANDING,
    TASK_GET_WALLET_TICKET,
    ERROR_FETCH,
    GENERIC_PAYMENT_ERROR_MESSAGE,
    TASK_GET_BLUEFIN_MERCHANT_DETAILS,
    TASK_GET_HOSTED_TRANSACTION_ID,
    ERROR_EVENT_DETAILS,
    TASK_GET_FILTER_EVENT_LIST,
    MSG_NOT_FOUND,
    TASK_GET_EVENT_LIST,
    TASK_SEARCH_EVENT_LIST,
    ERROR_PLACE_ORDER_TIMEOUT,
    TASK_PLACE_ORDER,
    TASK_PLACE_ORDER_GUEST,
    TASK_PLACE_ORDER_CART_RESERVATION,
    TASK_PLACE_ORDER_GUEST_CART_RESERVATION,
    TASK_RESERVE_CART,
    ERROR_RESERVE_CART,
    TASK_APPLY_PROMO,
    ERROR_APPLY_PROMO
} from '../../utils/commonConstants';

const taskErrorMap = [
    { task: TASK_GET_ADDRESS, message: ERROR_GET_ADDRESS },
    { task: TASK_SAVE_ADDRESS, message: ERROR_SAVE_ADDRESS },
    { task: TASK_UPDATE_ADDRESS, message: ERROR_SAVE_ADDRESS },
    { task: TASK_SET_DEFAULT_ADDRESS, message: ERROR_SET_DEFAULT_ADDRESS },
    { task: TASK_DELETE_ADDRESS, message: ERROR_DELETE_ADDRESS },
    { task: TASK_SEND_SUPPORT, message: ERROR_UNABLE_PROCESS },
    { task: TASK_GET_ORDER_HISTORY_ID, message: ERROR_FETCH },
    { task: TASK_GET_ORDER_HISTORY_DATE, message: ERROR_FETCH },
    { task: TASK_GET_BRANDING, message: ERROR_FETCH },
    { task: TASK_SAVE_GUEST_ADDRESS, message: ERROR_SAVE_ADDRESS },
    { task: TASK_GET_WALLET_TICKET, message: ERROR_FETCH },
    { task: TASK_GET_SQUARE_MERCHANT_DETAILS, message: GENERIC_PAYMENT_ERROR_MESSAGE },
    { task: TASK_GET_BLUEFIN_MERCHANT_DETAILS, message: GENERIC_PAYMENT_ERROR_MESSAGE },
    { task: TASK_GET_HOSTED_TRANSACTION_ID, message: GENERIC_PAYMENT_ERROR_MESSAGE },
    { task: TASK_GET_EVENT_LIST, message: ERROR_EVENT_DETAILS },
    { task: TASK_GET_FILTER_EVENT_LIST, message: ERROR_EVENT_DETAILS },
    { task: TASK_SEARCH_EVENT_LIST, message: MSG_NOT_FOUND },
    { task: TASK_PLACE_ORDER, message: ERROR_PLACE_ORDER_TIMEOUT },
    { task: TASK_PLACE_ORDER_GUEST, message: ERROR_PLACE_ORDER_TIMEOUT },
    { task: TASK_PLACE_ORDER_CART_RESERVATION, message: ERROR_PLACE_ORDER_TIMEOUT },
    { task: TASK_PLACE_ORDER_GUEST_CART_RESERVATION, message: ERROR_PLACE_ORDER_TIMEOUT },
    { task: TASK_RESERVE_CART, message: ERROR_RESERVE_CART },
    { task: TASK_APPLY_PROMO, message: ERROR_APPLY_PROMO }
];

const showMessage = (dispatch: CallableFunction, showToast = false, message: string, htmlAlert = false): void => {
    if (showToast) {
        dispatch(setToastMessage(message));
    } else {
        dispatch(setValidationMessage(message, htmlAlert));
    }
};

export const onTaskError = (dispatch: CallableFunction, errorResponse: any, task: number, customErrorMessage = '', showToast = false, htmlAlert = false): void => {
    dispatch(stopLoader());
    if (customErrorMessage !== '') {
        showMessage(dispatch, showToast, customErrorMessage);
    } else {
        let message = '';
        if (errorResponse && errorResponse.data && errorResponse.data.responseMessage) {
            message = errorResponse.data.responseMessage;
        } else {
            const errorObj = taskErrorMap.find((item): any => {
                return item.task === task;
            });
            if (errorObj && Object.keys(errorObj).length) {
                message = errorObj['message'];
            } else {
                message = ERROR_UNABLE_PROCESS;
            }
        }
        showMessage(dispatch, showToast, message, htmlAlert);
    }
    if (errorResponse === 500) {
        dispatch(setValidationMessage(ERROR_UNABLE_PROCESS));
    }
};
