import { setToSessionStorage, setTimerInitiationMoment } from '../../utils/utility';
import { toast } from 'react-toastify';
import store from '../../interfaces/store.Interface';
import customTypes from '../../interfaces/customTypes.Interface';

export const SAVE_CUSTOMER_ID = 'SAVE_CUSTOMER_ID';
export const TOGGLE_SIGNUP = 'TOGGLE_SIGNUP';
export const TOGGLE_SEARCH = 'TOGGLE_SEARCH';
export const TOGGLE_SIGNIN = 'TOGGLE_SIGNIN';
export const TOGGLE_IS_CART_LOGIN = 'TOGGLE_IS_CART_LOGIN';
export const SET_VALIDATION_MESSAGE = 'SET_VALIDATION_MESSAGE';
export const INC_SERVICE_CALL_COUNT = 'INC_SERVICE_CALL_COUNT';
export const DEC_SERVICE_CALL_COUNT = 'DEC_SERVICE_CALL_COUNT';
export const SET_BACKGROUND_MESSAGE = 'SET_BACKGROUND_MESSAGE';
export const TOGGLE_FORGOT_PASSWORD = 'TOGGLE_FORGOT_PASSWORD';
export const TOGGLE_CHANGE_PASSWORD = 'TOGGLE_CHANGE_PASSWORD';
export const SET_TOAST_MESSAGE = 'SET_TOAST_MESSAGE';
export const SET_GA_PARAM = 'SET_GA_PARAM';
export const RESET_GA_PARAM = 'RESET_GA_PARAM';
export const SET_IS_RESET_TICKET_LIST_REQUIRED = 'SET_IS_RESET_TICKET_LIST_REQUIRED';
export const RESET_SUPPORT_FORM = 'RESET_SUPPORT_FORM';
export const SET_TIMER_VISIBILITY = 'SET_TIMER_VISIBILITY';
export const SET_TICKET_LIST_REDIRECTION_DETAILS = 'SET_TICKET_LIST_REDIRECTION_DETAILS';

export function saveCustomerId(customerId: string) {
    return {
        type: SAVE_CUSTOMER_ID,
        customerId
    };
}

export function toggleSignupAcion() {
    return {
        type: TOGGLE_SIGNUP
    };
}

export function toggleSigninAction() {
    return {
        type: TOGGLE_SIGNIN
    };
}

export function toggleIsCartLogin() {
    return {
        type: TOGGLE_IS_CART_LOGIN
    };
}

export function toggleChangePasswordAction() {
    return {
        type: TOGGLE_CHANGE_PASSWORD
    };
}

export function toggleSigninCartViewAction() {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        dispatch(toggleIsCartLogin());
        dispatch(toggleSigninAction());
    };
}

export function setValidationMessage(validationMessage: string, htmlAlert: store['app']['htmlAlert'] = false) {
    return {
        type: SET_VALIDATION_MESSAGE,
        validationMessage,
        htmlAlert
    };
}

export function setToastMessage(toastMessage: string, toastId = '') {
    if (toastId) {
        toast(toastMessage, { toastId });
    } else {
        toast(toastMessage);
    }
    return {
        type: SET_TOAST_MESSAGE,
        toastMessage
    };
}

export function startLoader() {
    return {
        type: INC_SERVICE_CALL_COUNT
    };
}

export function stopLoader() {
    return {
        type: DEC_SERVICE_CALL_COUNT
    };
}

export function expandSearchFieldAction(expandSearch: boolean) {
    return {
        type: TOGGLE_SEARCH,
        expandSearch
    };
}

export function setBackgroundMessage(message: string) {
    return {
        type: SET_BACKGROUND_MESSAGE,
        message
    };
}

export function setIsResetTicketListRequired(flag: boolean) {
    return {
        type: SET_IS_RESET_TICKET_LIST_REQUIRED,
        flag
    };
}

export function saveCustomerIdAction(customerId: string) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        setToSessionStorage('CUSTOMER_ID', customerId);
        dispatch(saveCustomerId(customerId));
    };
}

export function toggleForgotPasswordAction() {
    return {
        type: TOGGLE_FORGOT_PASSWORD
    };
}

export function setGoogleAnalyticsParam(gaObject: Object) {
    return {
        type: SET_GA_PARAM,
        gaObject
    };
}

export function resetGoogleAnalyticsParam() {
    return {
        type: RESET_GA_PARAM
    };
}

export function setTimerVisibilityAction(status: boolean, timestampReset = true) {
    if (timestampReset) {
        // This condition is for preventing timer timestamp reset when we enable timer
        // during page reload.
        setTimerInitiationMoment(status);
    }
    return {
        type: SET_TIMER_VISIBILITY,
        status
    };
}

export function setTickeListRedirectionDetailsAction(data: store['app']['ticketListRedirectDetails']) {
    return {
        type: SET_TICKET_LIST_REDIRECTION_DETAILS,
        data
    };
}

export function resetSupportFormStatusAction(status: boolean) {
    return {
        type: RESET_SUPPORT_FORM,
        status
    };
}
