import { SET_SHOW_LIST, RESET_SHOW_LIST } from '../actions/showList';
import store from '../../interfaces/store.Interface';
import { showDetailsIS } from '../initialState';

function showListHandler(state: store['showDetails'], action: any): store['showDetails'] {
    switch (action.type) {
        case SET_SHOW_LIST:
            return { ...state, showList: { ...action.showList } };
        case RESET_SHOW_LIST:
            return { ...state, showList: { ...showDetailsIS.showList } };
        default:
            return { ...state };
    }
}
export default showListHandler;
