import HttpClient from './HttpClient';
import {
    CUSTOM_BRANDING_DETAILS,
    TICKET_LIST_URL,
    VENUE_DETAIL_URL,
    EVENT_LIST_URL,
    SEARCH_EVENT_URL,
    FILTER_EVENTS_URL,
    SHOWS_BY_ID,
    GET_ORIGINAL_USER_NAME,
    RESET_PASSWORD,
    CHANGE_PASSWORD,
    BUYER_SUPPORT_EMAIL,
    SIGN_UP,
    FEE_TAX_DETAILS,
    BILLING_SETTINGS_ADDRESS_URL,
    SET_DEFAULT_ADDRESS_URL,
    DELETE_ADDRESS_URL,
    GET_ORDER_HISTORY,
    GET_ORDER_HISTORY_BY_ID,
    PLACE_ORDER,
    PLACE_ORDER_GUEST,
    GET_DELIVERY_OPTIONS,
    GET_CUSTOMER_SALE_PERMISSION,
    PREFERRED_PAYMENT,
    GUEST_ADDRESS_URL,
    SET_WALLET_TICKET_URL,
    SQUARE_MERCHANT_DETAILS_URL,
    BLUEFIN_MERCHANT_DETAILS_URL,
    CREATE_HOSTED_TRANSACTION_URL,
    PROPAY_RESULT_URL,
    GET_CART_RESERVATION_DETAILS,
    PLACE_ORDER_CART_RESERVATION,
    PLACE_ORDER_GUEST_CART_RESERVATION,
    RESERVE_CART,
    RELEASE_CART,
    APPLY_PROMO,
    RELEASE_PROMO
} from './apiEndPoints';
import { ReleaseCartReq } from '../interfaces/cartReservation.Inerface';
import changePasswordRequest from '../interfaces/changePassword.Inteface';
import { supportBuyerEmailRequest } from '../interfaces/support.Interface';
import addBillingAddressRequest from '../interfaces/buyerBillingAddress.Interface';
import { getPromoReservationId, addUrlParams } from '../utils/utility';

export async function getCustomBrandingDetails(customerId: string) {
    return HttpClient.get(CUSTOM_BRANDING_DETAILS(encodeURIComponent(customerId)));
}

export async function getTicketsForEvents(customerId: string, eventId: any, showId: any, categoryId: any) {
    return HttpClient.get(TICKET_LIST_URL(customerId, eventId, showId, categoryId));
}

export async function getVenueDetails(venueDetailsRequest: any) {
    return HttpClient.post(VENUE_DETAIL_URL, venueDetailsRequest);
}

export async function getEventList(customerId: any, pageNumber: number) {
    return HttpClient.get(EVENT_LIST_URL(customerId, pageNumber));
}

export async function getSearchKeyEvent(searchRequest: object) {
    return HttpClient.post(SEARCH_EVENT_URL, searchRequest);
}

export async function getFilterEvents(filterRequest: object) {
    return HttpClient.post(FILTER_EVENTS_URL, filterRequest);
}

export async function getShowsByEventId(ids: any, customerId: number) {
    return HttpClient.post(SHOWS_BY_ID(customerId), ids);
}

export async function getOriginalUserName() {
    return HttpClient.get(GET_ORIGINAL_USER_NAME);
}

export async function resetPassword(resetObj: object) {
    return HttpClient.post(RESET_PASSWORD, resetObj);
}

export async function changePassword(request: changePasswordRequest) {
    return HttpClient.post(CHANGE_PASSWORD, request);
}

export async function sendBuyerSupportEmail(supportEmailRequest: supportBuyerEmailRequest) {
    return HttpClient.post(BUYER_SUPPORT_EMAIL, supportEmailRequest);
}

export async function getSignupURL(signUpRequest: object) {
    return HttpClient.post(SIGN_UP, signUpRequest);
}

export async function getFeeTaxDetails(feeTaxRequest: object) {
    return HttpClient.post(FEE_TAX_DETAILS, feeTaxRequest);
}

export async function getBillingAddress() {
    return HttpClient.get(BILLING_SETTINGS_ADDRESS_URL);
}

export async function addBillingAddress(addBillingAddress: addBillingAddressRequest) {
    return HttpClient.post(BILLING_SETTINGS_ADDRESS_URL, addBillingAddress);
}

export async function updateBillingAddress(addBillingAddress: addBillingAddressRequest) {
    return HttpClient.put(BILLING_SETTINGS_ADDRESS_URL, addBillingAddress);
}

export async function setDefaultBillingAddress(addressId: string) {
    return HttpClient.get(SET_DEFAULT_ADDRESS_URL(addressId));
}

export async function deleteAddress(addressId: string) {
    return HttpClient.delete(DELETE_ADDRESS_URL(addressId));
}

export async function addGuestBillingAddress(billingAddress: addBillingAddressRequest) {
    return HttpClient.post(GUEST_ADDRESS_URL, billingAddress);
}

export async function getOrderHistory(orderHistoryObject: object) {
    return HttpClient.post(GET_ORDER_HISTORY, orderHistoryObject);
}

export async function getOrderHistoryById(orderHistoryObject: object) {
    return HttpClient.post(GET_ORDER_HISTORY_BY_ID, orderHistoryObject);
}

export async function placeOrder(placeOrderRequest: object) {
    return HttpClient.post(PLACE_ORDER, placeOrderRequest);
}

export async function placeOrderGuest(placeOrderRequest: object) {
    return HttpClient.post(PLACE_ORDER_GUEST, placeOrderRequest);
}

export async function placeOrderCartReservation(placeOrderRequest: object) {
    return HttpClient.post(PLACE_ORDER_CART_RESERVATION, placeOrderRequest);
}

export async function placeOrderGuestCartReservation(placeOrderRequest: object) {
    return HttpClient.post(PLACE_ORDER_GUEST_CART_RESERVATION, placeOrderRequest);
}

export async function getWalletTicketDetails(uniqueId: string) {
    return HttpClient.get(SET_WALLET_TICKET_URL(uniqueId));
}

export async function getDeliveryOption(deliveryOptionRequest: Object) {
    return HttpClient.post(GET_DELIVERY_OPTIONS, deliveryOptionRequest);
}

export async function getCustomerSalePermission(customerId: string) {
    return HttpClient.get(GET_CUSTOMER_SALE_PERMISSION(customerId));
}

export async function getPreferedPaymentType(customerId: Number) {
    return HttpClient.get(PREFERRED_PAYMENT(customerId));
}

export async function getSquareMerchantDetails(customerId: number) {
    return HttpClient.get(SQUARE_MERCHANT_DETAILS_URL(customerId));
}

export async function createHostedTransaction(customerId: number, paymentRequest: object) {
    return HttpClient.post(CREATE_HOSTED_TRANSACTION_URL(customerId), paymentRequest);
}

export async function getPropayResult(dataObj: Object) {
    return HttpClient.post(PROPAY_RESULT_URL, dataObj);
}

export async function getCartReservationDetails(customerId: string) {
    return HttpClient.get(GET_CART_RESERVATION_DETAILS(encodeURIComponent(customerId)));
}

export async function getBluefinMerchantDetail(customerId: number) {
    return HttpClient.get(BLUEFIN_MERCHANT_DETAILS_URL(customerId));
}

export async function reserveCart(dataObj: Object) {
    return HttpClient.put(RESERVE_CART, dataObj);
}

export async function releaseCart(dataObj: ReleaseCartReq) {
    return HttpClient.post(RELEASE_CART, dataObj);
}

export async function applyPromo(promoRequest: object) {
    return HttpClient.post(APPLY_PROMO, promoRequest);
}

export async function releasePromo(customerId: string, promoId: any) {
    return HttpClient.get(RELEASE_PROMO(customerId, promoId));
}
