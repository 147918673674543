import clonedeep from 'lodash.clonedeep';
import brandingDetails from './brandingDetails';
import ticketList from './ticketList';
import showList from './showList';
import saveHandler from './app.reducer';
import eventList from './eventList';
import userDetailsHandler from './login.reducer';
import saveSignUpDetails from './signUp.reducer';
import checkoutHandler from './checkout.reducer';
import billingHandler from './billing.reducer';
import orderHistoryHandler from './orderHistory.reducer';
import checkoutSummaryHandler from './checkoutSummary.reducer';
import walletTicketHandler from './walletTicket.reducer';
import {
    viewStatusIS,
    billingIS,
    paymentsIS,
    eventDetailsIS,
    purchasedTicketDetailsIS,
    feeTaxDetailsIS,
    brandingIS,
    orderHistoryIS,
    showDetailsIS,
    eventListIS,
    ticketDeliveryOptionsIS,
    promoCodeDetailsIS
} from '../initialState';
import paymentsReducer from './payments.reducer';
import store from '../../interfaces/store.Interface';
import customTypes from '../../interfaces/customTypes.Interface';

export const initialState: store = {
    brandingDetails: { ...brandingIS },
    userDetails: {
        isUserLoggedIn: false,
        isSessionExpired: true,
        buyerDetails: {
            buyerName: '',
            name: '',
            email: '',
            role: ''
        }
    },
    eventListData: { ...eventListIS },
    ticketDetails: {
        ticketList: [],
        venueDetails: [],
        purchasedTicketDetails: { ...purchasedTicketDetailsIS },
        eventDetails: { ...eventDetailsIS }
    },
    showDetails: { ...showDetailsIS },
    app: {
        customerId: '',
        showSignupDialog: false,
        validationMessage: '',
        htmlAlert: false,
        serviceCallProgressCount: 0,
        toggleSearch: false,
        message: '',
        showSigninDialog: false,
        isCartLogin: false,
        showForgotPasswordDialog: false,
        showChangePasswordDialog: false,
        googleAnalytics: {},
        isResetTicketListRequired: false,
        resetSupportForm: false,
        showTimer: false,
        ticketListRedirectDetails: {
            eventId: 0,
            showId: 0
        }
    },
    signUp: {
        fields: {
            firstName: '',
            lastName: '',
            username: '',
            email: '',
            password: '',
            confirmPassword: '',
            status: 1,
            isCheckoutSummary: false
        },
        reCaptchaResponse: false,
        signupConfirm: false
    },
    checkout: {
        feeTaxDetails: { ...feeTaxDetailsIS },
        promoCodeDetails: { ...promoCodeDetailsIS },
        viewStatus: { ...viewStatusIS },
        ticketDeliveryOptions: { ...ticketDeliveryOptionsIS },
        customerSalePermission: {
            willCall: 0
        },
        preferredPayment: ''
    },
    billing: { ...billingIS },
    orderHistory: { ...orderHistoryIS },
    checkoutSummary: {
        showShareWindowDialog: false,
        media: ''
    },
    walletTicket: {
        buyerFirstName: '',
        buyerLastName: '',
        logoUrl: '',
        pdfUrl: '',
        transactionID: 0,
        ticketDetailCheck: false,
        ticketDetails: []
    },
    payments: { ...paymentsIS }
};

//combine all the reducer
export default function buyerApp(state: customTypes['stateShape'], action: any): store {
    if (!state) {
        //define the initial state of your store
        state = clonedeep(initialState);
    }
    return {
        brandingDetails: brandingDetails(state.brandingDetails, action),
        userDetails: userDetailsHandler(state.userDetails, action),
        ticketDetails: ticketList(state.ticketDetails, action),
        app: saveHandler(state.app, action),
        eventListData: eventList(state.eventListData, action),
        showDetails: showList(state.showDetails, action),
        signUp: saveSignUpDetails(state.signUp, action),
        checkout: checkoutHandler(state.checkout, action),
        billing: billingHandler(state.billing, action),
        orderHistory: orderHistoryHandler(state.orderHistory, action),
        checkoutSummary: checkoutSummaryHandler(state.checkoutSummary, action),
        walletTicket: walletTicketHandler(state.walletTicket, action),
        payments: paymentsReducer(state.payments, action)
    };
}
