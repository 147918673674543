import * as rax from 'retry-axios';
import axios, { Method } from 'axios';
import raxConfig from './AxiosRetryConfig';
import { getBaseUrl } from './constantsMapping';
import history from '../utils/history';
import { CognitoUserPool, CognitoRefreshToken } from 'amazon-cognito-identity-js';
import { getFromSessionStorage, getFromLocalStorage, setToLocalStorage, setToSessionStorage, handleInvalidSession, getRedirectionUrl } from '../utils/utility';
import { getCongnitoPoolData, getIdentityPoolId, getCognitoSessionExpiryTime } from './constantsMapping';
import { PATH_EVENT_LIST } from '../utils/routes';
import * as AWS from 'aws-sdk';
import aws4 from 'aws4';

let setHeader = (url: string, data: any, type: string, baseUrl: string): any => {
    return new Promise(function (resolve, reject) {
        const userDetails = getFromLocalStorage('_BUYER_USER_DETAILS');
        const poolData: any = getCongnitoPoolData();
        const userPool = new CognitoUserPool(poolData);
        const cognitoUser = userPool.getCurrentUser();
        const hostName = baseUrl.split('/')[2];
        const opts: any = {
            host: hostName,
            method: type,
            path: '/' + url,
            service: 'execute-api',
            region: 'us-west-2',
            headers: {},
            body: data ? JSON.stringify(data) : ''
        };
        if (type === 'POST' || type === 'PUT') {
            opts.headers['Content-Type'] = 'application/json';
        }
        let request = opts;
        // const isTimerExpired: boolean = AppUtil.getTimerValidity();
        AWS.config.region = 'us-west-2';
        if (['', '/', '/landing', '/paymentProgress'].includes(history.location.pathname) || history.location.pathname.includes('/pkpass')) {
            resolve(request);
        } else {
            let customerId = getFromSessionStorage('CUSTOMER_ID');
            let customerIdEncoded = btoa(customerId);
            if (customerId && Number(customerId) !== -1) {
                if (Object.keys(userDetails).length !== 0) {
                    //is logged in
                    handleLoggedInSession(cognitoUser, customerIdEncoded, opts, poolData, resolve, reject, request);
                } else {
                    // not logged in
                    handleGuestSession(customerIdEncoded, resolve, request);
                }
            } else {
                // if customer id not present in URL
                history.push('/landing');
                setToSessionStorage('BRANDING_DETAILS', null);
            }
        }
    });
};

let handleLoggedInSession = (cognitoUser: any, customerIdEncoded: string, opts: any, poolData: any, resolve: any, reject: any, request: any) => {
    if (history.location.pathname === `/${customerIdEncoded}/`) {
        resolve(request);
    } else {
        if (cognitoUser) {
            handleCognitoSession(cognitoUser, customerIdEncoded, opts, poolData, resolve, reject);
        } else {
            handleInvalidSession(customerIdEncoded);
        }
    }
};

let handleGuestSession = (customerIdEncoded: string, resolve: any, request: any) => {
    AWS.config.credentials = null;
    if (history.location.pathname === `/${customerIdEncoded}/order-history` || history.location.pathname === `/${customerIdEncoded}/billing`) {
        history.push(getRedirectionUrl(PATH_EVENT_LIST, { customerId: customerIdEncoded }));
    } else {
        request.headers = {
            'Cache-Control': 'no-cache, max-age=0, no-store, must-revalidate',
            Pragma: 'no-cache',
            'Device-Type': 'Web-Buyer',
            'Content-Type': 'application/json'
        };
        resolve(request);
    }
};

let handleCognitoSession = (cognitoUser: any, customerIdEncoded: string, opts: any, poolData: any, resolve: any, reject: any) => {
    let refreshToken: any;
    const tokenExpiryTime = getFromLocalStorage('BUYER_SESSION_EXPIRY_TIME');
    if (tokenExpiryTime && tokenExpiryTime.sessionExpiryTime && new Date().getTime() >= tokenExpiryTime.sessionExpiryTime) {
        if (tokenExpiryTime.refreshToken) {
            if (tokenExpiryTime.refreshTokenExpiryTime && new Date().getTime() >= tokenExpiryTime.refreshTokenExpiryTime) {
                handleInvalidSession(customerIdEncoded);
            } else {
                refreshToken = new CognitoRefreshToken({
                    RefreshToken: tokenExpiryTime.refreshToken
                });
                cognitoUser.refreshSession(refreshToken, (err: any, session: any) => {
                    if (err) {
                        handleInvalidSession(customerIdEncoded);
                    }
                    if (session && session.isValid()) {
                        setToLocalStorage('BUYER_SESSION_EXPIRY_TIME', {
                            sessionExpiryTime: new Date().getTime() + getCognitoSessionExpiryTime(),
                            refreshTokenExpiryTime: tokenExpiryTime.refreshTokenExpiryTime,
                            refreshToken: session.getRefreshToken().token
                        });
                        handleRolebasedAuthentication(opts, session, customerIdEncoded, poolData)
                            .then((requestObj: any) => {
                                resolve(requestObj);
                            })
                            .catch((requestObj: any) => {
                                reject(requestObj);
                            });
                    } else {
                        handleInvalidSession(customerIdEncoded);
                    }
                });
            }
        } else {
            handleInvalidSession(customerIdEncoded);
        }
    } else {
        cognitoUser.getSession((err: any, currentSession: any) => {
            if (err) {
                handleInvalidSession(customerIdEncoded);
            } else {
                if (currentSession && currentSession.isValid()) {
                    handleRolebasedAuthentication(opts, currentSession, customerIdEncoded, poolData)
                        .then((requestObj: any) => {
                            resolve(requestObj);
                        })
                        .catch((requestObj: any) => {
                            reject(requestObj);
                        });
                } else {
                    handleInvalidSession(customerIdEncoded);
                }
            }
        });
    }
};

let handleRolebasedAuthentication = (opts: any, session: any, customerId: string, poolData: any): any => {
    return new Promise(function (resolve: any, reject: any) {
        let request: any;
        const userDetails = getFromLocalStorage('_BUYER_USER_DETAILS');
        const credentialsDetails = getFromSessionStorage('_ROLE_AUTH_DETAILS');
        const userIdentityDetails = getFromSessionStorage('_ROLE_IDENTITY_PARAMS');
        const identity = new AWS.CognitoIdentityCredentials(userIdentityDetails);
        if (userDetails && userDetails.role && userDetails.role === 'Buyer') {
            if (userIdentityDetails.Logins && credentialsDetails.Credentials) {
                if (
                    userIdentityDetails.Logins[`cognito-idp.${AWS.config.region}.amazonaws.com/${poolData.UserPoolId}`] !== session.getIdToken().jwtToken ||
                    new Date(credentialsDetails.Credentials.Expiration).getTime() < new Date().getTime()
                ) {
                    refreshIdentityParams(opts, identity, session, poolData, customerId)
                        .then((requestObj: any) => {
                            request = setRequestDetails(requestObj, session);
                            resolve(request);
                        })
                        .catch((requestObj: any) => {
                            reject(requestObj);
                        });
                } else {
                    if (credentialsDetails.Credentials) {
                        request = addIdentityParams(opts, credentialsDetails, session);
                        request = setRequestDetails(request, session);
                        resolve(request);
                    } else {
                        AWS.config.credentials = null;
                        history.push(getRedirectionUrl(PATH_EVENT_LIST, { customerId }));
                    }
                }
            } else {
                fetchIdentityParams(opts, session, poolData)
                    .then((requestObj: any) => {
                        request = setRequestDetails(requestObj, session);
                        resolve(request);
                    })
                    .catch((requestObj: any) => {
                        reject(requestObj);
                    });
            }
        } else {
            handleInvalidSession(customerId);
        }
    });
};

let refreshIdentityParams = (opts: any, identity: any, session: any, poolData: any, customerId: string): any => {
    return new Promise(function (resolve, reject) {
        identity.params.Logins[`cognito-idp.${AWS.config.region}.amazonaws.com/${poolData.UserPoolId}`] = session.getIdToken().jwtToken;
        identity.refresh((err: any) => {
            if (err) {
                history.push(getRedirectionUrl(PATH_EVENT_LIST, { customerId }));
                reject(err);
            } else {
                const request = aws4.sign(opts, {
                    accessKeyId: identity.data.Credentials.AccessKeyId,
                    secretAccessKey: identity.data.Credentials.SecretKey,
                    sessionToken: identity.data.Credentials.SessionToken
                });
                delete request.headers.Host;
                setToSessionStorage('_ROLE_AUTH_DETAILS', identity.data);
                setToSessionStorage('_ROLE_IDENTITY_PARAMS', identity.params);
                resolve(request);
            }
        });
    });
};

let addIdentityParams = (opts: any, credentialsDetails: any, session: any): any => {
    const request = aws4.sign(opts, {
        accessKeyId: credentialsDetails.Credentials.AccessKeyId,
        secretAccessKey: credentialsDetails.Credentials.SecretKey,
        sessionToken: credentialsDetails.Credentials.SessionToken
    });
    delete request.headers.Host;
    return request;
};

let fetchIdentityParams = (opts: any, session: any, poolData: any): any => {
    return new Promise(function (resolve, reject) {
        const identity = new AWS.CognitoIdentity();
        const cognitoParams: any = {
            IdentityPoolId: getIdentityPoolId(),
            Logins: {}
        };
        cognitoParams.Logins[`cognito-idp.${AWS.config.region}.amazonaws.com/${poolData.UserPoolId}`] = session.getIdToken().jwtToken;
        identity.getId(cognitoParams, function (err: any, identityData: any) {
            if (err) {
                reject(err);
                return console.error(err);
            }
            const identityParams: any = {
                IdentityId: identityData.IdentityId,
                Logins: {}
            };
            identityParams.Logins[`cognito-idp.${AWS.config.region}.amazonaws.com/${poolData.UserPoolId}`] = session.getIdToken().jwtToken;
            setToSessionStorage('_ROLE_IDENTITY_PARAMS', cognitoParams);
            identity.getCredentialsForIdentity(identityParams, function (err: any, data: any) {
                if (err) {
                    reject(err);
                    return console.error(err);
                }
                const request = aws4.sign(opts, {
                    accessKeyId: data.Credentials.AccessKeyId,
                    secretAccessKey: data.Credentials.SecretKey,
                    sessionToken: data.Credentials.SessionToken
                });
                delete request.headers.Host;
                setToSessionStorage('_ROLE_AUTH_DETAILS', data);
                resolve(request);
            });
        });
    });
};

/**
 * @name setRequestDetails
 * @description update the details of request object
 */
let setRequestDetails = (request: any, session: any): Object => {
    request.headers['Access-Token'] = session.getAccessToken().jwtToken;
    request.headers['Id-Token'] = session.getIdToken().jwtToken;
    request.headers['Content-Type'] = 'application/json';
    if (!request.headers['Cache-Control']) {
        request.headers['Cache-Control'] = 'no-cache, max-age=0, no-store, must-revalidate';
    }
    if (!request.headers['Pragma']) {
        request.headers['Pragma'] = 'no-cache';
    }
    request.headers['Device-Type'] = 'Web-Buyer';
    return request;
};

let doGet = (url: string, params?: object) => {
    rax.attach();
    let response = new Promise(function (resolve, reject) {
        setHeader(url, null, 'GET', getBaseUrl()).then((request: any) => {
            return axios({
                method: 'get',
                url: `${getBaseUrl()}${url}`,
                params,
                headers: request.headers,
                raxConfig
            })
                .then((response: any) => {
                    resolve(response);
                })
                .catch((response: any) => {
                    reject(response);
                });
        });
    });
    return response;
};

let doPost = (url: string, data: object) => {
    return doCommonAxiosCall(url, 'POST', data);
};

let doPut = (url: string, data: object) => {
    return doCommonAxiosCall(url, 'PUT', data);
};

let doDelete = (url: string, data?: object) => {
    return doCommonAxiosCall(url, 'DELETE', data);
};

const doCommonAxiosCall = (url: string, method: Method, data?: object) => {
    const response = new Promise(function (resolve, reject) {
        setHeader(url, data, method, getBaseUrl()).then((request: any) => {
            return doAxiosCall(url, method, request, resolve, reject, data);
        });
    });
    return response;
};

const doAxiosCall = (url: string, method: Method, request: any, resolve: any, reject: any, data?: object) => {
    return axios({
        method,
        url: `${getBaseUrl()}${url}`,
        data,
        headers: request.headers
    })
        .then((response: any) => {
            resolve(response);
        })
        .catch((response: any) => {
            reject(response);
        });
};

export default {
    get: doGet,
    post: doPost,
    put: doPut,
    delete: doDelete
};
