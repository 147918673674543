import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

interface LayoutContainerProps {
    children?: any;
    outerContainerCustomClasses?: string;
}

const LayoutContainer = (props: LayoutContainerProps) => {
    return (
        <>
            <Box display="flex" justifyContent="center" className={`${props.outerContainerCustomClasses ? props.outerContainerCustomClasses : null}`}>
                <Grid container item justify="center" className="flex-75 flex-md-90 flex-sm-95 flex-xs-95">
                    {props.children}
                </Grid>
            </Box>
        </>
    );
};

export default LayoutContainer;
