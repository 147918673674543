import { getCustomBrandingDetails } from '../../utils/ApiUrlMapping';
import { setBrandingInfo } from '../actions/brandingDetails';
import { getFromSessionStorage, setToSessionStorage, getRedirectionUrl, getCustomerIdFromSessionStorage } from '../../utils/utility';
import { startLoader, stopLoader } from '../actions/app.action';
import history from '../../utils/history';
import { PATH_PAGE_NOT_FOUND } from '../../utils/routes';
import { TASK_GET_BRANDING } from '../../utils/commonConstants';
import { onTaskError } from './common.Middleware';
import customTypes from '../../interfaces/customTypes.Interface';

export function getCustomBrandingDetailsMiddleware(dispatch: any, getState: customTypes['getState'], customerId: string): any | undefined {
    let brandingData = getFromSessionStorage('BRANDING_DETAILS');
    if (Object.keys(brandingData).length === 0 && brandingData.constructor === Object) {
        dispatch(startLoader());
        return getCustomBrandingDetails(customerId)
            .then((response) => {
                onTaskSuccess(dispatch, response, TASK_GET_BRANDING, getState);
            })
            .catch((error) => {
                onTaskError(dispatch, error, TASK_GET_BRANDING);
            });
    } else {
        dispatch(setBrandingInfo(brandingData));
    }
}

const onTaskSuccess = (dispatch: CallableFunction, response: any, task: number, getState: customTypes['getState'], request?: any): void => {
    if (response.data.responseCode === 0) {
        dispatch(stopLoader());
        if (task === TASK_GET_BRANDING) {
            setToSessionStorage('BRANDING_DETAILS', response.data.responseObject);
            dispatch(setBrandingInfo(response.data.responseObject));
        }
    } else {
        history.push(getRedirectionUrl(PATH_PAGE_NOT_FOUND, { customerId: getCustomerIdFromSessionStorage(true) }));
        onTaskError(dispatch, response, task);
    }
};
