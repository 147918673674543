import { createStore, applyMiddleware } from 'redux';
import AppReducers from '../core/reducers/index';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

let __DEV__: any;
// middleware that logs actions
const loggerMiddleware = createLogger({
    predicate: (getState, action) => __DEV__
});

let AppStore = createStore(AppReducers, composeWithDevTools(applyMiddleware(thunkMiddleware, loggerMiddleware)));

export default AppStore;
