import { ticketDetailsObject, venueMapDetails } from '../interfaces/TicketDetails.Interface';
import history from './history';
import { PATH_EVENT_LIST, PATH_TICKET_LIST, PATH_SHOW_LIST } from './routes';
import moment from 'moment';
import 'moment-timezone';
import store from '../interfaces/store.Interface';
import { ReservedTicketsCollection, ReservedTicketIdentifier, ReservedTicket } from '../interfaces/cartReservation.Inerface';
import event_default from '../resources/images/event_default.png';

export function validateAddOrEditTicketChange(ticketObject: ticketDetailsObject, newValue: number, currentShowId: string): any {
    let msg = '';
    let availableCapacity = getAvailableCapacity(ticketObject);
    if (!availableCapacity) {
        msg = 'Sorry, this ticket is no longer available';
        ticketObject.count = 0;
    } else if (availableCapacity !== -1 && newValue > availableCapacity) {
        msg = 'Ticket quantity exceeds available capacity';
        ticketObject.count = availableCapacity;
    } else if (!currentShowId && newValue > ticketObject.maxOrder) {
        msg = 'Ticket quantity exceeds purchase limit for this transaction.';
        ticketObject.count = ticketObject.maxOrder;
    } else if (currentShowId && newValue > ticketObject.maxOrder) {
        msg = 'Purchase quantity for ' + ticketObject.ticketName + ' cannot be greater than ' + ticketObject.maxOrder;
        ticketObject.count = ticketObject.maxOrder;
    }
    let isValidAndMessage: {
        isValid: boolean;
        message: string;
    } = {
        isValid: true,
        message: ''
    };
    if (msg && msg.length > 0) {
        isValidAndMessage.message = msg;
        isValidAndMessage.isValid = false;
    }

    return isValidAndMessage;
}

export function validateRemoveTicketChange(ticketObject: ticketDetailsObject) {
    let msg = '';
    if (ticketObject.count < ticketObject.minOrder) {
        msg = "Minimum purchase quantity for '" + ticketObject.ticketName + "' is " + ticketObject.minOrder;
    }
    return {
        isValid: !Boolean(msg && msg.length),
        message: msg
    };
}

export function setToSessionStorage(key: string, item: any): void {
    sessionStorage.setItem(key, JSON.stringify(item));
}

export function getFromSessionStorage(key: string): any {
    return JSON.parse(sessionStorage.getItem(key) || '{}');
}

export function encode(customerIdString: string): string {
    const customerId: string = btoa(customerIdString);

    return customerId;
}

export const getUrlParamList = () => {
    return window.location.href.substring(window.location.href.indexOf('/#/') + 1).split('/');
};

export function getUTCDateObject(dateTimestamp: number): any {
    const momentObj = moment(dateTimestamp).tz('utc');

    return new Date(momentObj.year(), momentObj.month(), momentObj.date(), momentObj.hour(), momentObj.minute(), momentObj.second());
}

export function formatShowDate(showDate: number) {
    let momentDate = moment(showDate).format('ddd MMMM DD, YYYY');
    return momentDate;
}

export function formatDateFromDateTime(showDate: number) {
    let momentDate = moment(showDate).format('MMM DD, YYYY');
    return momentDate;
}

export function formatShowTime(showDate: number) {
    let momentTime = moment(showDate).format('hh:mm a');
    return momentTime;
}

export function formatShowDateAndTime(showDate: number) {
    const momentObj = moment(showDate).tz('utc');
    let momentDateAndTime = moment(momentObj).format('MMM DD, YYYY hh:mm a');
    return momentDateAndTime;
}

export function getUTCDateString(dateTimestamp: number, pattern: string) {
    const momentObj = moment(dateTimestamp).tz('utc');
    return momentObj.format(pattern);
}

export function formatDateTimetoTimeZone(dateTime: number, timeZone: string, dateTimeFormat: string) {
    return moment.tz(dateTime, timeZone).format(dateTimeFormat);
}

export function getTimeZoneAbbreviation(dateTime: number, timeZone: string): string | undefined {
    const momentZone = moment.tz.zone(timeZone);
    if (momentZone) {
        return momentZone.abbr(dateTime);
    }
}

export function formatDate(dateTimestamp: any) {
    let dateTime = moment.utc(dateTimestamp).format(`ll`);

    return dateTime.toString();
}

export function  getEventImage (imageUrls: any) {   
    let srcImageObj = imageUrls.filter((ele: any) => ele.imageType === 'ORIGINAL');
    if(srcImageObj) {
        return srcImageObj[0].imagePath;
    } else {
        return event_default;
    }
}

export const checkValidCustomerId = (params: any, history: any, redirect = true) => {
    let status = false;
    if (params.hasOwnProperty('customerId')) {
        let customerId = params.customerId;
        let customerIdDecoded = 0;
        try {
            customerIdDecoded = Number(atob(customerId));
        } catch (err) {
            customerIdDecoded = 0;
        }
        if (!customerIdDecoded || customerIdDecoded === -1 || isNaN(customerIdDecoded)) {
            if (redirect) {
                history.push('/landing');
            } else {
                status = false;
            }
        } else {
            status = true;
        }
    } else {
        if (redirect) {
            history.push('/landing');
        } else {
            status = false;
        }
    }
    return status;
};

export function formatDateWithoutDay(showDate: number) {
    let momentTime = moment(showDate).format('MMM DD, YYYY');
    return momentTime;
}

export function formatDateTimeWithoutDay(showDate: number) {
    let momentTime = moment(showDate).format('MMM DD, YYYY hh:mm a');
    return momentTime;
}

export function setToLocalStorage(key: string, item: any): void {
    localStorage.setItem(key, JSON.stringify(item));
}

export function getFromLocalStorage(key: string): any {
    return JSON.parse(localStorage.getItem(key) || '{}');
}

export function getBuyerDetails() {
    let buyerDetails = getFromLocalStorage('_BUYER_USER_DETAILS');
    return buyerDetails;
}

export function decodeString(customerIdString: string): number {
    let customerId = '';
    try {
        if (btoa(atob(customerIdString)) === customerIdString && !isNaN(parseInt(atob(customerIdString), 10))) {
            customerId = atob(customerIdString);
        } else {
            customerId = '-1';
        }
    } catch (err) {
        return -1;
    }
    return parseInt(customerId, 10);
}

export function clearUserDetails(): void {
    localStorage.removeItem('_BUYER_USER_DETAILS');
    localStorage.removeItem('BUYER_SESSION_EXPIRY_TIME');
    sessionStorage.setItem('PURCHASED_TICKET_DETAILS', '{}');
    sessionStorage.removeItem('_ROLE_IDENTITY_PARAMS');
    sessionStorage.removeItem('_ROLE_AUTH_DETAILS');
    sessionStorage.removeItem('PLACE_ORDER_DETAILS');
}

export function clearAll(): void {
    localStorage.clear();
}

export function formatPhoneNumber(phoneNumber: string) {
    let formattedNumber = phoneNumber;
    const c = phoneNumber[0] === '1' && phoneNumber.length > 10 ? '1 ' : '';
    phoneNumber = phoneNumber[0] === '1' && phoneNumber.length > 10 ? phoneNumber.slice(1) : phoneNumber;
    const area = phoneNumber.substring(0, 3);
    const front = phoneNumber.substring(3, 6);
    const end = phoneNumber.substring(6, 10);
    if (front) {
        if (c) {
            formattedNumber = c + '-' + area + '-' + front;
        } else {
            formattedNumber = area + '-' + front;
        }
    }
    if (end) {
        formattedNumber += '-' + end;
    }
    return formattedNumber;
}
export function clearBrandingData(): void {
    sessionStorage.removeItem('BRANDING_DETAILS');
}

export function clearReservationDetails(): void {
    sessionStorage.setItem('CART_RESERVATION_DETAILS', '{}');
}

export function clearPlaceOrderDetails(): void {
    sessionStorage.removeItem('PLACE_ORDER_DETAILS');
}

export function clearPurchasedTickets(): void {
    sessionStorage.setItem('PURCHASED_TICKET_DETAILS', '{}');
}

export function getCustomerIdFromSessionStorage(convertToBtoa = false): any {
    let customerId = getFromSessionStorage('CUSTOMER_ID');
    return !isEmptyObject(customerId) ? (convertToBtoa ? btoa(customerId) : Number(customerId)) : false;
}

export function getBrandingDataFromSessionStorage(): any {
    return getFromSessionStorage('BRANDING_DETAILS');
}

export function getReservationDetailsFromSessionStorage(): any {
    return getFromSessionStorage('CART_RESERVATION_DETAILS');
}

export const isEmptyObject = (obj: object): boolean => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export function isNumeric(num: any) {
    return !isNaN(num);
}

export function getCurrentAppVersion() {
    return sanitizeAppVersion(process.env.REACT_APP_VERSION);
}

export function sanitizeAppVersion(version: any) {
    return version?.replace(/-/g, '');
}

export function getRedirectionUrl(url: string, pathParams: any) {
    for (let key in pathParams) {
        if (pathParams.hasOwnProperty(key)) {
            url = url.replace(`:${key}`, pathParams[key]);
        }
    }
    return url;
}

export function beautifyUrl(url: string, pathParams: any) {
    for (let key in pathParams) {
        url = url.replace('{?}', encodeURIComponent(pathParams[key]));
    }
    return url;
}

/**
 * @name handleInvalidSession
 * @description handle thetasks during invalid session
 */
export function handleInvalidSession(customerId: string): void {
    clearUserDetails();
    clearAll();
    history.push(getRedirectionUrl(PATH_EVENT_LIST, { customerId }));
}

export function getEnv() {
    return process.env.hasOwnProperty('REACT_APP_ENV') && process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'demo';
}

export const getPhoneNumber = (phNo: string) => {
    return phNo ? formatPhoneNumber(phNo.replace('+1', '')) : '';
};

export const gotoTicketList = (eventObject: any, callBack: any) => {
    if (!eventObject.isComingSoon) {
        callBack();
        const customerId = btoa(getFromSessionStorage('CUSTOMER_ID'));
        const eventId = eventObject.id ? eventObject.id : -1;
        if (eventObject.showSchedules && eventObject.showSchedules.length) {
            history.push(getRedirectionUrl(PATH_SHOW_LIST, { customerId, eventId }));
        } else {
            history.push(getRedirectionUrl(PATH_TICKET_LIST, { customerId, eventId }));
        }
    }
};

export const printTicket = (pdfTicketUrl: string) => {
    if (pdfTicketUrl) {
        window.open(pdfTicketUrl, '_blank');
    }
};
export const getEncodedUrlParams = (data: any) => {
    let params = Object.entries(data).map((item: any) => {
        let key = item[0],
            value = item[1];
        return `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`;
    });
    return params.length > 0 ? '?' + params.join('&') : '';
};

export const returnHome = () => {
    history.push(
        getRedirectionUrl(PATH_EVENT_LIST, {
            customerId: btoa(getFromSessionStorage('CUSTOMER_ID'))
        })
    );
};

export const isCaptchaEnabled = () => !['qa', 'uat', 'test'].includes(getEnv());

export const arrayRemove = (arr: any[], value: any) => {
    return arr.filter(function (ele) {
        return ele !== value;
    });
};

export const getImageDimensions = (imgUrl: string, callback: (dimensions: any) => void) => {
    let imageWidth = 0;
    let imageHeight = 0;
    let img = new Image();
    img.src = imgUrl;
    img.onload = () => {
        imageWidth = img.naturalWidth;
        imageHeight = img.naturalHeight;
        callback({ imageWidth, imageHeight });
    };
};

export const getVenueMapDetails = (venueDetails: store['ticketDetails']['venueDetails']): venueMapDetails => {
    let venueMaplocation = '';
    let venueDetail: any = {};
    let showMap = false;
    if (venueDetails.length > 0) {
        venueDetail = venueDetails[0];
        if (venueDetail.hasOwnProperty('venueId') && venueDetail.venueId !== '') {
            venueMaplocation = venueDetail.venueName + ',' + venueDetail.venueAddressLine1 + ',' + venueDetail.venueCity + ',' + venueDetail.venueCode;
            venueMaplocation = venueMaplocation.split(' ').join('+');
            showMap = true;
        }
    }
    return { showMap, venueMaplocation, venueDetail };
};

export function getPromoReservationId(): number {
    let data = getFromSessionStorage('PROMO_RESERVATION_DETAILS');
    return data && data.promoReservationId ? data.promoReservationId : null;
}

export function getPromoReservationDetails(): any {
    let data = getFromSessionStorage('PROMO_RESERVATION_DETAILS');
    return data;
}

export function isCartReservationEnabled(): boolean {
    let data = getFromSessionStorage('CART_RESERVATION_DETAILS');
    return Boolean(data && data.cartReservationEnabled);
}

export function getCartReservationId(): number {
    let data = getFromSessionStorage('CART_RESERVATION_DETAILS');
    return data && data.cartReservationId ? data.cartReservationId : 0;
}

export function getCartReservationTimeInMs(): number {
    let data = getFromSessionStorage('CART_RESERVATION_DETAILS');
    let reservationTimeInMs = 0;
    let timeRemaining = shouldTimerPersisit();
    if (timeRemaining) {
        // timer will start from the remaining time before reload.
        reservationTimeInMs = timeRemaining;
    } else if (data.reservationTime) {
        // converting the value returned from api in seconds to milli seconds
        reservationTimeInMs = data.reservationTime * 1000;
    }
    return reservationTimeInMs;
}

export function getFormattedCartReservationTime(): string {
    let data = getFromSessionStorage('CART_RESERVATION_DETAILS');
    let time = '0:00';
    let unit = 'seconds';
    if (data.reservationTime) {
        time = moment.utc(moment.duration(data.reservationTime, 'seconds').asMilliseconds()).format('mm:ss');
        if (data.reservationTime >= 60) {
            unit = 'minutes';
        }
    }
    return `${time} ${unit}`;
}

export function setCartReservationId(cartReservationId: number): boolean {
    let data = getFromSessionStorage('CART_RESERVATION_DETAILS');
    data.cartReservationId = cartReservationId;
    if (!cartReservationId) {
        data.reservedTicketList = [];
    }
    setToSessionStorage('CART_RESERVATION_DETAILS', data);
    return true;
}

export function setPromoReservationId(promoReservationId: number): boolean {
    let data = getFromSessionStorage('PROMO_RESERVATION_DETAILS');
    data.promoReservationId = promoReservationId;
    setToSessionStorage('PROMO_RESERVATION_ID', data);
    return true;
}

export function setPromoList(promoData: any): boolean {
    let data = getFromSessionStorage('PROMO_RESERVATION_DETAILS');
    data.promoList = promoData;
    setToSessionStorage('PROMO_RESERVATION_DETAILS', data);
    return true;
}

export function setPromoReservationDetails(promoData: any): boolean {
    let data = getFromSessionStorage('PROMO_RESERVATION_DETAILS');
    data.promoReservationId = promoData.promoReservationId;
    data.tickets = promoData.tickets;
    data.invalidPromoCodes = promoData.invalidPromoCodes;
    data.validPromoCodes = promoData.validPromoCodes;
    setToSessionStorage('PROMO_RESERVATION_DETAILS', data);
    return true;
}

export function setTimerInitiationMoment(status: boolean) {
    let data = getFromSessionStorage('CART_RESERVATION_DETAILS');
    if (status) {
        data.timerInitiatedMoment = moment().unix();
    } else {
        data.timerInitiatedMoment = 0;
    }
    setToSessionStorage('CART_RESERVATION_DETAILS', data);
}

export function shouldTimerPersisit(): number {
    let timeinMs = 0;
    if (isCartReservationEnabled() && getCartReservationId()) {
        let data = getFromSessionStorage('CART_RESERVATION_DETAILS');
        if (data.timerInitiatedMoment) {
            let now = moment().unix();
            let diff = now - data.timerInitiatedMoment;
            let remainingTime = data.reservationTime - diff;
            if (remainingTime && remainingTime > 0) {
                // converting to milliseconds
                timeinMs = remainingTime * 1000;
            }
        }
    }
    return timeinMs;
}

export function setReservedTickets(reservedTickets: any): void {
    let data = getFromSessionStorage('CART_RESERVATION_DETAILS');
    if (reservedTickets.events && reservedTickets.events.length > 0) {
        let reservedTicketsDetails: ReservedTicketsCollection = [];
        reservedTickets.events.forEach((event: any) => {
            let eventId = event.eventId ? event.eventId : 0;
            if (event.shows && event.shows.length > 0) {
                event.shows.forEach((show: any) => {
                    let showId = show.showId ? show.showId : 0;
                    if (show.tickets && show.tickets.length > 0) {
                        show.tickets.forEach((ticket: any) => {
                            let ticketId = ticket.ticketId ? ticket.ticketId : 0;
                            let count = ticket.count;
                            let isCombo = ticket.combo;
                            if (eventId && ticketId) {
                                reservedTicketsDetails.push({ eventId, isCombo, ticketId, showId, count });
                            }
                        });
                    }
                });
            }
        });
        data.reservedTicketList = reservedTicketsDetails;
    } else {
        data.reservedTicketList = [];
    }
    setToSessionStorage('CART_RESERVATION_DETAILS', data);
}

export function getReservedTickets() {
    let reservedTickets = [];
    let details = getFromSessionStorage('CART_RESERVATION_DETAILS');
    if (details.reservedTicketList && details.reservedTicketList.length > 0) {
        reservedTickets = details.reservedTicketList;
    }
    return reservedTickets;
}

export function isReservedTicket(data: ReservedTicketIdentifier): number {
    let count = 0;
    let reservedTickets = getReservedTickets();
    if (reservedTickets.length > 0) {
        let reservedTicket = reservedTickets.find((ticket: ReservedTicket) => {
            return ticket.eventId === data.eventId && ticket.isCombo === data.isCombo && ticket.ticketId === data.ticketId && ticket.showId === data.showId;
        });
        if (reservedTicket && reservedTicket.hasOwnProperty('count')) {
            count = reservedTicket.count;
        }
    }
    return count;
}

export function getAvailableCapacity(ticket: any): number {
    let availableCapacity = ticket.availableCapacity;
    if (availableCapacity !== -1 && isCartReservationEnabled() && getCartReservationId()) {
        let { ticketId, showId, isCombo } = ticket;
        let eventId = ticket.eventDetails.id;
        let reservedTicketCount = isReservedTicket({ eventId, isCombo, ticketId, showId });
        if (reservedTicketCount) {
            // cart reservation scenario - live update will reduce the available capacity for a reserved ticket, even
            // for the user who reserved it. Hence we are adding the available capacity with reserved ticket count of the user.
            availableCapacity = availableCapacity + reservedTicketCount;
        }
    }
    return availableCapacity;
}

export function getFormattedAmount(amount: any): any {
    if (amount) {
        if (amount < 0) {
            amount *= -1;
        }
        return parseFloat(amount).toFixed(2);
    } else {
        return parseFloat('0').toFixed(2);
    }
}

export const addUrlParams = (urlString: string, params: any): string => {
    urlString = urlString.replace('{*}', params);

    return urlString;
};

export function isYumaCountyFair() {
    const customerId = getFromSessionStorage('CUSTOMER_ID');
    if(customerId === 212) {
        if(getEnv() === 'prod') {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
};