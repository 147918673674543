export const CUSTOM_BRANDING_DETAILS: any = (customerId: string) => {
    return 'settings/CustomerBrandingDetails?customerId=' + customerId;
};

export const TICKET_LIST_URL: any = (customerId: string, eventId: any, showId: any, categoryId: any) => {
    if(categoryId) {
        return 'event/listTicketsForEvents?customerId=' + customerId + '&id=' + eventId + '&showId=' + showId + '&categoryId=' + categoryId;
    } else {
        return 'event/listTicketsForEvents?customerId=' + customerId + '&id=' + eventId + '&showId=' + showId;
    }
};

export const VENUE_DETAIL_URL: any = 'venue/detailsByIdsForBuyer';

export const EVENT_LIST_BASE_URL: string = 'event/listEventsForBuyer';

export const EVENT_LIST_URL: any = (customerId: string, pageNumber: any) => {
    return EVENT_LIST_BASE_URL + '?customerId=' + customerId + '&state=active&saleStatus=selling&page=' + pageNumber;
};

export const SEARCH_EVENT_URL: any = 'event/searchEventsForBuyer';

export const FILTER_EVENTS_URL: any = 'event/filterEventsByDate';

export const SHOWS_BY_ID: any = (customerId: number) => {
    return 'event/show/getShowsByEventIdsForBuyer/' + customerId;
};

export const GET_ORIGINAL_USER_NAME: any = 'buyer/buyerDetails';

export const RESET_PASSWORD: string = 'buyer/forgotPassword';

export const CHANGE_PASSWORD: any = 'buyer/changePassword';

export const BUYER_SUPPORT_EMAIL: any = 'customer/adminSupport/buyerSupportEmail';

export const SIGN_UP: any = 'buyer/signup';

export const FEE_TAX_DETAILS: any = 'buyer/getFeeAndTaxForEventTickets';

export const APPLY_PROMO: any = 'ticket/promocode/apply';

export const RELEASE_PROMO: any = (customerId: string, promoId: any) => {
    return 'ticket/promocode/release/' + promoId + '?customerId=' + customerId;
};

// BILLING
export const BILLING_SETTINGS_ADDRESS_URL: any = 'buyer/settings/billingAddress';
export const SET_DEFAULT_ADDRESS_BASE_URL: string = 'buyer/settings/makeDefaultBillingAddress';
export const SET_DEFAULT_ADDRESS_URL: any = (addressId: string) => SET_DEFAULT_ADDRESS_BASE_URL + '?addressId=' + addressId;
export const DELETE_ADDRESS_URL: any = (addressId: string) => 'buyer/settings/billingAddress?addressId=' + addressId;
export const GUEST_ADDRESS_URL: string = 'buyer/settings/guestBillingAddress';

export const GET_ORDER_HISTORY: any = 'buyer/buyerOrderHistory';

export const GET_ORDER_HISTORY_BY_ID: any = 'buyer/buyerOrderHistory/byOrderNumber';

export const SHARE_FB_URL: any = 'share/share.jsp?title={?}&description={?}&image={?}&imageWidth={?}&imageHeight={?}&redirectURL={?}';

// place order - cart reservation disabled

export const PLACE_ORDER: any = 'buyer/placeOrder';

export const PLACE_ORDER_GUEST: any = 'buyer/guest/placeOrder';

// place order - cart reservation enabled

export const PLACE_ORDER_CART_RESERVATION: any = 'buyer/cart/placeOrder';

export const PLACE_ORDER_GUEST_CART_RESERVATION: any = 'buyer/guest/cart/placeOrder';

//WALLET
export const SET_WALLET_TICKET_URL: any = (uniqueId: string) => 'sale/receipt/walletTicketDetails/' + uniqueId;

export const GET_DELIVERY_OPTIONS: any = 'ticket/deliveryOption/getDeliveryOptionsForTickets';

export const GET_CUSTOMER_SALE_PERMISSION: any = (customerId: string) => 'subscription/salePermissions/getCustomerSalePermission?customerId=' + customerId;

export const PREFERRED_PAYMENT: any = (customerId: Number) => 'payment/preferred/' + customerId;

// PAYMENT
export const SQUARE_MERCHANT_DETAILS_URL: any = (customerId: number) => 'payment/merchant/squareDetails/' + customerId;
export const CREATE_HOSTED_TRANSACTION_URL: any = (customerId: number) => 'payment/propay/createHostedTransaction/' + customerId;
export const PROPAY_RESULT_URL: any = 'payment/propay/getHostedTransactionResult';

export const GET_CART_RESERVATION_DETAILS: any = (customerId: string) => {
    return 'settings/transactionSettings/getCartReservationSettings/' + customerId;
};

export const BLUEFIN_MERCHANT_DETAILS_URL: any = (customerId: number) => 'payment/merchant/payconexDetailsForBuyer/' + customerId;
export const BLUEFIN_SCRIPT_URL: any = '/iframe/iframe-lib-1.0.0.js';

export const RESERVE_CART: any = 'sale/cartReservation';
export const RELEASE_CART: any = 'sale/cartReservation/releaseCart';
