import { getSignupURL } from '../../utils/ApiUrlMapping';
import { encode } from '../../utils/utility';
import { signUpObject } from '../../interfaces/signUp.Interface';
import { getFromSessionStorage } from '../../utils/utility';
import { startLoader, stopLoader, setValidationMessage, toggleSignupAcion } from '../actions/app.action';
import { showConfirmationAction } from '../actions/signUp.action';
import { TASK_SIGNUP } from '../../utils/commonConstants';

export function doSignUpMiddleWare(dispatch: any, signUpDetails: signUpObject) {
    const regTrim: any = / {1,}/g;
    dispatch(startLoader());
    let signUpRequestEncoded = {
        customerId: Number(getFromSessionStorage('CUSTOMER_ID')),
        email: signUpDetails.email,
        firstName: signUpDetails.firstName.replace(regTrim, ' '),
        lastName: signUpDetails.lastName.replace(regTrim, ' '),
        password: signUpDetails.password ? encode(signUpDetails.password) : '',
        status: 1,
        confirmPassword: '',
        username: signUpDetails.username
    };
    getSignupURL(signUpRequestEncoded)
        .then((apiResponse) => {
            onTaskSuccess(dispatch, apiResponse, TASK_SIGNUP, signUpDetails);
        })
        .catch((errorResponse) => {
            onTaskError(errorResponse, signUpDetails, dispatch);
        });
}

const onTaskSuccess = (dispatch: CallableFunction, response: any, task: number, request?: any): void => {
    if (response.data.responseCode === 0) {
        dispatch(stopLoader());
        if (task === TASK_SIGNUP) {
            if (!request.isCheckoutSummary) {
                dispatch(toggleSignupAcion());
            }
            dispatch(showConfirmationAction(true));
        }
    } else {
        onTaskError(response, request, dispatch);
    }
};

function onTaskError(response: any, request: any, dispatch: any) {
    dispatch(stopLoader());
    if (response.data.responseCode === 502) {
        dispatch(setValidationMessage('The provided username already exists. Please use a different username and try again.'));
    } else {
        if (response && response.data.responseMessage) {
            dispatch(setValidationMessage(response.data.responseMessage));
        }
    }
}
