import DEMO from '../config/demo.json';
import DEV from '../config/dev.json';
import PREPROD from '../config/preprod.json';
import PROD from '../config/prod.json';
import QA from '../config/qa.json';
import STAGE from '../config/stage.json';
import UAT from '../config/uat.json';
import { getEnv } from './utility';

let CONFIG;
let ENV = getEnv();

switch (ENV) {
    case 'demo':
        CONFIG = DEMO;
        break;
    case 'dev':
        CONFIG = DEV;
        break;
    case 'preprod':
        CONFIG = PREPROD;
        break;
    case 'prod':
        CONFIG = PROD;
        break;
    case 'qa':
        CONFIG = QA;
        break;
    case 'stage':
        CONFIG = STAGE;
        break;
    case 'uat':
        CONFIG = UAT;
        break;
    default:
        CONFIG = DEMO;
}

export const GLOBAL_CONFIG = {
    // Add common config values here
    COGNITO_SESSION_EXPIRY_TIME: 3300000,
    COGNITO_REFRESH_EXPIRY_TIME: 2505600000,
    ...CONFIG.constants
};
