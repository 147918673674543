import { startLoader, stopLoader } from '../actions/app.action';
import { getCartReservationDetails, releaseCart } from '../../utils/ApiUrlMapping';
import { onTaskError } from './common.Middleware';
import { TASK_GET_RESERVATION_DETAILS, TASK_RELEASE_CART } from '../../utils/commonConstants';
import {
    setToSessionStorage,
    getCartReservationId,
    getFromSessionStorage,
    setCartReservationId,
    clearPurchasedTickets,
    isCartReservationEnabled,
    clearReservationDetails,
    getCustomerIdFromSessionStorage,
    setPromoReservationDetails,
    getPromoReservationId
} from '../../utils/utility';
import { setTimerVisibilityAction, setValidationMessage, setTickeListRedirectionDetailsAction } from '../actions/app.action';
import { resetPurchasedTicketListAction } from '../actions/ticketList';
import { resetViewStatusAction, resetDeliveryMethods, resetFeeTaxDetails, resetPromo, releasePromoAction } from '../actions/checkout.action';
import { resetPaymentAction } from '../actions/payments.action';
import customTypes from '../../interfaces/customTypes.Interface';
import { getReservationDetailsAction } from '../actions/cartReservation.action';

export const getReservationDetailsMiddleware = (dispatch: CallableFunction, customerId: string) => {
    dispatch(startLoader());
    return getCartReservationDetails(customerId)
        .then((response) => {
            onTaskSuccess(dispatch, response, TASK_GET_RESERVATION_DETAILS);
        })
        .catch((error) => {
            onTaskError(dispatch, error, TASK_GET_RESERVATION_DETAILS);
        });
};

export const releaseCartMiddleware = (dispatch: CallableFunction, getState: customTypes['getState'], timerExpiry: boolean, resetTransactionSettings: boolean) => {
    const cartReservationId = getCartReservationId();
    if (isCartReservationEnabled() && cartReservationId) {
        dispatch(startLoader());
        setCartReservationId(0);

        dispatch(setTimerVisibilityAction(false));

        if (timerExpiry) {
            // Release cart action will be called during timer-expiry, logout and when user removes all items in cart.
            // On logout and user removing the tickets, we dont need to clear cart forcefully as that is already being taken care of.
            let showId = 0;
            let eventId = 0;
            if (getState().ticketDetails.purchasedTicketDetails.purchasedTicketList.length > 0) {
                let ticket = getState().ticketDetails.purchasedTicketDetails.purchasedTicketList[0];
                showId = ticket.showId;
                eventId = ticket.eventDetails.id;
            }
            dispatch(setTickeListRedirectionDetailsAction({ eventId, showId }));
            dispatch(setValidationMessage('Your order is expired, please make your selection again.'));
            clearPurchasedTickets();
            dispatch(resetPurchasedTicketListAction());
            dispatch(resetViewStatusAction());
            dispatch(resetFeeTaxDetails());
            dispatch(resetPaymentAction());
            dispatch(resetDeliveryMethods());
        }
        if (resetTransactionSettings) {
            clearReservationDetails();
            let customerIdEncoded = getCustomerIdFromSessionStorage(true);
            dispatch(getReservationDetailsAction(customerIdEncoded));
        }
        const customerId = Number(getFromSessionStorage('CUSTOMER_ID'));
        return releaseCart({ customerId, cartReservationId })
            .then((response: any) => {
                onTaskSuccess(dispatch, response, TASK_RELEASE_CART);
            })
            .catch((error: any) => {
                onTaskError(dispatch, error, TASK_RELEASE_CART);
            });
    }
};

const onTaskSuccess = (dispatch: CallableFunction, response: any, task: number): void => {
    if (response.data.responseCode === 0) {
        dispatch(stopLoader());
        // replaced switch because sonar lint rule specifies switch shuld have atleast three cases.
        if (task === TASK_GET_RESERVATION_DETAILS) {
            setToSessionStorage('CART_RESERVATION_DETAILS', response.data.responseObject);
        } else if (task === TASK_RELEASE_CART) {
            triggerReleasePromo(dispatch);
        }
    } else {
        onTaskError(dispatch, response, task);
    }
};

const triggerReleasePromo = (dispatch: CallableFunction) => {
    if (getPromoReservationId()) {
        dispatch(releasePromoAction());
    }
    setPromoReservationDetails({});
    dispatch(resetPromo());
};
