import { SET_LOGGEDIN_STATUS, SET_SESSION_STATUS, SET_BUYER_DETAILS } from '../actions/login.action';
import store from '../../interfaces/store.Interface';

function userDetailsHandler(state: store['userDetails'], action: any): store['userDetails'] {
    switch (action.type) {
        case SET_LOGGEDIN_STATUS:
            return { ...state, isUserLoggedIn: action.loggedInStatus };
        case SET_SESSION_STATUS:
            return { ...state, isSessionExpired: action.sessionStatus };
        case SET_BUYER_DETAILS:
            return { ...state, buyerDetails: action.buyerDetails };
        default:
            return { ...state };
    }
}
export default userDetailsHandler;
