import eventListData from '../../interfaces/eventList.Interface';
import { getEventListMiddleware, searchKeyEventMiddleware, filterEventsMiddleware, showMoreMiddleWare } from '../middlewares/eventList.Middleware';
import store from '../../interfaces/store.Interface';
import customTypes from '../../interfaces/customTypes.Interface';

/**
 * action types
 */
export const SET_EVENT_LIST = 'SET_EVENT_LIST';
export const ADD_EVENTS = 'ADD_EVENTS';
export const SEARCH_EVENT = 'SEARCH_EVENT';
export const SET_FILTER = 'SET_FILTER';
export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';
export const TOGGLE_SEARCH = 'TOGGLE_SEARCH';
export const SET_VIEWTYPE = 'SET_VIEWTYPE';
export const RESET_EVENT_LIST = 'RESET_EVENT_LIST';
export const SET_EVENT_COUNT = 'SET_EVENT_COUNT';

/**
 * action creators
 */
export function setEventList(eventList: eventListData['eventList']) {
    return {
        type: SET_EVENT_LIST,
        eventList
    };
}

export function addEvents(eventList: eventListData['eventList']) {
    return {
        type: ADD_EVENTS,
        eventList
    };
}

export function searchEvent(eventList: eventListData['eventList']) {
    return {
        type: SEARCH_EVENT,
        eventList
    };
}

export function setPageNumber(pageNumber: eventListData['pageNumber']) {
    return {
        type: SET_PAGE_NUMBER,
        pageNumber
    };
}

export function expandSearchFieldAction(expandSearch: boolean) {
    return {
        type: TOGGLE_SEARCH,
        expandSearch
    };
}

export function setEventCount(eventCount: number) {
    return {
        type: SET_EVENT_COUNT,
        eventCount
    };
}

export function getEventListAction(customerId: any, pageNumber: any) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return getEventListMiddleware(dispatch, customerId, pageNumber);
    };
}

export function searchEventAction(customerId: any, searchkey: any) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return searchKeyEventMiddleware(dispatch, customerId, searchkey);
    };
}

export function filterEventsAction(filterKey: any) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return filterEventsMiddleware(dispatch, filterKey, true);
    };
}

export function showMoreAction() {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return showMoreMiddleWare(dispatch, getState);
    };
}

export function setViewType(viewType: store['eventListData']['viewType']) {
    return {
        type: SET_VIEWTYPE,
        viewType
    };
}

export function setViewTypeAction(viewType: store['eventListData']['viewType']) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        dispatch(setViewType(viewType));
    };
}

export function setFilter(filter: store['eventListData']['filter']) {
    return {
        type: SET_FILTER,
        filter
    };
}

export function resetEventListAction(excludeView = false) {
    return {
        type: RESET_EVENT_LIST,
        excludeView
    };
}
