import { SET_TICKET_LIST, SET_VENUE_DETAILS, UPDATE_TICKET_COUNT, SET_EVENT_DETAILS, RESET_TICKET_LIST, RESET_PURCHASED_TICKET_LIST } from '../actions/ticketList';
import store from '../../interfaces/store.Interface';
import { eventDetailsIS, purchasedTicketDetailsIS } from '../initialState';

function ticketListHandler(state: store['ticketDetails'], action: any): store['ticketDetails'] {
    switch (action.type) {
        case SET_TICKET_LIST:
            return { ...state, ticketList: [...action.ticketList] };
        case SET_VENUE_DETAILS:
            return { ...state, venueDetails: [...action.venueDetails] };
        case UPDATE_TICKET_COUNT:
            return { ...state, purchasedTicketDetails: { ...action.purchasedTicketDetails } };
        case SET_EVENT_DETAILS:
            return { ...state, eventDetails: { ...action.eventDetails } };
        case RESET_TICKET_LIST:
            return { ...state, ticketList: [], venueDetails: [], eventDetails: { ...eventDetailsIS } };
        case RESET_PURCHASED_TICKET_LIST:
            return { ...state, purchasedTicketDetails: { ...purchasedTicketDetailsIS } };
        default:
            return { ...state };
    }
}

export default ticketListHandler;
