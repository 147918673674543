import store from '../../interfaces/store.Interface';
import { getWalletTicketMiddleware } from '../middlewares/walletTicket.Middleware';
import customTypes from '../../interfaces/customTypes.Interface';

/**
 * action types
 */
export const SET_WALLET_TICKET_DETAILS = 'SET_WALLET_TICKET_DETAILS';

/**
 * action creators
 */
export function setWalletTicketDetails(walletTicket: store['walletTicket']) {
    return {
        type: SET_WALLET_TICKET_DETAILS,
        walletTicket
    };
}

export function getWalletTicketAction(uniqueId: string) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return getWalletTicketMiddleware(uniqueId, dispatch, getState);
    };
}
