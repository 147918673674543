export const TASK_GET_EVENT_LIST = 1;
export const TASK_GET_VENUE_DETAILS = 4;
export const TASK_GET_EVENT_DETAILS = 5;
export const TASK_FORGOT_PASSWORD = 6;
export const TASK_GET_ADDRESS = 7;
export const TASK_SAVE_ADDRESS = 8;
export const TASK_GET_FILTER_EVENT_LIST = 9;
export const TASK_GET_BRANDING = 10;
export const TASK_GET_FEE_DETAILS = 11;
export const TASK_SAVE_GUEST_ADDRESS = 12;
export const TASK_PLACE_ORDER = 13;
export const TASK_PLACE_ORDER_GUEST = 14;
export const TASK_UPDATE_ADDRESS = 15;
export const TASK_GET_ORDER_HISTORY_ID = 16;
export const TASK_GET_ORDER_HISTORY_DATE = 17;
export const TASK_SEND_SUPPORT = 18;
export const TASK_SEARCH_EVENT_LIST = 19;
export const TASK_CHANGE_PASSWORD = 20;
export const TASK_SET_DEFAULT_ADDRESS = 21;
export const TASK_SIGNUP = 3;
export const TASK_GET_SALE_PERMISSION = 22;
export const TASK_PAYMENT_SALE = 23;
export const TASK_GET_TICKET_OPTIONS = 24;
export const TASK_GET_PREFERRED_PAYMENT = 25;
export const TASK_GET_PROPAY_RESULT = 26;
export const TASK_GET_SHOW_LIST = 27;
export const TASK_DELETE_ADDRESS = 28;
export const TASK_GET_FEE_TAX = 29;
export const TASK_GET_SQUARE_MERCHANT_DETAILS = 30;
export const TASK_GET_WALLET_TICKET = 31;
export const TASK_GET_DELIVERY_OPTION = 32;
export const TASK_GET_HOSTED_TRANSACTION_ID = 33;
export const TASK_GET_BLUEFIN_MERCHANT_DETAILS = 34;
export const TASK_GET_RESERVATION_DETAILS = 35;
export const TASK_RESERVE_CART = 36;
export const TASK_PLACE_ORDER_CART_RESERVATION = 37;
export const TASK_PLACE_ORDER_GUEST_CART_RESERVATION = 38;
export const TASK_RELEASE_CART = 39;
export const TASK_APPLY_PROMO = 40;
export const TASK_RELEASE_PROMO = 41;

export const apiNumber = {
    changePassword: 2
};
export const COUNTRY_LIST = ['United States', 'United Kingdom'];
export const CREDIT_CARD_TYPES = ['American Express', 'MasterCard', 'Visa'];

export const CURRENCY_SYMBOL = '$';

/* Error Messages */
export const ERROR_NOT_FOUND_TICKETS = 'No tickets available';
export const ERROR_NOT_FOUND_FEE_DETAILS = 'Fee details not available';
export const ERROR_UNABLE_PROCESS = 'We are unable to process your request. Please try again later.';
export const ERROR_SESSION_EXPIRED = 'Your session has expired. Please login to continue.';
export const ERROR_SESSION_REFRESHED = 'Your session refreshed. Please try again.';
export const ERROR_SAVE_ADDRESS = 'Unable to save address.';
export const ERROR_SET_DEFAULT_ADDRESS = 'Unable to set default address. Please try again.';
export const ERROR_GET_ADDRESS = 'Unable to fetch address.';
export const ERROR_PLACE_ORDER = 'Unable to place order. Please try again later.';
export const ERROR_FETCH = 'Unable to fetch data. Please try again.';
export const ERROR_REQUEST_FAIL = 'Request failed.';
export const ERROR_SIGNUP_DUPLICATE = 'The provided username already exists. Please use a different username and try again.';
export const ERROR_PLACE_ORDER_EVENT_MODIFIED = 'The selected ticket(s) is not available for sale.';
export const ERROR_PAYMENT_SALE = 'Something went wrong. Card payment failed.';
export const ERROR_GET_TICKET_OPTIONS = 'Unable to fetch ticket options';
export const ERROR_GET_PROPAY_RESULT = 'Card Authentication Failed. Please check the card details';
export const ERROR_GET_PREFERRED_PAYMENT = 'Failed to get preferred payment details. Please contact customer support';
export const ERROR_PLACE_ORDER_TIMEOUT =
    '<span> Sorry, something went wrong with your order. Please contact' + '<a href="mailto=admin@kisticket.com"> admin@kisticket.com </a> for assistance </span>';
export const ERROR_DELETE_ADDRESS = 'Failed to delete billing address';
export const GENERIC_PAYMENT_ERROR_MESSAGE = 'Something went wrong. Please contact customer support or try again later';
export const ERROR_EVENT_DETAILS = 'Failed to retrieve events.';
export const ERROR_RESERVE_CART = 'Failed to checkout';
export const ERROR_APPLY_PROMO = 'Falied to apply promo';

/* Toast Messages */
export const MSG_PLACED_ORDER = 'Order confirmed.';
export const MSG_FETCH_SUCCESS = 'Fetch successfully completed.';
export const MSG_SUBMIT_SUCCESS = 'Your query has been submitted successfully.';
export const MSG_NOT_FOUND = 'Not found.';
export const MSG_SUCCESS = 'Success.';
export const MSG_SET_DEFAULT_ADDRESS = 'You have successfully set a default billing address.';
export const MSG_DELETE_ADDRESS = 'Billing address deleted successfully';
