import {
    getSquareMerchantDetailsMiddleware,
    getHostedTransactionIdMiddleware,
    getPropayResultMiddleware,
    getBluefinMerchantDetailsMiddleware
} from '../middlewares/payments.Middleware';
import store from '../../interfaces/store.Interface';
import customTypes from '../../interfaces/customTypes.Interface';

export const SET_SQUARE_PAYMENT_NONCE = 'SET_SQUARE_PAYMENT_NONCE';
export const SET_SQUARE_CARD_STATUS = 'SET_SQUARE_CARD_STATUS';
export const SET_BLUEFIN_CARD_STATUS = 'SET_BLUEFIN_CARD_STATUS';
export const SET_PAYMENT_FORM_LOADED = 'SET_PAYMENT_FORM_LOADED';
export const SET_SQUARE_APPLICATION_ID = 'SET_SQUARE_APPLICATION_ID';
export const INC_PAYMENT_RETRY_COUNT = 'INC_PAYMENT_RETRY_COUNT';
export const RESET_PAYMENT = 'RESET_PAYMENT';
export const SET_HOSTED_TRANSACTION_ID = 'SET_HOSTED_TRANSACTION_ID';
export const SET_PROPAY_PAYMENT_STATUS = 'SET_PROPAY_PAYMENT_STATUS';
export const SET_BLUEFIN_ACCOUNT_ID = 'SET_BLUEFIN_ACCOUNT_ID';
export const SET_BLUEFIN_PAYMENT_TOKEN = 'SET_BLUEFIN_PAYMENT_TOKEN';
export const SET_BLUEFIN_DEVICE_TRANSACTION_ID = 'SET_BLUEFIN_DEVICE_TRANSACTION_ID';

export function setSquarePaymentNonceAction(nonce: store['payments']['square']['nonce']) {
    return {
        type: SET_SQUARE_PAYMENT_NONCE,
        nonce
    };
}

export function setSquareCardStatusAction(status: store['payments']['square']['cardStatus']) {
    return {
        type: SET_SQUARE_CARD_STATUS,
        status
    };
}

export function setBlueFinCardStatusAction(status: store['payments']['bluefin']['cardStatus']) {
    return {
        type: SET_BLUEFIN_CARD_STATUS,
        status
    };
}

export function setPaymentFormLoadedAction(status: store['payments']['paymentFormLoaded']) {
    return {
        type: SET_PAYMENT_FORM_LOADED,
        status
    };
}

export function setSquareApplicationIdAction(applicationId: store['payments']['square']['applicationId'],
    locationId: store['payments']['square']['locationId']) {
    let squareDetails = {
        applicationId: applicationId, locationId: locationId
    }
    return {
        type: SET_SQUARE_APPLICATION_ID,
        squareDetails
    };
}

export function setBluefinAccountIdAction(accountId: store['payments']['bluefin']['accountId']) {
    return {
        type: SET_BLUEFIN_ACCOUNT_ID,
        accountId
    };
}

export function setBlueFinPaymentTokenAction(paymentToken: store['payments']['bluefin']['paymentToken']) {
    return {
        type: SET_BLUEFIN_PAYMENT_TOKEN,
        paymentToken
    };
}

export function setBlueFinDeviceTransactionIdAction(deviceTransId: store['payments']['bluefin']['deviceTransId']) {
    return {
        type: SET_BLUEFIN_DEVICE_TRANSACTION_ID,
        deviceTransId
    };
}

export function resetPaymentAction() {
    return {
        type: RESET_PAYMENT
    };
}

export function incrementPaymentRetryCountAction() {
    return {
        type: INC_PAYMENT_RETRY_COUNT
    };
}

export function setHostedTransactionId(transactionId: string) {
    return {
        type: SET_HOSTED_TRANSACTION_ID,
        transactionId
    };
}

export function setPropayPaymentStatus(status: boolean) {
    return {
        type: SET_PROPAY_PAYMENT_STATUS,
        status
    };
}

export function paymentFrameRetryAction(callback: CallableFunction) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        if (getState().payments.retryCount < 3) {
            setTimeout(() => {
                callback();
            }, 5000);
            dispatch(incrementPaymentRetryCountAction());
        }
    };
}

export function getSquareMerchantDetailsAction(customerId: number) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return getSquareMerchantDetailsMiddleware(customerId, dispatch, getState);
    };
}

export function getHostedTransactionIdAction(customerId: number, paymentRequest: Object) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return getHostedTransactionIdMiddleware(customerId, paymentRequest, dispatch, getState);
    };
}

export function getPaymentResultAction(propayRequest: Object) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return getPropayResultMiddleware(propayRequest, dispatch, getState);
    };
}

export function getBluefinMerchantDetailsAction(customerId: number) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return getBluefinMerchantDetailsMiddleware(customerId, dispatch, getState);
    };
}
