export function getFormattedTicketList(purchasedTicketList: any) {
    let eventTicketList: Array<any> = [];
    let tempEventObject: Array<any> = [];
    purchasedTicketList.forEach((element: any) => {
        let tempTicketObj = {
            ticketId: element.ticketId,
            ticketName: element.ticketName,
            count: element.count,
            isCombo: element.isCombo,
            showId: element.showId,
            price: element.price,
            maxOrder: element.maxOrder,
            minOrder: element.minOrder,
            availableCapacity: element.availableCapacity,
            eventDetails: { id: element.eventDetails.id }
        };
        tempEventObject = eventTicketList.filter((e: any) => {
            return element.eventDetails.id === e.eventId;
        });
        if (tempEventObject && tempEventObject.length > 0) {
            updateEventObj(element, tempTicketObj, tempEventObject);
        } else {
            setNewEventObj(element, tempTicketObj, eventTicketList);
        }
    });
    return eventTicketList;
}

function updateEventObj(element: any, tempTicketObj: any, tempEventObject: any) {
    if (tempEventObject[0].showList) {
        let showObject = tempEventObject[0].showList.find((showElement: any) => {
            return showElement.showId === element.showId;
        });
        if (showObject) {
            if (showObject.ticketList && showObject.ticketList.length > 0) {
                updateTicketObj(element, tempTicketObj, showObject);
            } else {
                showObject.ticketList = [tempTicketObj];
            }
        } else {
            const newShowObject = setNewShowObj(element, tempTicketObj);
            tempEventObject[0].showList.push(newShowObject);
        }
    }
}

function updateTicketObj(element: any, tempTicketObj: any, showObject: any) {
    let tempTicket = showObject.ticketList.filter((ele: any) => {
        return element.ticketId === ele.ticketId && element.isCombo === ele.isCombo && element.showId === ele.showId;
    });
    if (tempTicket && tempTicket.length > 0) {
        tempTicket[0].count = element.count;
    } else {
        showObject.ticketList.push(tempTicketObj);
    }
}

function setNewShowObj(element: any, tempTicketObj: any) {
    let newShowObject: any = {};
    let showObj = element.eventDetails.showSchedules[0].shows.filter((showElement: any) => {
        return showElement.showId === element.showId;
    });
    if (showObj && showObj.length > 0) {
        newShowObject.showId = showObj[0].showId;
        newShowObject.showStartDate = showObj[0].fromTime;
        newShowObject.showEndDate = showObj[0].toTime;
        newShowObject.ticketList = [tempTicketObj];
    } else {
        newShowObject.showId = element.showId;
        newShowObject.showStartDate = null;
        newShowObject.showEndDate = null;
        newShowObject.ticketList = [tempTicketObj];
    }

    return newShowObject;
}

function setNewEventObj(element: any, tempTicketObj: any, eventTicketList: Array<any>) {
    let newShowObject: any = {};
    if (element.showId === 0) {
        newShowObject.showId = 0;
        newShowObject.showStartDate = null;
        newShowObject.showEndDate = null;
        newShowObject.ticketList = [tempTicketObj];
    } else {
        newShowObject = setNewShowObj(element, tempTicketObj);
    }
    let newTempEventObject = {
        eventId: element.eventDetails.id,
        eventName: element.eventDetails.name,
        showList: [newShowObject]
    };
    eventTicketList.push(newTempEventObject);
}
