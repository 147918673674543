import { CognitoUserPool, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { setToLocalStorage, setToSessionStorage, getFromLocalStorage, getCustomerIdFromSessionStorage, getRedirectionUrl } from '../../utils/utility';
import { setLoggedInStatusAction, setSessionStatusAction, setBuyerDetails } from '../actions/login.action';
import { PATH_EVENT_LIST, PATH_CHECKOUT } from '../../utils/routes';
import { getOriginalUserName } from '../../utils/ApiUrlMapping';
import { startLoader, stopLoader, setValidationMessage, toggleSigninAction, toggleSigninCartViewAction } from '../actions/app.action';
import history from '../../utils/history';
import { getCognitoRefreshExpiryTime, getCognitoSessionExpiryTime, getCongnitoPoolData, getIdentityPoolId } from '../../utils/constantsMapping';
import * as AWS from 'aws-sdk';
import { resetBillingDetailsAction } from '../actions/billing.action';
import { resetViewStatusAction, resetDeliveryMethods, reserveTicketsAction } from '../actions/checkout.action';
import { resetPaymentAction } from '../actions/payments.action';
import { resetOrderHistory } from '../actions/orderHistory';

export function doLogin(dispatch: any, isCartLogin: boolean, isSignUpLogin: boolean, loginData: any) {
    const userName = loginData.username;
    const password = loginData.password;
    AWS.config.region = 'us-west-2';
    if (userName && password) {
        dispatch(startLoader());
        const authenticationData: any = {
            Username: userName.toLowerCase(),
            Password: password
        };
        const authenticationDetails = new AuthenticationDetails(authenticationData);
        const poolData: any = getCongnitoPoolData();
        const userPool = new CognitoUserPool(poolData);
        const userData = {
            Username: userName.toLowerCase(),
            Pool: userPool
        };
        const cognitoUser = new CognitoUser(userData);
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (result: any): void => {
                loginSuccessAction(result, poolData, isCartLogin, isSignUpLogin, loginData.isCheckoutSummary, dispatch);
            },
            onFailure: (err: any): void => {
                dispatch(stopLoader());
                dispatch(setValidationMessage(err.message || 'Could not get logged in. Please try again!'));
            },
            newPasswordRequired: (userAttributes: Object, requiredAttributes: Object): void => {
                const poolData: any = getCongnitoPoolData();
                const userPool = new CognitoUserPool(poolData);
                const cognitoUser = userPool.getCurrentUser();
                if (cognitoUser) {
                    cognitoUser.signOut();
                }
                dispatch(setValidationMessage('Access Denied'));
                if (isCartLogin) {
                    dispatch(toggleSigninCartViewAction());
                } else {
                    dispatch(toggleSigninAction());
                }
            }
        });
    }
}

function loginSuccessAction(result: any, poolData: any, isCartLogin: boolean, isSignUpLogin: boolean, isCheckoutSummary: boolean, dispatch: CallableFunction) {
    setToLocalStorage('BUYER_SESSION_EXPIRY_TIME', {
        sessionExpiryTime: new Date().getTime() + getCognitoSessionExpiryTime(),
        refreshTokenExpiryTime: new Date().getTime() + getCognitoRefreshExpiryTime(),
        refreshToken: result.getRefreshToken().getToken()
    });
    dispatch(stopLoader());
    const cognitoParams: any = {
        IdentityPoolId: getIdentityPoolId(),
        Logins: {}
    };
    cognitoParams.Logins[`cognito-idp.${AWS.config.region}.amazonaws.com/${poolData.UserPoolId}`] = result.getIdToken().getJwtToken();
    const identity = new AWS.CognitoIdentity();
    dispatch(startLoader());
    identity.getId(cognitoParams, function (err, identityData) {
        dispatch(stopLoader());
        if (err) {
            return console.error(err);
        }
        const identityParams: any = {
            IdentityId: identityData.IdentityId,
            Logins: {}
        };
        identityParams.Logins[`cognito-idp.${AWS.config.region}.amazonaws.com/${poolData.UserPoolId}`] = result.getIdToken().getJwtToken();
        setToSessionStorage('_ROLE_IDENTITY_PARAMS', cognitoParams);
        dispatch(startLoader());
        identity.getCredentialsForIdentity(identityParams, function (err, data) {
            dispatch(stopLoader());
            if (err) {
                return console.error(err);
            }
            setToSessionStorage('_ROLE_AUTH_DETAILS', data);
            const accessToken = {
                AccessToken: result.getAccessToken().getJwtToken()
            };
            const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
            dispatch(startLoader());
            cognitoidentityserviceprovider.getUser(accessToken, (err: any, result: any): void => {
                dispatch(stopLoader());
                if (!err && result) {
                    setLoginDetails(result, isCartLogin, isSignUpLogin, isCheckoutSummary, dispatch);
                } else {
                    dispatch(setValidationMessage('Unable to fetch user details'));
                }
            });
        });
    });
}

function setLoginDetails(result: any, isCartLogin: boolean, isSignUpLogin: boolean, isCheckoutSummary: boolean, dispatch: CallableFunction) {
    const userDetails = getUserDetails(result);
    if (userDetails.role === 'Buyer') {
        setToLocalStorage('_BUYER_USER_DETAILS', userDetails);
        getOriginalUserNameMiddleware(dispatch);
        loginSuccess(dispatch, isCartLogin, isCheckoutSummary);
    } else {
        const poolData: any = getCongnitoPoolData();
        const userPool = new CognitoUserPool(poolData);
        const cognitoUser = userPool.getCurrentUser();
        if (cognitoUser) {
            cognitoUser.signOut();
        }
        dispatch(setValidationMessage('Access Denied'));
    }
    if (!isSignUpLogin) {
        if (isCartLogin) {
            dispatch(toggleSigninCartViewAction());
        } else {
            dispatch(toggleSigninAction());
        }
    }
}

function getUserDetails(result: any) {
    let userDetails: any = {};
    result.UserAttributes.forEach((resultObj: any, index: number): void => {
        if (resultObj.Name === 'preferred_username') {
            userDetails.name = resultObj.Value;
        }
        if (resultObj.Name === 'name') {
            userDetails.buyerName = resultObj.Value;
        }
        if (resultObj.Name === 'custom:employee_level_role') {
            userDetails.role = resultObj.Value;
        }
        if (resultObj.Name === 'email') {
            userDetails.email = resultObj.Value;
        }
    });
    if (!userDetails.name) {
        userDetails.name = result.Username;
    }

    return userDetails;
}

/**
 * @name loginSuccess
 * @desc After successful login
 */
export function loginSuccess(dispatch: any, isCartLogin: boolean, isCheckoutSummary = false): void {
    dispatch(setLoggedInStatusAction(true));
    dispatch(resetBillingDetailsAction());
    dispatch(resetViewStatusAction());
    dispatch(resetPaymentAction());
    dispatch(setSessionStatusAction(false));
    dispatch(resetOrderHistory());
    dispatch(resetDeliveryMethods());
    if (isCheckoutSummary || history.location.pathname.includes('/checkoutSummary')) {
        history.push(getRedirectionUrl(PATH_EVENT_LIST, { customerId: getCustomerIdFromSessionStorage(true) }));
    } else if (isCartLogin) {
        dispatch(reserveTicketsAction());
    }
}

export function getOriginalUserNameMiddleware(dispatch: any) {
    dispatch(startLoader());
    return getOriginalUserName()
        .then((response: any) => {
            dispatch(stopLoader());
            let buyerDetails = getFromLocalStorage('_BUYER_USER_DETAILS');
            buyerDetails.name = response.data.responseObject.username;
            setToLocalStorage('_BUYER_USER_DETAILS', buyerDetails);
            dispatch(setBuyerDetails(buyerDetails));
        })
        .catch((error) => {
            dispatch(stopLoader());
        });
}
