import { viewStatus, feeTaxDetails, ticketDeliveryOptions, promoCodeDetails } from '../interfaces/checkout.Interface';
import { paymentsCollection } from '../interfaces/payments.Interface';
import buyerBillingAddressCollection from '../interfaces/buyerBillingAddressCollection.Interface';
import { eventDetailsObject } from '../interfaces/TicketDetails.Interface';
import { purchasedTicketObject } from '../interfaces/TicketDetails.Interface';
import brandingDetails from '../interfaces/brandingDetails.Interface';
import { orderHistoryInterface } from '../interfaces/orderHistory.Interface';
import { showListDetails } from '../interfaces/showList.Interface';
import eventListData from '../interfaces/eventList.Interface';

export const viewStatusIS: viewStatus = {
    billingComponent: 'enabled',
    deliveryMethodComponent: 'disabled',
    paymentMethodComponent: 'disabled'
};

export const billingIS: buyerBillingAddressCollection = {
    billingAddress: [],
    guestBillingAddress: {
        addressId: 0
    },
    deleteBillingAddressId: 0,
    editBillingAddressId: 0,
    selectedAddress: {
        addressId: 0
    },
    mode: 'view'
};

export const paymentsIS: paymentsCollection = {
    retryCount: 0,
    paymentFormLoaded: false,
    square: {
        applicationId: '',
        locationId: '',
        cardStatus: '',
        nonce: ''
    },
    propay: {
        hostedTransactionId: '',
        isPropayPaymentDone: false
    },
    bluefin: {
        accountId: '',
        paymentToken: '',
        cardStatus: '',
        deviceTransId: null
    }
};

export const eventDetailsIS: eventDetailsObject = {
    id: 0,
    name: '',
    description: '',
    startDateTime: 0,
    endDateTime: 0,
    eventState: '',
    imageUrls: [],
    venueTickets: [],
    eventTimeZone: '',
    saleStartDateTime: 0,
    eventFeeTemplateId: 0,
    isComingSoon: false,
    showSchedules: [],
    showId: 0
};

export const feeTaxDetailsIS: feeTaxDetails = {
    feeTaxEventsList: [],
    grandTotal: 0,
    totalTicketPrice: 0,
    totalDiscount: 0,
    merchantFeeOutside: true
};

export const purchasedTicketDetailsIS: purchasedTicketObject = {
    purchasedTicketList: [],
    subTotalDetails: {
        totalAmount: 0,
        totalDiscountAmount: 0
    }
};

export const brandingIS: brandingDetails = {
    id: 0,
    logoURL: 'https://s3-us-west-2.amazonaws.com/wwls3bucket/Frontend/logo/KIS_Logo.png',
    primaryColor: '',
    secondaryColor: '',
    warningColor: '',
    bannerImageURL: '',
    backgroundImageURL: '',
    primaryColorCode: '',
    companyName: '',
    customerSupport: '',
    companyURL: ''
};

export const orderHistoryIS: orderHistoryInterface = {
    totalOrders: 0,
    buyerOrderHistoryVOs: []
};

export const showDetailsIS: showListDetails = {
    showList: {
        eventId: 0,
        eventName: '',
        imageUrls: [],
        startDateTime: 0,
        endDateTime: 0,
        eventTimeZone: '',
        schedules: [],
        venueNames: []
    }
};

export const eventListIS: eventListData = {
    eventList: [],
    pageNumber: 1,
    viewType: 'grid',
    filter: 'all',
    eventCount: 0
};

export const ticketDeliveryOptionsIS: ticketDeliveryOptions = {
    ticketDeliveryDetails: [],
    ticketDeliveryOptionType: ''
};

export const promoCodeDetailsIS: promoCodeDetails = {
    isPromoApplied: false,
    errorMessage: ''
};
