import store from '../../interfaces/store.Interface';
import {
    getFeeTaxMiddleware,
    setPromoCodeMiddleware,
    placeOrderMiddleware,
    getDeliveryOptionMiddleware,
    getCustomerSalePermissionMiddleware,
    getPreferedPaymentTypeMiddleware,
    proceedToCheckoutMiddleware,
    setSelectedDeliveryOptionMiddleware,
    isValidCartMiddleware,
    reserveTicketsMiddleware,
    applyPromoMiddleware,
    releasePromoMiddleware
} from '../middlewares/checkout.Middleware';
import customTypes from '../../interfaces/customTypes.Interface';

/**
 * action types
 */
export const SET_FEE_TAX_DETAILS = 'SET_FEE_TAX_DETAILS';
export const SET_PROMO_CODE = 'SET_PROMO_CODE';
export const SET_VIEW_STATUS = 'SET_VIEW_STATUS';
export const RESET_VIEW_STATUS = 'RESET_VIEW_STATUS';
export const SET_DELIVERY_METHODS = 'SET_DELIVERY_METHODS';
export const SET_PREFEREED_PAYMENT = 'SET_PREFEREED_PAYMENT';
export const SET_CUSTOMER_SALE_PERMISSION = 'SET_CUSTOMER_SALE_PERMISSION';
export const SET_SELECTED_DELIVERY_OPTION = 'SET_SELECTED_DELIVERY_OPTION';
export const RESET_FEE_TAX_DETAILS = 'RESET_FEE_TAX_DETAILS';
export const RESET_DELIVERY_METHODS = 'RESET_DELIVERY_METHODS';
export const RESET_PROMO = 'RESET_PROMO';
export const SET_PROMO_ERROR = 'SET_PROMO_ERROR';

/**
 * action creators
 */
export function setFeeTaxDetails(feeTaxDetails: store['checkout']['feeTaxDetails']) {
    return {
        type: SET_FEE_TAX_DETAILS,
        feeTaxDetails
    };
}

export function setViewStatusAction(data: store['checkout']['viewStatus']) {
    return {
        type: SET_VIEW_STATUS,
        data
    };
}

export function resetViewStatusAction() {
    return {
        type: RESET_VIEW_STATUS
    };
}

export function setDeliveryOptions(ticketDeliveryOptions: store['checkout']['ticketDeliveryOptions']) {
    return {
        type: SET_DELIVERY_METHODS,
        ticketDeliveryOptions
    };
}

export function setPreferredPayment(preferredpayement: store['checkout']['preferredPayment']) {
    return {
        type: SET_PREFEREED_PAYMENT,
        preferredpayement
    };
}

export function setCustomerSalePermission(customerSalePermission: store['checkout']['customerSalePermission']) {
    return {
        type: SET_CUSTOMER_SALE_PERMISSION,
        customerSalePermission
    };
}

export function setSelectedDeliveryOption(data: store['checkout']['ticketDeliveryOptions']) {
    return {
        type: SET_SELECTED_DELIVERY_OPTION,
        data
    };
}

export function resetFeeTaxDetails() {
    return {
        type: RESET_FEE_TAX_DETAILS
    };
}

export function resetDeliveryMethods() {
    return {
        type: RESET_DELIVERY_METHODS
    };
}

export function resetPromo() {
    return {
        type: RESET_PROMO
    };
}

export function setPromoErrorMessageAction(errorMessage: string) {
    return {
        type: SET_PROMO_ERROR,
        errorMessage
    };
}

export function getFeeTaxDetailsAction() {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return getFeeTaxMiddleware(dispatch, getState);
    };
}

export function applyPromoCodeAction(promocode?: any) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return setPromoCodeMiddleware(dispatch, getState, promocode);
    };
}

export function setPromoCodeDetails(promoCodeDetails: store['checkout']['promoCodeDetails']) {
    return {
        type: SET_PROMO_CODE,
        promoCodeDetails
    };
}

export function placeOrderAction() {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return placeOrderMiddleware(dispatch, getState);
    };
}

export function getDeliveryOptionAction() {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return getDeliveryOptionMiddleware(dispatch, getState);
    };
}

export function getCustomerSalePermissionAction() {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return getCustomerSalePermissionMiddleware(dispatch, getState);
    };
}

export function getPreferedPaymentTypeAction() {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return getPreferedPaymentTypeMiddleware(dispatch, getState);
    };
}

export function proceedToCheckoutAction() {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return proceedToCheckoutMiddleware(dispatch, getState);
    };
}

export function setSelectedDeliveryOptionAction(targetOption: any, eventId: any, ticketDetails: any) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return setSelectedDeliveryOptionMiddleware(dispatch, getState, targetOption, eventId, ticketDetails);
    };
}

export function isValidCart() {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return isValidCartMiddleware(dispatch, getState);
    };
}

export function reserveTicketsAction() {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return reserveTicketsMiddleware(dispatch, getState);
    };
}

export function applyPromoAction() {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return applyPromoMiddleware(dispatch, getState);
    };
}

export function releasePromoAction() {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return releasePromoMiddleware(dispatch, getState);
    };
}
