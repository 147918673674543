import React from 'react';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import './GenericPageSkeleton.scss';

const GenericPageSkeleton = () => {
    return (
        <Box className="skeleton-page-content full-width p-3 flex-column">
            <Box>
                <Skeleton width={'40%'} animation="wave" variant="rect" className="" />
                <Skeleton animation="wave" className="" />
                <Skeleton animation="wave" className="" />
                <Skeleton animation="wave" className="" />
            </Box>
            <Box display="flex" flexDirection="column" flex={1}>
                <Skeleton animation="wave" className="mt-20 skeleton-page-content-section" />
                <Skeleton animation="wave" className="mt-20 skeleton-page-content-section" />
            </Box>
        </Box>
    );
};

export default GenericPageSkeleton;
