import { startLoader, stopLoader } from '../actions/app.action';
import { setWalletTicketDetails } from '../actions/walletTicket.action';
import { getWalletTicketDetails } from '../../utils/ApiUrlMapping';
import { TASK_GET_WALLET_TICKET } from '../../utils/commonConstants';
import { getEnv } from '../../utils/utility';
import { formatDateTimetoTimeZone, getTimeZoneAbbreviation } from '../../utils/utility';
import { onTaskError } from './common.Middleware';
import customTypes from '../../interfaces/customTypes.Interface';

const ENV = getEnv();

export function getWalletTicketMiddleware(uniqueid: string, dispatch: CallableFunction, getState: customTypes['getState']) {
    dispatch(startLoader());
    return getWalletTicketDetails(uniqueid)
        .then((response: any) => {
            onTaskSuccess(dispatch, response, TASK_GET_WALLET_TICKET);
        })
        .catch((error: any) => {
            onTaskError(dispatch, error, TASK_GET_WALLET_TICKET);
        });
}

const onTaskSuccess = (dispatch: CallableFunction, response: any, task: number): void => {
    if (response.data.responseCode === 0) {
        dispatch(stopLoader());
        switch (task) {
            case TASK_GET_WALLET_TICKET:
                let data = response.data.responseObject;
                data.ticketDetailCheck = true;
                if (data && data.ticketDetails.length) {
                    data.ticketDetails.forEach((ticketObj: any) => {
                        const showStartDate = formatDateTimetoTimeZone(ticketObj.showDateTime, ticketObj.timezone, 'MMM DD, YYYY');
                        const showStartTime = formatDateTimetoTimeZone(ticketObj.showDateTime, ticketObj.timezone, 'hh:mm A');
                        const showEndDate = formatDateTimetoTimeZone(ticketObj.showEndDateTime, ticketObj.timezone, 'MMM DD, YYYY');
                        const showEndTime = formatDateTimetoTimeZone(ticketObj.showEndDateTime, ticketObj.timezone, 'hh:mm A');
                        const timeZoneStart = getTimeZoneAbbreviation(ticketObj.showDateTime, ticketObj.eventTimeZone);
                        const timezoneEnd = getTimeZoneAbbreviation(ticketObj.showEndDateTime, ticketObj.eventTimeZone);
                        let dateTimeString =
                            showStartDate +
                            ' at ' +
                            showStartTime +
                            (timeZoneStart !== timezoneEnd ? ' ' + timeZoneStart : '') +
                            ' to ' +
                            showEndDate +
                            ' at ' +
                            showEndTime +
                            ' ' +
                            timezoneEnd;
                        if ((ENV && ENV === 'uat' && data.customerId === 296) || (ENV && ENV === 'prod' && data.customerId === 209)) {
                            dateTimeString = showStartDate + ' to ' + showEndDate;
                        }
                        ticketObj.dateTimeString = dateTimeString;
                    });
                    dispatch(setWalletTicketDetails(data));
                } else {
                    data.ticketDetailCheck = true;
                    dispatch(setWalletTicketDetails(data));
                    onTaskError(dispatch, response, task);
                }
                break;
            default:
                break;
        }
    } else {
        onTaskError(dispatch, response, task);
    }
};
