import { doSignUpMiddleWare } from '../middlewares/signUp.Middleware';
import { doLogin } from '../middlewares/login.Middleware';
import { signUpObject } from '../../interfaces/signUp.Interface';
import customTypes from '../../interfaces/customTypes.Interface';
/**
 * action types
 */
export const DO_SIGNUP = 'DO_SIGNUP';
export const SET_RESPONSE = 'SET_RESPONSE';
export const SHOW_CONFIRMATION = 'SHOW_CONFIRMATION';
export const RESET_SIGNUP_FORM = 'RESET_SIGNUP_FORM';
export const SET_FIELDS = 'SET_FIELDS';

/**
 * action creators
 */
export function signUp() {
    return {
        type: DO_SIGNUP
    };
}

export function reCaptchaAction(response: boolean) {
    return {
        type: SET_RESPONSE,
        response
    };
}

export function showConfirmationAction(confirm: boolean) {
    return {
        type: SHOW_CONFIRMATION,
        confirm
    };
}

export function signUpAction(signUpDetails: signUpObject) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return doSignUpMiddleWare(dispatch, signUpDetails);
    };
}

export function doLoginAfterSignUpAction(isCartLogin: boolean, signUpDetails: signUpObject) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return doLogin(dispatch, isCartLogin, true, signUpDetails);
    };
}

export function resetSignUpAction() {
    return {
        type: RESET_SIGNUP_FORM
    };
}

export function setSignupFieldsAction(signUpDetails: signUpObject) {
    return {
        type: SET_FIELDS,
        signUpDetails
    };
}
