import React from 'react';
import usePageName from '../../../hooks/usePageName';
import ContainerSkeletonProvider from '../Skeleton/ContainerSkeletonProvider.Component';
import LayoutContainer from '../layoutContainer/LayoutContainer.Component';
import GenericPageSkeleton from '../Skeleton/GenericPageSkeleton.Component';

interface SkeletonProviderProps {
    isLayoutRequired?: boolean;
}

const SkeletonProvider = (props: SkeletonProviderProps) => {
    const { page } = usePageName();

    let skeleton;
    switch (page) {
        case 'landing':
            skeleton = <ContainerSkeletonProvider />;
            break;
        case 'paymentFrame':
            skeleton = <></>;
            break;
        default:
            if (props.isLayoutRequired) {
                skeleton = (
                    <LayoutContainer>
                        <GenericPageSkeleton />
                    </LayoutContainer>
                );
            } else {
                skeleton = <GenericPageSkeleton />;
            }
            break;
    }

    return skeleton;
};

export default SkeletonProvider;
