export const PATH_LANDING = '/landing';
export const PATH_PAGE_NOT_FOUND = '/:customerId/page-not-found';
export const PATH_EVENT_LIST = '/:customerId/event-list';
export const PATH_SHOW_LIST = '/:customerId/shows/:eventId';
export const PATH_SHOW_LIST_FROM_CATEGORY = '/:customerId/shows/:eventId/:categoryId';
export const PATH_TICKET_LIST = '/:customerId/event/:eventId';
export const PATH_TICKET_SHOW_LIST = '/:customerId/event/:eventId/:showId';
export const PATH_TICKET_CATEGORY_LIST = '/:customerId/event/:eventId/category/:categoryId'
export const PATH_TICKET_CATEGORY_SHOW_LIST = '/:customerId/event/:eventId/:showId/:categoryId'
export const PATH_SUPPORT = '/:customerId/support';
export const PATH_ORDER_HISTORY = '/:customerId/order-history';
export const PATH_CART = '/:customerId/cart';
export const PATH_CHECKOUT = '/:customerId/checkout';
export const PATH_BILLING = '/:customerId/billing';
export const PATH_CHECKOUT_SUMMARY = '/:customerId/checkoutSummary';
export const PATH_WALLET_TICKET = '/pkpass/:uniqueId';
export const PATH_PAYMENT_PROGRESS = '/paymentProgress';
