import React, { Suspense } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import {
    PATH_PAGE_NOT_FOUND,
    PATH_EVENT_LIST,
    PATH_TICKET_LIST,
    PATH_TICKET_SHOW_LIST,
    PATH_SHOW_LIST,
    PATH_SUPPORT,
    PATH_BILLING,
    PATH_CHECKOUT,
    PATH_CART,
    PATH_ORDER_HISTORY,
    PATH_CHECKOUT_SUMMARY,
    PATH_WALLET_TICKET,
    PATH_PAYMENT_PROGRESS,
    PATH_LANDING,
    PATH_TICKET_CATEGORY_LIST,
    PATH_SHOW_LIST_FROM_CATEGORY,
    PATH_TICKET_CATEGORY_SHOW_LIST
} from './routes';
import history from './history';
import ReactLazyPreload from '../utils/lazyhandler';
import ContainerSkeletonProvider from '../modules/main/Skeleton/ContainerSkeletonProvider.Component';
import SkeletonProvider from '../modules/main/skeletonProvider/SkeletonProvider.Component';
import PaymentProgress from '../modules/containers/paymentProgress/paymentProgress.Component';

const simulateLongerLazyLoad = (): any =>
    new Promise((resolve, reject): any => {
        setTimeout(() => {
            resolve();
        }, 1000000000);
    });

const Layout = ReactLazyPreload(() => import('../modules/main/layout/Layout'));

const ValidRouteParams = ReactLazyPreload(() => import('../modules/main/validRouteParams/ValidRouteParams.Component'));
const App = ReactLazyPreload(() => import('../modules/App'));
const Landing = ReactLazyPreload(() => import('../modules/containers/landing/Landing.Container'));
const EventList = ReactLazyPreload(() => import('../modules/containers/eventList/EventList.Container'));
const TicketListDetails = ReactLazyPreload(() => import('../modules/containers/ticketList/TicketList.Container'));
const ShowListDetails = ReactLazyPreload(() => import('../modules/containers/showList/ShowList.Container'));
// const SupportDetails = ReactLazyPreload(() =>
//     simulateLongerLazyLoad()
// );
const SupportDetails = ReactLazyPreload(() => import('../modules/containers/support/Support.Container'));
const Checkout = ReactLazyPreload(() => import('../modules/containers/checkout/Checkout'));
const Billing = ReactLazyPreload(() => import('../modules/containers/billing/Billing'));
const OrderHistory = ReactLazyPreload(() => import('../modules/containers/orderHistory/OrderHistoryContainer'));
const CartDetails = ReactLazyPreload(() => import('../modules/containers/cart/Cart.Container'));
const CheckoutSummary = ReactLazyPreload(() => import('../modules/containers/checkoutSummary/CheckoutSummary.Container'));
const WalletTicket = ReactLazyPreload(() => import('../modules/containers/walletTicket/WalletTicket.Container'));

const PageNotFound = ReactLazyPreload(() => import('../modules/containers/pageNotFound/pageNotFound.Component'));
const WildCardRoutingHandler = ReactLazyPreload(() => import('../modules/main/wildCardRoutingHandler/WildCardRoutingHandler.Component'));

Layout.preload();
EventList.preload();
TicketListDetails.preload();
ValidRouteParams.preload();
WildCardRoutingHandler.preload();
App.preload();
PageNotFound.preload();
CheckoutSummary.preload();

const Routing = () => {
    return (
        <>
            <Router history={history}>
                <Suspense fallback={<ContainerSkeletonProvider />}>
                    <Layout>
                        <Suspense fallback={<SkeletonProvider isLayoutRequired={true} />}>
                            <Switch>
                                <Redirect exact={true} from="/" to={PATH_LANDING} />
                                <Route exact={true} path={PATH_LANDING}>
                                    <Landing />
                                </Route>
                                <Route exact={true} path={PATH_EVENT_LIST}>
                                    <ValidRouteParams>
                                        <App>
                                            <EventList />
                                        </App>
                                    </ValidRouteParams>
                                </Route>
                                <Route exact={true} path={[PATH_TICKET_LIST, PATH_TICKET_SHOW_LIST, PATH_TICKET_CATEGORY_LIST, PATH_TICKET_CATEGORY_SHOW_LIST]}>
                                    <ValidRouteParams>
                                        <App>
                                            <TicketListDetails />
                                        </App>
                                    </ValidRouteParams>
                                </Route>
                                <Route exact={true} path={[PATH_SHOW_LIST, PATH_SHOW_LIST_FROM_CATEGORY]}>
                                    <ValidRouteParams>
                                        <App>
                                            <ShowListDetails />
                                        </App>
                                    </ValidRouteParams>
                                </Route>
                                <Route exact={true} path={PATH_SUPPORT}>
                                    <ValidRouteParams>
                                        <App>
                                            <SupportDetails />
                                        </App>
                                    </ValidRouteParams>
                                </Route>
                                <Route exact={true} path={PATH_CHECKOUT}>
                                    <ValidRouteParams>
                                        <App>
                                            <Checkout />
                                        </App>
                                    </ValidRouteParams>
                                </Route>
                                <Route exact={true} path={PATH_BILLING}>
                                    <ValidRouteParams>
                                        <App>
                                            <Billing />
                                        </App>
                                    </ValidRouteParams>
                                </Route>
                                <Route exact={true} path={PATH_ORDER_HISTORY}>
                                    <ValidRouteParams>
                                        <App>
                                            <OrderHistory />
                                        </App>
                                    </ValidRouteParams>
                                </Route>
                                <Route exact={true} path={PATH_CART}>
                                    <ValidRouteParams>
                                        <App>
                                            <CartDetails />
                                        </App>
                                    </ValidRouteParams>
                                </Route>
                                <Route exact={true} path={PATH_CHECKOUT_SUMMARY}>
                                    <ValidRouteParams>
                                        <App>
                                            <CheckoutSummary />
                                        </App>
                                    </ValidRouteParams>
                                </Route>

                                <Route exact={true} path={PATH_WALLET_TICKET}>
                                    <WalletTicket />
                                </Route>

                                {/* ADD ALL CUSTOM ROUTES ABOVE THIS BLOCK AS SWITCH HANDLES CASES IN THE PROVIDED ORDER*/}
                                <Route exact={true} path={PATH_PAGE_NOT_FOUND}>
                                    <ValidRouteParams>
                                        <App>
                                            <PageNotFound />
                                        </App>
                                    </ValidRouteParams>
                                </Route>
                                <Route exact={true} path={PATH_PAYMENT_PROGRESS}>
                                    <PaymentProgress />
                                </Route>
                                <Route path="*">
                                    <WildCardRoutingHandler />
                                </Route>
                            </Switch>
                        </Suspense>
                    </Layout>
                </Suspense>
            </Router>
        </>
    );
};

export default Routing;
