import store from '../../interfaces/store.Interface';
import addBillingAddressRequest from '../../interfaces/buyerBillingAddress.Interface';
import {
    deleteAddressMiddleware,
    setDefaultBillingAddressMiddleware,
    getBillingDetailsMiddleware,
    addBillingDetailsMiddleware,
    updateBillingDetailsMiddleware,
    addGuestBillingDetailsMiddleware
} from '../middlewares/billing.Middleware';
import customTypes from '../../interfaces/customTypes.Interface';

export const SET_BILLING_DETAILS = 'GET_BILLING_DETAILS';
export const SET_DELETE_CONFIRMATION = 'SET_DELETE_CONFIRMATION';
export const SET_BILLING_COMPONENT_MODE = 'SET_BILLING_COMPONENT_MODE';
export const RESET_BILLING = 'RESET_BILLING';
export const SET_SELECTED_ADDRESS = 'SET_SELECTED_ADDRESS';
export const SET_GUEST_BILLING_DETAILS = 'SET_GUEST_BILLING_DETAILS';

export const setBillingDetailsAction = (data: store['billing']['billingAddress']) => {
    return {
        type: SET_BILLING_DETAILS,
        data
    };
};

export const setDeleteConfirmationAction = (addressId: store['billing']['deleteBillingAddressId']) => {
    return {
        type: SET_DELETE_CONFIRMATION,
        addressId
    };
};

export const setBillingComponentModeAction = (mode: store['billing']['mode'], addressId: store['billing']['editBillingAddressId'] = 0) => {
    return {
        type: SET_BILLING_COMPONENT_MODE,
        mode,
        addressId
    };
};

export const resetBillingDetailsAction = () => {
    return {
        type: RESET_BILLING
    };
};

export const setSelectedAddressAction = (data: store['billing']['selectedAddress']) => {
    return {
        type: SET_SELECTED_ADDRESS,
        data
    };
};

export const setGuestBillingDeatilsAction = (data: store['billing']['guestBillingAddress']) => {
    return {
        type: SET_GUEST_BILLING_DETAILS,
        data
    };
};

export function getBillingDetailsAction() {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return getBillingDetailsMiddleware(dispatch, getState);
    };
}

export function addBillingDetailsAction(addBillingRequest: addBillingAddressRequest) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return addBillingDetailsMiddleware(addBillingRequest, dispatch, getState);
    };
}

export function updateBillingDetailsAction(addBillingRequest: addBillingAddressRequest) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return updateBillingDetailsMiddleware(addBillingRequest, dispatch, getState);
    };
}

export function setDefaultBillingAddressAction(addressId: string) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return setDefaultBillingAddressMiddleware(dispatch, getState, addressId);
    };
}

export function deleteAddressAction(addressId: string) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return deleteAddressMiddleware(dispatch, getState, addressId);
    };
}

export function addGuestBillingDetailsAction(billingAddress: addBillingAddressRequest) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return addGuestBillingDetailsMiddleware(billingAddress, dispatch, getState);
    };
}
