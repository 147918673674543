import { getVenueDetails, getTicketsForEvents } from '../../utils/ApiUrlMapping';
import { setVenueDetails, setTicketList, getVenueDetailsAction, updatePurchasedTicketDetails, setEventDetails } from '../actions/ticketList';
import ticketDetails, { ticketDetailsObject, purchasedTicketObject, eventDetailsObject, eventDetailForHeader } from '../../interfaces/TicketDetails.Interface';
import stateList from '../../utils/data/stateList.json';
import {
    setToSessionStorage,
    getFromSessionStorage,
    validateAddOrEditTicketChange,
    validateRemoveTicketChange,
    formatShowDateAndTime,
    formatDateTimeWithoutDay,
    getUTCDateObject,
} from '../../utils/utility';
import { setValidationMessage, startLoader, stopLoader, setBackgroundMessage } from '../actions/app.action';
import history from '../../utils/history';
import customTypes from '../../interfaces/customTypes.Interface';
import store from '../../interfaces/store.Interface';
import clonedeep from 'lodash.clonedeep';
import { releaseCartAction } from '../actions/cartReservation.action';

export function getVenueDetailsMiddleware(dispatch: any, getState: customTypes['getState'], venueDetailsRequest: any) {
    dispatch(startLoader());
    return getVenueDetails(venueDetailsRequest)
        .then((response: any) => {
            dispatch(stopLoader());
            let data = response.data.responseObject;
            if (response.data.responseCode === 0) {
                data = getValidVenues(data);
                if (data && data.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        data[i]['venueCode'] = getStateAbbreviation(data[i].venueState);
                    }
                    dispatch(setVenueDetails(data));
                }
                setVenueNameForTickets(dispatch, data, getState);
            } else {
                dispatch(setValidationMessage(response.data.responseMessage));
                dispatch(setBackgroundMessage(response.data.responseMessage));
            }
        })
        .catch((error) => {
            dispatch(stopLoader());
        });
}

function setVenueDetailsRequest(venueTicketDetails: ticketDetails['venueDetails']) {
    let venueDetailsRequest: {
        customerId: number;
        venueIds: number[];
    } = {
        customerId: Number(getFromSessionStorage('CUSTOMER_ID')),
        venueIds: []
    };
    venueTicketDetails.forEach((element) => {
        venueDetailsRequest.venueIds.push(element.venueId);
    });
    return venueDetailsRequest;
}

function setVenueNameForTickets(dispatch: any, venueDetails: any, getState: customTypes['getState']) {
    let ticketList = getState().ticketDetails.ticketList;
    //To remove the "NO_VENUE" details.
    const venueDetail = venueDetails.filter((venue: any) => venue.venueId !== -1);
    ticketList.forEach((ticket: any) => {
        if (!ticket.venueDetails.venueName) {
            ticket.venueDetails.venueName = venueDetail[0].venueName;
        }
    });
    dispatch(setTicketList(ticketList));
}

export function getTicketListMiddleware(dispatch: any, getState: customTypes['getState'], ticketListRequest: any) {
    dispatch(startLoader());
    return getTicketsForEvents(ticketListRequest.customerId, ticketListRequest.eventId, ticketListRequest.showId, ticketListRequest.categoryId)
        .then((response: any) => {
            dispatch(stopLoader());
            if (response.data.responseCode === 0) {
                let showSchedules = response.data.responseObject.eventDetails.showSchedules;
                if (showSchedules && showSchedules.length > 0 && ticketListRequest.showId === 0) {
                    if (ticketListRequest.categoryId) {
                        history.push(`/${decodeURIComponent(ticketListRequest.customerId)}/shows/${ticketListRequest.eventId}/${ticketListRequest.categoryId}`);
                    } else {
                        history.push(`/${decodeURIComponent(ticketListRequest.customerId)}/shows/${ticketListRequest.eventId}`);
                    }
                } else {
                    let dataFormatted = processTicketListResponse(response.data.responseObject);
                    dispatch(setTicketList(dataFormatted));
                    let eventDetails = processEventDetails(response.data.responseObject.eventDetails, ticketListRequest.showId);
                    dispatch(setEventDetails(eventDetails));
                    let venueDetailsRequest = setVenueDetailsRequest(response.data.responseObject.venueTicketDetails);
                    dispatch(getVenueDetailsAction(venueDetailsRequest));
                    updateCartWithTicketDetails(dispatch, getState, dataFormatted);
                    updatePurchasedSum(dispatch, getState);
                }
            } else {
                dispatch(setValidationMessage(response.data.responseMessage));
                history.push(`/${decodeURIComponent(ticketListRequest.customerId)}/event-list`);
            }
        })
        .catch((error) => {
            dispatch(stopLoader());
        });
}

function getNormalTicketShowId(ticket: any) {
    let showId = 0;
    if (ticket.aggregateTicketCapacity && ticket.aggregateTicketCapacity[0] && ticket.aggregateTicketCapacity[0].showId) {
        showId = ticket.aggregateTicketCapacity[0].showId;
    }

    return showId;
}

function getComboTicketShowId(ticket: any) {
    let showId = 0;
    if (ticket.aggregateComboCapacity && ticket.aggregateComboCapacity[0] && ticket.aggregateComboCapacity[0].showId) {
        showId = ticket.aggregateComboCapacity[0].showId;
    }

    return showId;
}

function processTicketListResponse(data: any): ticketDetails['ticketList'] {
    let ticketListFormatted: Array<any> = [];
    let ticketItem: any = {};
    if (data.venueTicketDetails && data.venueTicketDetails.length > 0) {
        data.venueTicketDetails.forEach((element: any) => {
            let venueDetails = {
                id: element.id,
                venueId: element.venueId,
                status: element.status
            };
            if (element.tickets && element.tickets.length > 0) {
                element.tickets.forEach((e: any) => {
                    ticketItem = {};
                    ticketItem.ticketName = e.ticketName;
                    ticketItem.description = e.ticketDescription;
                    ticketItem.isDescExpanded = true;
                    ticketItem.maxOrder = e.maxOrder;
                    ticketItem.minOrder = e.minOrder;
                    ticketItem.displayTicketCapacity = e.displayTicketCapacity;
                    ticketItem.ticketType = e.ticketType;
                    ticketItem.price = parseFloat(e.ticketPrice).toFixed(2);
                    ticketItem.wholePrice = ticketItem.price.split('.')[0];
                    ticketItem.decimalPrice = ticketItem.price.split('.')[1];
                    ticketItem.ticketId = e.ticketId;
                    ticketItem.venueTicketId = e.venueTicketId;
                    ticketItem.nonPrinting = e.nonPrinting;
                    ticketItem.orderIndex = e.orderIndex;
                    ticketItem.originalPrice = e.originalPrice;
                    ticketItem.isCombo = false;
                    ticketItem.showId = getNormalTicketShowId(e);
                    ticketItem.availableCapacity = e.aggregateTicketCapacity[0].availableCapacity;
                    ticketItem.eventDetails = data.eventDetails;
                    ticketItem.venueDetails = venueDetails;
                    ticketItem.count = 0;
                    ticketListFormatted.push(ticketItem);
                });
            }
            if (element.comboTickets && element.comboTickets.length > 0) {
                element.comboTickets.forEach((e: any) => {
                    ticketItem = {};
                    ticketItem.ticketName = e.ticketComboName;
                    ticketItem.description = e.ticketDescription;
                    ticketItem.isDescExpanded = true;
                    ticketItem.maxOrder = e.maxOrder;
                    ticketItem.minOrder = e.minOrder;
                    ticketItem.displayTicketCapacity = e.displayTicketCapacity;
                    ticketItem.price = parseFloat(e.price).toFixed(2);
                    ticketItem.wholePrice = ticketItem.price.split('.')[0];
                    ticketItem.decimalPrice = ticketItem.price.split('.')[1];
                    ticketItem.ticketId = e.ticketComboId;
                    ticketItem.venueTicketId = e.venueTicketId;
                    ticketItem.orderIndex = e.orderIndex;
                    ticketItem.originalPrice = e.originalPrice;
                    ticketItem.isCombo = true;
                    ticketItem.nonPrinting = e.nonPrinting;
                    ticketItem.showId = getComboTicketShowId(e);
                    ticketItem.availableCapacity = e.aggregateComboCapacity[0].availableCapacity;
                    ticketItem.eventDetails = data.eventDetails;
                    ticketItem.venueDetails = venueDetails;
                    ticketItem.count = 0;
                    ticketListFormatted.push(ticketItem);
                });
            }
        });
    }
    ticketListFormatted.sort((a, b) => {
        return a.orderIndex - b.orderIndex;
    });
    return ticketListFormatted;
}

const updateCountInTicketList = (dispatch: CallableFunction, getState: customTypes['getState'], ticketObject: ticketDetailsObject, count: number) => {
    let ticketList = [...getState().ticketDetails.ticketList];
    const ticketListObj = ticketList.find((element: any) => {
        return element.showId === ticketObject.showId && element.isCombo === ticketObject.isCombo && element.ticketId === ticketObject.ticketId;
    });
    if (ticketListObj) {
        ticketListObj.count = count;
    }
    dispatch(setTicketList(ticketList));
};

export function addTicketToCartMiddleware(dispatch: CallableFunction, getState: customTypes['getState'], ticketObject: ticketDetailsObject, newValue: any, currentShowId: string) {
    let ticketObjectClone = { ...ticketObject };
    ticketObjectClone.count = newValue + 1;
    let purchasedTicketDetails = clonedeep(getState().ticketDetails.purchasedTicketDetails);
    let isValidAndMessage = validateAddOrEditTicketChange(ticketObjectClone, ticketObjectClone.count, currentShowId);
    if (purchasedTicketDetails && purchasedTicketDetails.purchasedTicketList) {
        let tempObj = purchasedTicketDetails.purchasedTicketList.find((element: any) => {
            return element.ticketId === ticketObjectClone.ticketId && element.isCombo === ticketObjectClone.isCombo && element.showId === ticketObjectClone.showId;
        });
        if (tempObj) {
            if (isValidAndMessage.isValid) {
                tempObj.count++;
            } else {
                tempObj.count = ticketObjectClone.count;
            }
        } else {
            if (isValidAndMessage.isValid) {
                ticketObjectClone.count = 1;
                purchasedTicketDetails.purchasedTicketList.push(ticketObjectClone);
            }
        }
        updateCountInTicketList(dispatch, getState, ticketObjectClone, ticketObjectClone.count);
        purchasedTicketDetails.subTotalDetails = getSubTotal(purchasedTicketDetails.purchasedTicketList);
        setToSessionStorage('PURCHASED_TICKET_DETAILS', purchasedTicketDetails);
        dispatch(updatePurchasedTicketDetails(purchasedTicketDetails));
    }
    if (!isValidAndMessage.isValid) {
        dispatch(setValidationMessage(isValidAndMessage.message));
    }
}

export function removeTicketFromCartMiddleware(dispatch: CallableFunction, getState: customTypes['getState'], ticketObject: ticketDetailsObject, currentShowId: string) {
    let ticketObjectClone = { ...ticketObject };
    let purchasedTicketDetails = clonedeep(getState().ticketDetails.purchasedTicketDetails);
    let count = 0;
    let isValidAndMessage = validateAddOrEditTicketChange(ticketObjectClone, ticketObjectClone.count, currentShowId);
    if (purchasedTicketDetails && purchasedTicketDetails.purchasedTicketList) {
        let tempObj = purchasedTicketDetails.purchasedTicketList.find((element: any) => {
            return element.ticketId === ticketObject.ticketId && element.isCombo === ticketObject.isCombo && element.showId === ticketObject.showId;
        });
        if (tempObj) {
            if (isValidAndMessage.isValid) {
                tempObj.count--;
            } else {
                tempObj.count = ticketObjectClone.count;
            }
            count = tempObj.count;
            if (tempObj.count <= 0) {
                count = 0;
                purchasedTicketDetails.purchasedTicketList = purchasedTicketDetails.purchasedTicketList.filter((element: any) => {
                    return !(element.ticketId === ticketObject.ticketId && element.isCombo === ticketObject.isCombo && element.showId === ticketObject.showId);
                });
            }
        }
        updateCountInTicketList(dispatch, getState, ticketObject, count);
        purchasedTicketDetails.subTotalDetails = getSubTotal(purchasedTicketDetails.purchasedTicketList);
        setToSessionStorage('PURCHASED_TICKET_DETAILS', purchasedTicketDetails);
        dispatch(updatePurchasedTicketDetails(purchasedTicketDetails));
        checkReleaseCartRequired(dispatch, getState, purchasedTicketDetails);
    }
    if (!isValidAndMessage.isValid) {
        dispatch(setValidationMessage(isValidAndMessage.message));
    }
}

export function editTicketCountMiddleware(
    dispatch: CallableFunction,
    getState: customTypes['getState'],
    ticketObject: ticketDetailsObject,
    newValue: string,
    currentShowId: string
) {
    let ticketObjectClone = { ...ticketObject };
    let purchasedTicketDetails = clonedeep(getState().ticketDetails.purchasedTicketDetails);
    let isValidAndMessage = validateAddOrEditTicketChange(ticketObjectClone, Number(newValue), currentShowId);
    let count = isValidAndMessage.isValid ? Number(newValue) : ticketObjectClone.count;
    if (purchasedTicketDetails && purchasedTicketDetails.purchasedTicketList) {
        let tempObj = purchasedTicketDetails.purchasedTicketList.find((element: any) => {
            return element.ticketId === ticketObjectClone.ticketId && element.isCombo === ticketObjectClone.isCombo && element.showId === ticketObjectClone.showId;
        });
        if (tempObj) {
            tempObj.count = count;
            if (tempObj.count <= 0) {
                purchasedTicketDetails.purchasedTicketList = purchasedTicketDetails.purchasedTicketList.filter((element: any) => {
                    return !(element.ticketId === ticketObjectClone.ticketId && element.isCombo === ticketObjectClone.isCombo && element.showId === ticketObjectClone.showId);
                });
            }
        } else {
            ticketObjectClone.count = count;
            if (ticketObjectClone.count > 0) {
                purchasedTicketDetails.purchasedTicketList.push(ticketObjectClone);
            }
        }
        updateCountInTicketList(dispatch, getState, ticketObjectClone, count);
        purchasedTicketDetails.subTotalDetails = getSubTotal(purchasedTicketDetails.purchasedTicketList);
        setToSessionStorage('PURCHASED_TICKET_DETAILS', purchasedTicketDetails);
        dispatch(updatePurchasedTicketDetails(purchasedTicketDetails));
        checkReleaseCartRequired(dispatch, getState, purchasedTicketDetails);
    }
    if (!isValidAndMessage.isValid) {
        dispatch(setValidationMessage(isValidAndMessage.message));
    }
}

export function saveTicketDetailsMiddleware(dispatch: CallableFunction, ticketObject: purchasedTicketObject) {
    dispatch(updatePurchasedTicketDetails({ ...ticketObject }));
}

function getSubTotal(purchasedTicketList: any): store['ticketDetails']['purchasedTicketDetails']['subTotalDetails'] {
    let totalAmount = 0;
    purchasedTicketList.forEach((element: any) => {
        totalAmount += element.count * element.price;
    });
    let totalObj: {
        totalAmount: number;
        totalDiscountAmount: number;
    } = {
        totalAmount,
        totalDiscountAmount: totalAmount
    };
    return totalObj;
}

export function getStateAbbreviation(stateName: string): string {
    let stateCode = '';
    const selectedState = stateList.filter((state) => {
        return state.name === stateName;
    });
    if (selectedState && selectedState.length) {
        stateCode = selectedState[0].alpha2;
    }
    return stateCode;
}

function getValidVenues(venueDetails: Array<Object>): Array<Object> {
    const filteredvenueDetails = venueDetails.filter((venue: any, index: number) => {
        return venue.venueId !== -1;
    });
    return filteredvenueDetails;
}

export function deleteTicketFromCartMiddleware(dispatch: CallableFunction, getState: customTypes['getState'], ticketObject: ticketDetailsObject) {
    let purchasedTicketDetails = clonedeep(getState().ticketDetails.purchasedTicketDetails);
    if (purchasedTicketDetails && purchasedTicketDetails.purchasedTicketList) {
        let tempObj = purchasedTicketDetails.purchasedTicketList.filter((element: any) => {
            return element.ticketId === ticketObject.ticketId && element.isCombo === ticketObject.isCombo && element.showId === ticketObject.showId;
        });
        if (tempObj && tempObj.length > 0) {
            purchasedTicketDetails.purchasedTicketList = purchasedTicketDetails.purchasedTicketList.filter((element: any) => {
                return !(element.ticketId === ticketObject.ticketId && element.isCombo === ticketObject.isCombo && element.showId === ticketObject.showId);
            });
            ticketObject.count = 0;
        }
        updateCountInTicketList(dispatch, getState, ticketObject, 0);
        purchasedTicketDetails.subTotalDetails = getSubTotal(purchasedTicketDetails.purchasedTicketList);
        setToSessionStorage('PURCHASED_TICKET_DETAILS', purchasedTicketDetails);
        dispatch(updatePurchasedTicketDetails(purchasedTicketDetails));
        checkReleaseCartRequired(dispatch, getState, purchasedTicketDetails);
    }
}

export function isValidMinOrderMiddleware(dispatch: CallableFunction, getState: customTypes['getState'], purchasedTicketList: Array<ticketDetailsObject>) {
    let isValidAndMessage: {
        isValid: boolean;
        message: string;
    } = {
        isValid: true,
        message: ''
    };
    if (purchasedTicketList && purchasedTicketList.length > 0) {
        purchasedTicketList.forEach(function (element) {
            if (isValidAndMessage.isValid) {
                isValidAndMessage = validateRemoveTicketChange(element);
                if (!isValidAndMessage.isValid) {
                    dispatch(setValidationMessage(isValidAndMessage.message));
                    isValidAndMessage.isValid = false;
                }
            }
        });
    }
}

function processEventDetails(eventResponse: eventDetailsObject, showId: number): eventDetailForHeader {
    let showStartDateTime = '';
    let showEndDateTime = '';
    if (eventResponse.showSchedules && eventResponse.showSchedules.length > 0 && eventResponse.showSchedules[0].shows) {
        let tempObj = eventResponse.showSchedules[0].shows.filter((element) => {
            return Number(showId) === element.showId;
        });
        const startDateTime = getUTCDateObject(tempObj[0].fromTime);
        const endDateTime = getUTCDateObject(tempObj[0].toTime);
        showStartDateTime = formatDateTimeWithoutDay(startDateTime);
        showEndDateTime = formatDateTimeWithoutDay(endDateTime);
    }

    let eventDetails: eventDetailForHeader = {
        id: eventResponse.id,
        name: eventResponse.name,
        description: eventResponse.description ? eventResponse.description : '',
        startDateTime: showId ? showStartDateTime : formatShowDateAndTime(eventResponse.startDateTime),
        endDateTime: showId ? showEndDateTime : formatShowDateAndTime(eventResponse.endDateTime),
        eventTimeZone: eventResponse.eventTimeZone,
        showId: showId ? showId : 0
    };

    return eventDetails;
}

export function updateTicketCapacityMiddleware(dispatch: CallableFunction, getState: customTypes['getState'], capacityResponse: any): void {
    let ticketList = [...getState().ticketDetails.ticketList];
    let purchasedTicketDetails = clonedeep(getState().ticketDetails.purchasedTicketDetails);
    capacityResponse.forEach((capacityTicket: any) => {
        if (capacityTicket && capacityTicket.availableCapacity >= 0) {
            const ticketListObj = ticketList.find((ticketObject: any) => {
                return (
                    ticketObject.eventDetails.id === capacityTicket.eventId &&
                    ticketObject.showId === capacityTicket.showId &&
                    ticketObject.isCombo === capacityTicket.isCombo &&
                    ticketObject.ticketId === capacityTicket.ticketId
                );
            });
            if (ticketListObj) {
                ticketListObj.availableCapacity = capacityTicket.availableCapacity;
            }
            const purchaseListObj = purchasedTicketDetails.purchasedTicketList.find((ticketObj: any) => {
                return (
                    ticketObj.eventDetails.id === capacityTicket.eventId &&
                    ticketObj.showId === capacityTicket.showId &&
                    ticketObj.isCombo === capacityTicket.isCombo &&
                    ticketObj.ticketId === capacityTicket.ticketId
                );
            });
            if (purchaseListObj) {
                purchaseListObj.availableCapacity = capacityTicket.availableCapacity;
            }
        }
    });
    setToSessionStorage('PURCHASED_TICKET_DETAILS', purchasedTicketDetails);
    dispatch(updatePurchasedTicketDetails(purchasedTicketDetails));
    dispatch(setTicketList(ticketList));
}

const updateCartWithTicketDetails = (dispatch: CallableFunction, getState: customTypes['getState'], eventTicketList: Array<ticketDetailsObject>) => {
    let purchasedTicketDetails = getState().ticketDetails.purchasedTicketDetails;
    purchasedTicketDetails.purchasedTicketList.forEach((cartTicketObj: any, idx: number) => {
        let ticketObj = cartTicketObj;
        eventTicketList.forEach((eventTicketObj: any) => {
            if (
                cartTicketObj.eventDetails.id === eventTicketObj.eventDetails.id &&
                cartTicketObj.showId === eventTicketObj.showId &&
                cartTicketObj.isCombo === eventTicketObj.isCombo &&
                cartTicketObj.ticketId === eventTicketObj.ticketId
            ) {
                ticketObj = eventTicketObj;
                ticketObj.count = cartTicketObj.count;
                ticketObj.venueDetails = { ...ticketObj.venueDetails, venueName: cartTicketObj.venueDetails.venueName };
            }
        });
        purchasedTicketDetails.purchasedTicketList[idx] = ticketObj;
    });
    setToSessionStorage('PURCHASED_TICKET_DETAILS', purchasedTicketDetails);
    dispatch(updatePurchasedTicketDetails(purchasedTicketDetails));
};

export function updatePurchasedSum(dispatch: CallableFunction, getState: customTypes['getState']) {
    let purchasedTicketDetails = clonedeep(getState().ticketDetails.purchasedTicketDetails);
    if (purchasedTicketDetails && purchasedTicketDetails.purchasedTicketList) {
        purchasedTicketDetails.subTotalDetails = getSubTotal(purchasedTicketDetails.purchasedTicketList);
        setToSessionStorage('PURCHASED_TICKET_DETAILS', purchasedTicketDetails);
        dispatch(updatePurchasedTicketDetails(purchasedTicketDetails));
    }
}

function checkReleaseCartRequired(dispatch: CallableFunction, getState: customTypes['getState'], purchasedTicketDetails: purchasedTicketObject) {
    if (purchasedTicketDetails.purchasedTicketList.length === 0) {
        dispatch(releaseCartAction(false));
    }
}
