import customTypes from '../../interfaces/customTypes.Interface';

export const TOGGLE_SHARE_WINDOW = 'TOGGLE_SHARE_WINDOW';
export const SET_MEDIA = 'SET_MEDIA';

export function toggleShareWindowAction(flag: boolean) {
    return {
        type: TOGGLE_SHARE_WINDOW,
        flag
    };
}

export function setMediaAction(media: string) {
    return {
        type: SET_MEDIA,
        media
    };
}

export function shareMediaAction(media: string) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        dispatch(setMediaAction(media));
        dispatch(toggleShareWindowAction(true));
    };
}
