import { SET_WALLET_TICKET_DETAILS } from '../actions/walletTicket.action';
import store from '../../interfaces/store.Interface';

function walletTicketHandler(state: store['walletTicket'], action: any): store['walletTicket'] {
    if (action.type === SET_WALLET_TICKET_DETAILS) {
        return { ...state, ...action.walletTicket };
    } else {
        return { ...state };
    }
}

export default walletTicketHandler;
