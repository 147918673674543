import { getShowsByEventId } from '../../utils/ApiUrlMapping';
import { setShowList } from '../actions/showList';
import { schedulesObject, showDetailsObject } from '../../interfaces/showList.Interface';
import { getUTCDateObject, getFromSessionStorage, formatDateTimeWithoutDay } from '../../utils/utility';
import moment from 'moment';
import history from '../../utils/history';
import { startLoader, stopLoader, setBackgroundMessage, setIsResetTicketListRequired } from '../actions/app.action';
import { setEventDetails } from '../actions/ticketList';
import { eventDetailForHeader } from '../../interfaces/TicketDetails.Interface';

export function getShowsByIdsActionMiddleware(dispatch: any, showIdRequest: any) {
    dispatch(startLoader());
    return getShowsByEventId(showIdRequest, Number(getFromSessionStorage('CUSTOMER_ID')))
        .then((response: any) => {
            dispatch(stopLoader());
            let groupedShows = arrangeShowsPerDate(response.data.responseObject);
            let eventDetails = processEventDetails(groupedShows);
            dispatch(setShowList(groupedShows));
            dispatch(setEventDetails(eventDetails));
            let isShowsAvailable = groupedShows && groupedShows.schedules && groupedShows.schedules.length > 0 ? true : false;
            if (!isShowsAvailable) {
                dispatch(setBackgroundMessage('This event is not currently active, please check again later.'));
            }
        })
        .catch((error) => {
            dispatch(stopLoader());
        });
}

function arrangeShowsPerDate(details: Array<showDetailsObject>): showDetailsObject {
    let showsObject: showDetailsObject = {
        eventId: 0,
        eventName: '',
        imageUrls: [],
        startDateTime: 0,
        endDateTime: 0,
        eventTimeZone: '',
        schedules: [],
        venueNames: []
    };
    let isPresent = false;
    let showsList: Array<schedulesObject> = [];
    details.forEach((showDetails) => {
        showsObject.eventId = showDetails.eventId;
        showsObject.eventName = showDetails.eventName;
        showsObject.eventTimeZone = showDetails.eventTimeZone;
        showsObject.startDateTime = showDetails.startDateTime;
        showsObject.endDateTime = showDetails.endDateTime;
        showsObject.imageUrls = showDetails.imageUrls;
        showsObject.venueNames = showDetails.venueNames;

        if (showDetails.schedules && showDetails.schedules.length > 0) {
            showDetails.schedules[0].shows.forEach((showElement) => {
                isPresent = false;
                showElement.fromTime = getUTCDateObject(showElement.fromTime);
                showElement.toTime = getUTCDateObject(showElement.toTime);
                showsList.forEach((show) => {
                    if (isSameDay(showElement.fromTime, show.showDate) && show.shows) {
                        isPresent = true;
                        show.shows.push(showElement);
                    }
                });

                if (!isPresent) {
                    let showObject: schedulesObject = {
                        showDate: 0,
                        shows: []
                    };
                    showObject.showDate = showElement.fromTime;
                    showObject.shows = [];
                    showObject.shows.push(showElement);
                    showsList.push(showObject);
                }
            });
        }
    });
    showsObject.schedules = showsList;
    return showsObject;
}

function isSameDay(firstDate: number, secondDate: number): boolean {
    return moment(firstDate).isSame(secondDate, 'day');
}

export function redirectToTicketListMiddleware(dispatch: any, showId: number, eventId: number, categoryId: any) {
    dispatch(setIsResetTicketListRequired(false));
    if (categoryId) {
        history.push(`/${btoa(getFromSessionStorage('CUSTOMER_ID'))}/event/${eventId}/${showId}/${categoryId}`);
    } else {
        history.push(`/${btoa(getFromSessionStorage('CUSTOMER_ID'))}/event/${eventId}/${showId}`);
    }
}

function processEventDetails(showResponse: showDetailsObject): eventDetailForHeader {
    const startDateTime = getUTCDateObject(showResponse.startDateTime);
    const endDateTime = getUTCDateObject(showResponse.endDateTime);
    let eventDetails: eventDetailForHeader = {
        id: showResponse.eventId,
        name: showResponse.eventName,
        description: '',
        startDateTime: formatDateTimeWithoutDay(startDateTime),
        endDateTime: formatDateTimeWithoutDay(endDateTime),
        eventTimeZone: '',
        showId: 0
    };

    return eventDetails;
}
