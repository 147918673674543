//support IE9, IE10, IE11 and above
import 'react-app-polyfill/ie9';

//below will support only IE11 or above
// import 'react-app-polyfill/ie11';

import 'core-js/features/string/includes';
import 'core-js/features/array/includes';
import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/string/virtual/ends-With';
import 'core-js/features/object/values';
import 'core-js/features/object/keys';
import 'core-js/features/object/entries';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AppStore from './utils/store';
import './resources/scss/index.scss';
import * as serviceWorker from './serviceWorker';
import Routing from './utils/routing';
import ReactGA from 'react-ga';
import { getGATrackingId } from './utils/constantsMapping';

const store = AppStore;

declare global {
    interface Window {
        Square: any;
        PaymentiFrame: any;
    }
}

// Initialize GA tracking
ReactGA.initialize(getGATrackingId());

// a
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA test
serviceWorker.unregister();

ReactDOM.render(
    <Provider store={store}>
        <Routing />
    </Provider>,
    document.getElementById('root')
);
