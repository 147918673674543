import {
    SET_FEE_TAX_DETAILS,
    SET_PROMO_CODE,
    SET_VIEW_STATUS,
    RESET_VIEW_STATUS,
    SET_DELIVERY_METHODS,
    SET_PREFEREED_PAYMENT,
    SET_CUSTOMER_SALE_PERMISSION,
    SET_SELECTED_DELIVERY_OPTION,
    RESET_FEE_TAX_DETAILS,
    RESET_DELIVERY_METHODS,
    RESET_PROMO,
    SET_PROMO_ERROR
} from '../actions/checkout.action';
import { viewStatusIS, feeTaxDetailsIS, ticketDeliveryOptionsIS, promoCodeDetailsIS } from '../initialState';
import store from '../../interfaces/store.Interface';

function checkoutHandler(state: store['checkout'], action: any): store['checkout'] {
    switch (action.type) {
        case SET_FEE_TAX_DETAILS:
            return { ...state, feeTaxDetails: { ...action.feeTaxDetails } };
        case SET_PROMO_CODE:
            return { ...state, promoCodeDetails: { ...action.promoCodeDetails } };
        case SET_VIEW_STATUS:
            return { ...state, viewStatus: { ...state.viewStatus, ...action.data } };
        case RESET_VIEW_STATUS:
            return { ...state, viewStatus: { ...viewStatusIS } };
        case SET_DELIVERY_METHODS:
            return { ...state, ticketDeliveryOptions: { ...action.ticketDeliveryOptions } };
        case SET_PREFEREED_PAYMENT:
            return { ...state, preferredPayment: action.preferredpayement };
        case SET_CUSTOMER_SALE_PERMISSION:
            return { ...state, customerSalePermission: { ...action.customerSalePermission } };
        case SET_SELECTED_DELIVERY_OPTION:
            return { ...state, ticketDeliveryOptions: { ...action.data } };
        default:
            return checkoutHandlerMore(state, action);
    }
}

function checkoutHandlerMore(state: store['checkout'], action: any): store['checkout'] {
    switch (action.type) {
        case RESET_FEE_TAX_DETAILS:
            return { ...state, feeTaxDetails: { ...feeTaxDetailsIS } };
        case RESET_DELIVERY_METHODS:
            return { ...state, ticketDeliveryOptions: { ...ticketDeliveryOptionsIS } };
        case RESET_PROMO:
            return { ...state, promoCodeDetails: { ...promoCodeDetailsIS } };
        case SET_PROMO_ERROR:
            return { ...state, promoCodeDetails: { ...state.promoCodeDetails, errorMessage: action.errorMessage } };
        default:
            return { ...state };
    }
}

export default checkoutHandler;
