import React from 'react';
import './paymentProgress.scss';

export default () => {
    return (
        <>
            <div className="payment-loader-text">Please Wait.. Payment in Progress !!</div>
        </>
    );
};
