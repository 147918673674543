import {
    SET_SQUARE_PAYMENT_NONCE,
    SET_SQUARE_CARD_STATUS,
    SET_PAYMENT_FORM_LOADED,
    SET_SQUARE_APPLICATION_ID,
    INC_PAYMENT_RETRY_COUNT,
    RESET_PAYMENT,
    SET_HOSTED_TRANSACTION_ID,
    SET_PROPAY_PAYMENT_STATUS,
    SET_BLUEFIN_ACCOUNT_ID,
    SET_BLUEFIN_CARD_STATUS,
    SET_BLUEFIN_PAYMENT_TOKEN,
    SET_BLUEFIN_DEVICE_TRANSACTION_ID
} from '../actions/payments.action';
import store from '../../interfaces/store.Interface';
import { paymentsIS } from '../initialState';

function paymentsReducer(state: store['payments'], action: any): store['payments'] {
    switch (action.type) {
        case SET_SQUARE_PAYMENT_NONCE:
            return { ...state, square: { ...state.square, nonce: action.nonce } };
        case SET_SQUARE_CARD_STATUS:
            return { ...state, square: { ...state.square, cardStatus: action.status } };
        case SET_PAYMENT_FORM_LOADED:
            return { ...state, paymentFormLoaded: action.status };
        case SET_SQUARE_APPLICATION_ID:
            return { ...state, square: { ...state.square, applicationId: action.squareDetails.applicationId, locationId: action.squareDetails.locationId } };
        case INC_PAYMENT_RETRY_COUNT:
            return { ...state, retryCount: state.retryCount + 1 };
        case RESET_PAYMENT:
            return { ...paymentsIS };
        case SET_HOSTED_TRANSACTION_ID:
            return { ...state, propay: { ...state.propay, hostedTransactionId: action.transactionId } };
        case SET_PROPAY_PAYMENT_STATUS:
            return { ...state, propay: { ...state.propay, isPropayPaymentDone: action.status } };
        default:
            return paymentsReducerMore(state, action);
    }
}

function paymentsReducerMore(state: store['payments'], action: any): store['payments'] {
    switch (action.type) {
        case SET_BLUEFIN_ACCOUNT_ID:
            return { ...state, bluefin: { ...state.bluefin, accountId: action.accountId } };
        case SET_BLUEFIN_PAYMENT_TOKEN:
            return { ...state, bluefin: { ...state.bluefin, paymentToken: action.paymentToken } };
        case SET_BLUEFIN_CARD_STATUS:
            return { ...state, bluefin: { ...state.bluefin, cardStatus: action.status } };
        case SET_BLUEFIN_DEVICE_TRANSACTION_ID:
            return { ...state, bluefin: { ...state.bluefin, deviceTransId: action.deviceTransId } };
        default:
            return { ...state };
    }
}

export default paymentsReducer;
