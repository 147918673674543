import { startLoader, stopLoader, setToastMessage } from '../actions/app.action';
import { setSquareApplicationIdAction, setBluefinAccountIdAction } from '../actions/payments.action';
import { onTaskError } from './common.Middleware';
import { getSquareMerchantDetails, createHostedTransaction, getPropayResult, getBluefinMerchantDetail } from '../../utils/ApiUrlMapping';
import {
    TASK_GET_SQUARE_MERCHANT_DETAILS,
    TASK_GET_HOSTED_TRANSACTION_ID,
    TASK_GET_PROPAY_RESULT,
    ERROR_GET_PROPAY_RESULT,
    TASK_GET_BLUEFIN_MERCHANT_DETAILS
} from '../../utils/commonConstants';
import customTypes from '../../interfaces/customTypes.Interface';
import { setHostedTransactionId } from '../actions/payments.action';

export const getSquareMerchantDetailsMiddleware = (customerId: number, dispatch: CallableFunction, getState: customTypes['getState'], request?: any): Promise<void> => {
    dispatch(startLoader());
    return getSquareMerchantDetails(customerId)
        .then((response) => {
            onTaskSuccess(dispatch, response, TASK_GET_SQUARE_MERCHANT_DETAILS, getState);
        })
        .catch((error) => {
            onTaskError(dispatch, error, TASK_GET_SQUARE_MERCHANT_DETAILS, '', true);
        });
};

export const getHostedTransactionIdMiddleware = (customerId: number, paymentRequest: Object, dispatch: CallableFunction, getState: customTypes['getState']) => {
    dispatch(startLoader());
    return createHostedTransaction(customerId, paymentRequest)
        .then((response) => {
            onTaskSuccess(dispatch, response, TASK_GET_HOSTED_TRANSACTION_ID, getState);
        })
        .catch((error) => {
            onTaskError(dispatch, error, TASK_GET_HOSTED_TRANSACTION_ID, '', false);
        });
};

export const getPropayResultMiddleware = (propayRequest: Object, dispatch: CallableFunction, getState: customTypes['getState']) => {
    dispatch(startLoader());
    return getPropayResult(propayRequest)
        .then((response) => {
            onTaskSuccess(dispatch, response, TASK_GET_PROPAY_RESULT, getState);
        })
        .catch((error) => {
            let msg = '';
            if (error && error.data && error.data.responseObject && error.data.responseObject.TransactionResultMessage) {
                msg = error.data.responseObject.TransactionResultMessage;
            } else if (error && error.data && error.data.responseMessage) {
                msg = error.data.responseMessage;
            } else {
                msg = ERROR_GET_PROPAY_RESULT;
            }
            onTaskError(dispatch, error, TASK_GET_PROPAY_RESULT, msg, false);
        });
};

export const getBluefinMerchantDetailsMiddleware = (customerId: number, dispatch: CallableFunction, getState: customTypes['getState']): Promise<void> => {
    dispatch(startLoader());
    return getBluefinMerchantDetail(customerId)
        .then((response) => {
            onTaskSuccess(dispatch, response, TASK_GET_BLUEFIN_MERCHANT_DETAILS, getState);
        })
        .catch((error) => {
            onTaskError(dispatch, error, TASK_GET_BLUEFIN_MERCHANT_DETAILS, '', true);
        });
};

const handleErrorResponse = (dispatch: CallableFunction, response: any, task: number) => {
    if (task === TASK_GET_SQUARE_MERCHANT_DETAILS || task === TASK_GET_BLUEFIN_MERCHANT_DETAILS) {
        onTaskError(dispatch, response, task, '', true);
    } else if (task === TASK_GET_HOSTED_TRANSACTION_ID) {
        dispatch(setHostedTransactionId(''));
        onTaskError(dispatch, response, task, '', false);
    } else if (task === TASK_GET_PROPAY_RESULT) {
        let msg = '';
        if (response.data.responseObject && response.data.responseObject.TransactionResultMessage) {
            msg = response.data.responseObject.TransactionResultMessage;
        } else {
            msg = response.data.responseMessage || ERROR_GET_PROPAY_RESULT;
        }
        onTaskError(dispatch, response, task, msg, false);
    } else {
        onTaskError(dispatch, response, task);
    }
};

const onTaskSuccess = (dispatch: CallableFunction, response: any, task: number, getState: customTypes['getState'], request?: any): void => {
    if (response.data.responseCode === 0) {
        dispatch(stopLoader());
        switch (task) {
            case TASK_GET_SQUARE_MERCHANT_DETAILS:
                if (response.data.responseObject.applicationId && response.data.responseObject.locationId) {
                    dispatch(setSquareApplicationIdAction(atob(response.data.responseObject.applicationId),
                        atob(response.data.responseObject.locationId)));
                } else {
                    setToastMessage('Payment Gateway Error');
                }
                break;
            case TASK_GET_HOSTED_TRANSACTION_ID:
                if (response.data.responseObject.Result.ResultCode === '00') {
                    dispatch(setHostedTransactionId(response.data.responseObject.HostedTransactionIdentifier));
                } else {
                    dispatch(setHostedTransactionId(''));
                    onTaskError(dispatch, response, task, '', false);
                }
                break;
            case TASK_GET_PROPAY_RESULT:
                setToastMessage(response.data.responseObject.TransactionResultMessage);
                break;
            case TASK_GET_BLUEFIN_MERCHANT_DETAILS:
                let accountId = '';
                if (response.data.responseObject) {
                    accountId = atob(response.data.responseObject);
                }
                dispatch(setBluefinAccountIdAction(accountId));
                break;
            default:
                break;
        }
    } else {
        handleErrorResponse(dispatch, response, task);
    }
};
