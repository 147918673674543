import { useRouteMatch } from 'react-router-dom';
import {
    PATH_LANDING,
    PATH_EVENT_LIST,
    PATH_TICKET_LIST,
    PATH_SHOW_LIST,
    PATH_TICKET_SHOW_LIST,
    PATH_SUPPORT,
    PATH_ORDER_HISTORY,
    PATH_CART,
    PATH_BILLING,
    PATH_CHECKOUT_SUMMARY,
    PATH_CHECKOUT,
    PATH_PAYMENT_PROGRESS,
    PATH_TICKET_CATEGORY_LIST,
    PATH_SHOW_LIST_FROM_CATEGORY,
    PATH_TICKET_CATEGORY_SHOW_LIST
} from '../utils/routes';

const usePageName = (): { page: string; params: Object } => {
    let page = '';
    let params = {};
    const match = useRouteMatch({
        path: [
            PATH_LANDING,
            PATH_EVENT_LIST,
            PATH_TICKET_LIST,
            PATH_SHOW_LIST,
            PATH_TICKET_SHOW_LIST,
            PATH_SUPPORT,
            PATH_ORDER_HISTORY,
            PATH_CART,
            PATH_BILLING,
            PATH_CHECKOUT_SUMMARY,
            PATH_CHECKOUT,
            PATH_PAYMENT_PROGRESS,
            PATH_TICKET_CATEGORY_LIST,
            PATH_SHOW_LIST_FROM_CATEGORY,
            PATH_TICKET_CATEGORY_SHOW_LIST
        ],
        exact: true,
        strict: true,
        sensitive: true
    });
    if (match && match.hasOwnProperty('path')) {
        page = getPageName(match.path);
        params = match.params;
    }
    return { page, params };
};

const getPageName = (path: string) => {
    let page = '';
    switch (path) {
        case PATH_LANDING:
            page = 'landing';
            break;
        case PATH_EVENT_LIST:
            page = 'eventList';
            break;
        case PATH_TICKET_LIST:
            page = 'ticketList';
            break;
        case PATH_SHOW_LIST:
            page = 'showsList';
            break;
        case PATH_TICKET_SHOW_LIST:
            page = 'ticketListShow';
            break;
        case PATH_SUPPORT:
            page = 'support';
            break;
        case PATH_ORDER_HISTORY:
            page = 'orderHistory';
            break;
        default:
            page = getPageNameMore(path);
    }

    return page;
};

const getPageNameMore = (path: string) => {
    let page = '';
    switch (path) {
        case PATH_CART:
            page = 'cart';
            break;
        case PATH_BILLING:
            page = 'billing';
            break;
        case PATH_CHECKOUT_SUMMARY:
            page = 'checkoutSummary';
            break;
        case PATH_CHECKOUT:
            page = 'checkout';
            break;
        case PATH_PAYMENT_PROGRESS:
            page = 'paymentFrame';
            break;
        case PATH_TICKET_CATEGORY_LIST:
            page = 'ticketList';
            break;
        case PATH_SHOW_LIST_FROM_CATEGORY:
            page = 'showsList';
            break;
        case PATH_TICKET_CATEGORY_SHOW_LIST:
            page = 'ticketListShow';
            break;
        default:
            break;
    }

    return page;
};

export default usePageName;
