import { startLoader, stopLoader } from '../actions/app.action';
import { getOrderHistory, getOrderHistoryById } from '../../utils/ApiUrlMapping';
import { setOrderHistory, resetOrderHistory } from '../actions/orderHistory';
import { orderHistoryInterface, orderHistoryRequestObject } from '../../interfaces/orderHistory.Interface';
import { TASK_GET_ORDER_HISTORY_ID, TASK_GET_ORDER_HISTORY_DATE } from '../../utils/commonConstants';
import { onTaskError } from './common.Middleware';
import moment from 'moment';
import customTypes from '../../interfaces/customTypes.Interface';
import clonedeep from 'lodash.clonedeep';

export function getOrderHistoryMiddleWare(dispatch: any, getState: customTypes['getState'], OrderHistoryObject: orderHistoryRequestObject) {
    let localOrderHistoryData = clonedeep(getState().orderHistory);
    dispatch(startLoader());
    return getOrderHistory(OrderHistoryObject)
        .then((response: any) => {
            onTaskSuccess(dispatch, response, TASK_GET_ORDER_HISTORY_DATE, getState, localOrderHistoryData);
        })
        .catch((error) => {
            dispatch(resetOrderHistory());
            onTaskError(dispatch, error, TASK_GET_ORDER_HISTORY_DATE);
        });
}

export function getOrderHistoryByIdMiddleware(dispatch: any, getState: customTypes['getState'], OrderHistoryObject: orderHistoryRequestObject) {
    let localOrderHistoryData = clonedeep(getState().orderHistory);
    dispatch(startLoader());
    return getOrderHistoryById(OrderHistoryObject)
        .then((response: any) => {
            onTaskSuccess(dispatch, response, TASK_GET_ORDER_HISTORY_ID, getState, localOrderHistoryData);
        })
        .catch((error) => {
            dispatch(resetOrderHistory());
            onTaskError(dispatch, error, TASK_GET_ORDER_HISTORY_ID);
        });
}

const processOrderHistory = (orderHistoryData: orderHistoryInterface, dispatch: CallableFunction) => {
    if (orderHistoryData.buyerOrderHistoryVOs && orderHistoryData.buyerOrderHistoryVOs.length > 0) {
        orderHistoryData.buyerOrderHistoryVOs.forEach((element: any) => {
            if (element.orderDateTime) {
                element.orderDateTimeFormatted = moment(element.orderDateTime).format('MM/DD/YYYY');
            } else {
                element.orderDateTimeFormatted = '';
            }
        });
    }
    dispatch(setOrderHistory(orderHistoryData));
};

const onTaskSuccess = (
    dispatch: CallableFunction,
    response: any,
    task: number,
    getState: customTypes['getState'],
    orderHistoryData: orderHistoryInterface,
    request?: any
): void => {
    if (response.data.responseCode === 0) {
        dispatch(stopLoader());
        switch (task) {
            case TASK_GET_ORDER_HISTORY_ID:
                orderHistoryData.totalOrders = response.data.responseObject.totalOrders;
                orderHistoryData.buyerOrderHistoryVOs = response.data.responseObject.buyerOrderHistoryVOs;
                processOrderHistory(orderHistoryData, dispatch);
                break;
            case TASK_GET_ORDER_HISTORY_DATE:
                orderHistoryData.totalOrders = response.data.responseObject.totalOrders;
                orderHistoryData.buyerOrderHistoryVOs = orderHistoryData.buyerOrderHistoryVOs.concat(response.data.responseObject.buyerOrderHistoryVOs);
                processOrderHistory(orderHistoryData, dispatch);
                break;
            default:
                break;
        }
    } else if (response.data.responseCode === 11) {
        dispatch(stopLoader());
        dispatch(resetOrderHistory());
    } else {
        dispatch(resetOrderHistory());
        onTaskError(dispatch, response, task);
    }
};
