import React from 'react';
import usePageName from '../../../hooks/usePageName';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import './ContainerSkeletonProvider.scss';
import LayoutContainer from '../layoutContainer/LayoutContainer.Component';
import GenericPageSkeleton from './GenericPageSkeleton.Component';

const ContainerSkeletonProvider = () => {
    const { page } = usePageName();
    return (
        <>
            {page === 'paymentFrame' ? (
                <> </>
            ) : (
                <Grid container spacing={0} alignItems="center" className="page-skeleton-container">
                    <Grid item className="skeleton-header" xs={12}>
                        <Skeleton animation="wave" variant="rect" className="full-height" />
                    </Grid>
                    <Grid item className="skeleton-page-body" xs={12}>
                        <LayoutContainer>
                            <GenericPageSkeleton />
                        </LayoutContainer>
                    </Grid>
                    <Grid item className="skeleton-footer" xs={12}>
                        <Skeleton animation="wave" variant="rect" className="full-height" />
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default ContainerSkeletonProvider;
