import customTypes from '../../interfaces/customTypes.Interface';
import { getReservationDetailsMiddleware, releaseCartMiddleware } from '../middlewares/cartReservation.Middleware';

export function getReservationDetailsAction(customerId: string) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return getReservationDetailsMiddleware(dispatch, customerId);
    };
}

export function releaseCartAction(timerExpiry: boolean, resetTransactionSettings = false) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return releaseCartMiddleware(dispatch, getState, timerExpiry, resetTransactionSettings);
    };
}
