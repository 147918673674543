import ticketDetails, { ticketDetailsObject, purchasedTicketObject, eventDetailForHeader } from '../../interfaces/TicketDetails.Interface';
import {
    getVenueDetailsMiddleware,
    getTicketListMiddleware,
    addTicketToCartMiddleware,
    removeTicketFromCartMiddleware,
    editTicketCountMiddleware,
    saveTicketDetailsMiddleware,
    deleteTicketFromCartMiddleware,
    isValidMinOrderMiddleware,
    updateTicketCapacityMiddleware
} from '../middlewares/ticketList.Middleware';
import customTypes from '../../interfaces/customTypes.Interface';

/**
 * action types
 */
export const SET_TICKET_LIST = 'SET_TICKET_LIST';

export const SET_VENUE_DETAILS = 'SET_VENUE_DETAILS';

export const UPDATE_TICKET_COUNT = 'UPDATE_TICKET_COUNT';

export const SET_EVENT_DETAILS = 'SET_EVENT_DETAILS';

export const SET_EXPAND_DESCRIPTION = 'SET_EXPAND_DESCRIPTION';

export const RESET_TICKET_LIST = 'RESET_TICKET_LIST';

export const RESET_PURCHASED_TICKET_LIST = 'RESET_PURCHASED_TICKET_LIST';

/**
 * action creators
 */
export function setTicketList(ticketList: ticketDetails['ticketList']) {
    return {
        type: SET_TICKET_LIST,
        ticketList
    };
}

export function setVenueDetails(venueDetails: ticketDetails['venueDetails']) {
    return {
        type: SET_VENUE_DETAILS,
        venueDetails
    };
}

export function updatePurchasedTicketDetails(purchasedTicketDetails: purchasedTicketObject) {
    return {
        type: UPDATE_TICKET_COUNT,
        purchasedTicketDetails
    };
}

export function setEventDetails(eventDetails: eventDetailForHeader) {
    return {
        type: SET_EVENT_DETAILS,
        eventDetails
    };
}

export function readMoreOrLessDescription() {
    return {
        type: SET_EXPAND_DESCRIPTION
    };
}

export function resetTicketListAction() {
    return {
        type: RESET_TICKET_LIST
    };
}

export function resetPurchasedTicketListAction() {
    return {
        type: RESET_PURCHASED_TICKET_LIST
    };
}

export function getTicketListAction(ticketListRequest: any) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return getTicketListMiddleware(dispatch, getState, ticketListRequest);
    };
}

export function getVenueDetailsAction(venueDetailsRequest: any) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return getVenueDetailsMiddleware(dispatch, getState, venueDetailsRequest);
    };
}

export function addTicketToCartAction(ticketObject: ticketDetailsObject, newValue: string, currentShowId: string) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return addTicketToCartMiddleware(dispatch, getState, ticketObject, newValue, currentShowId);
    };
}

export function removeTicketFromCartAction(ticketObject: ticketDetailsObject, currentShowId: string) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return removeTicketFromCartMiddleware(dispatch, getState, ticketObject, currentShowId);
    };
}

export function editTicketCountAction(ticketObject: ticketDetailsObject, newValue: string, currentShowId: string) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return editTicketCountMiddleware(dispatch, getState, ticketObject, newValue, currentShowId);
    };
}

export function saveTicketCountAction(ticketObject: purchasedTicketObject) {
    return (dispatch: CallableFunction) => {
        return saveTicketDetailsMiddleware(dispatch, ticketObject);
    };
}

export function deleteTicketFromCartAction(ticketObject: ticketDetailsObject) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return deleteTicketFromCartMiddleware(dispatch, getState, ticketObject);
    };
}

export function isValidMinOrderAction(purchasedTicketList: Array<ticketDetailsObject>) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return isValidMinOrderMiddleware(dispatch, getState, purchasedTicketList);
    };
}

export function showMoreTextAction(ticketObject: ticketDetailsObject) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        let updatedTicketList = [...getState().ticketDetails.ticketList];
        if (updatedTicketList) {
            let foundIndex = updatedTicketList.findIndex(
                (element) => element.ticketId === ticketObject.ticketId && element.isCombo === ticketObject.isCombo && element.showId === ticketObject.showId
            );
            updatedTicketList[foundIndex].isDescExpanded = !updatedTicketList[foundIndex].isDescExpanded;
            dispatch(setTicketList(updatedTicketList));
            dispatch(readMoreOrLessDescription());
        }
    };
}

export function updateTicketCapacityAction(capacityResponse: any) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return updateTicketCapacityMiddleware(dispatch, getState, capacityResponse);
    };
}
