import { getEventList, getSearchKeyEvent, getFilterEvents } from '../../utils/ApiUrlMapping';
import { setEventList, setPageNumber, setFilter, addEvents, resetEventListAction, setEventCount } from '../actions/eventList';
import eventListData from '../../interfaces/eventList.Interface';
import moment from 'moment';
import { getFromSessionStorage, encode } from '../../utils/utility';
import { startLoader, stopLoader, expandSearchFieldAction } from '../actions/app.action';
import customTypes from '../../interfaces/customTypes.Interface';
import { TASK_GET_EVENT_LIST, TASK_GET_FILTER_EVENT_LIST, TASK_SEARCH_EVENT_LIST } from '../../utils/commonConstants';
import store from '../../interfaces/store.Interface';
import { onTaskError } from './common.Middleware';

export function getEventListMiddleware(dispatch: any, customerId: any, pageNumber: any) {
    return getEventListAction(dispatch, customerId, pageNumber);
}

export function searchKeyEventMiddleware(dispatch: any, customerId: any, searchkey: any) {
    dispatch(resetEventListAction(true));
    if (searchkey) {
        const searchRequest = {
            customerId,
            eventName: searchkey
        };
        dispatch(startLoader());
        return getSearchKeyEvent(searchRequest)
            .then((response: any) => {
                onTaskSuccess(dispatch, response, TASK_SEARCH_EVENT_LIST, { pageNumber: 1 });
            })
            .catch((error) => {
                onTaskError(dispatch, error, TASK_SEARCH_EVENT_LIST);
            });
    } else {
        const customerIdEncoded: string = encode(customerId.toString());
        return getEventListAction(dispatch, customerIdEncoded, 1);
    }
}

export function getEventDate(filterDate: any, isStartTime: boolean) {
    let dateObj: Date;
    if (isStartTime) {
        dateObj = new Date(new Date(filterDate).setHours(0, 0, 0, 0));
    } else {
        dateObj = new Date(new Date(filterDate).setHours(23, 59, 59, 999));
    }
    return moment.utc(dateObj, 'YYYY-MM-DDTHH:mm:ss.SSS').format();
}

export function filterEventsMiddleware(dispatch: any, filterKey: any, clearEventData = false, pageNumber: store['eventListData']['pageNumber'] = 1) {
    let newDate = new Date();
    let startDate = '';
    let endDate = '';
    dispatch(expandSearchFieldAction(false));
    if (clearEventData) {
        dispatch(setEventList([]));
    }
    switch (filterKey) {
        case 'all':
            break;
        case 'today':
            startDate = getEventDate(newDate, true);
            endDate = getEventDate(newDate, false);
            break;
        case 'tomorrow':
            const tomorrowDate = newDate;
            tomorrowDate.setDate(tomorrowDate.getDate() + 1);
            startDate = getEventDate(tomorrowDate, true);
            endDate = getEventDate(tomorrowDate, false);
            break;
        case 'nextWeek':
            const curr = newDate; // get current date
            const nextWeekToday = curr.getDate() + 7;
            const nextWeekDay = curr.getDay();
            const first = nextWeekToday - nextWeekDay; // First day is the day of the month - the day of the week
            const last = first + 6; // last day is the first day + 6
            const firstday = new Date(curr).setDate(first);
            const lastday = new Date(curr).setDate(last);
            startDate = getEventDate(firstday, true);
            endDate = getEventDate(lastday, false);
            break;
        case 'nextMonth':
            const firstDayOfNextMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 1);
            const lastDayOfNextMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 2, 0);
            startDate = getEventDate(firstDayOfNextMonth, true);
            endDate = getEventDate(lastDayOfNextMonth, false);
            break;
        case 'nextYear':
            const firstDayOfNextYear = new Date(newDate.getFullYear() + 1, 0, 1);
            const lastDayOfNextYear = new Date(newDate.getFullYear() + 1, 11, 31);
            startDate = getEventDate(firstDayOfNextYear, true);
            endDate = getEventDate(lastDayOfNextYear, false);
            break;
        default:
            break;
    }
    dispatch(setFilter(filterKey));
    if (filterKey === 'all') {
        const customerId = Number(getFromSessionStorage('CUSTOMER_ID'));
        const customerIdEncoded: string = encode(customerId.toString());
        return getEventListAction(dispatch, customerIdEncoded, pageNumber);
    } else {
        const filterRequest = {
            customerId: Number(getFromSessionStorage('CUSTOMER_ID')),
            startDate,
            endDate,
            page: pageNumber
        };
        dispatch(startLoader());
        return getFilterEvents(filterRequest)
            .then((response: any) => {
                onTaskSuccess(dispatch, response, TASK_GET_FILTER_EVENT_LIST, { pageNumber });
            })
            .catch((error) => {
                onTaskError(dispatch, error, TASK_GET_FILTER_EVENT_LIST);
            });
    }
}

export function showMoreMiddleWare(dispatch: any, getState: customTypes['getState']) {
    let pageNumber = getState().eventListData.pageNumber + 1;
    let filterKey = getState().eventListData.filter;
    return filterEventsMiddleware(dispatch, filterKey, false, pageNumber);
}

function getEventListAction(dispatch: any, customerId: any, pageNumber: any) {
    dispatch(startLoader());
    return getEventList(customerId, pageNumber)
        .then((response: any) => {
            onTaskSuccess(dispatch, response, TASK_GET_EVENT_LIST, { pageNumber });
        })
        .catch((error) => {
            onTaskError(dispatch, error, TASK_GET_EVENT_LIST);
        });
}

function processEventListResponse(data: any): eventListData['eventList'] {
    let eventListFormatted: Array<any> = [];
    let eventListItem: any = {};
    if (data.length > 0) {
        data.forEach((e: any) => {
            eventListItem = {};
            eventListItem.id = e.id;
            eventListItem.name = e.name;
            eventListItem.description = e.description;
            eventListItem.eventState = e.eventState;
            eventListItem.imageUrls = e.imageUrls;
            eventListItem.venueTickets = e.venueTickets;
            eventListItem.eventTimeZone = e.eventTimeZone;
            eventListItem.saleStartDateTime = e.saleStartDateTime;
            eventListItem.startDateTime = e.startDateTime;
            eventListItem.endDateTime = e.endDateTime;
            eventListItem.eventFeeTemplateId = e.eventFeeTemplateId;
            eventListItem.isComingSoon = e.isComingSoon;
            eventListItem.showSchedules = e.showSchedules;
            eventListFormatted.push(eventListItem);
        });
    }

    return eventListFormatted;
}

function handleEventListResponse(dispatch: CallableFunction, response: any, request?: any) {
    const dataFormatted = processEventListResponse(response.data.responseObject.buyerEventVOList);
    if (request && request.pageNumber > 1) {
        dispatch(addEvents(dataFormatted));
        dispatch(setPageNumber(request.pageNumber));
    } else {
        dispatch(setEventList(dataFormatted));
        dispatch(setPageNumber(1));
    }
    dispatch(setEventCount(response.data.responseObject.totalCountOfEvents ? response.data.responseObject.totalCountOfEvents : 0));
}

const onTaskSuccess = (dispatch: CallableFunction, response: any, task: number, request?: any): void => {
    if (response.data.responseCode === 0) {
        dispatch(stopLoader());
        switch (task) {
            case TASK_GET_EVENT_LIST:
            case TASK_SEARCH_EVENT_LIST:
            case TASK_GET_FILTER_EVENT_LIST:
                handleEventListResponse(dispatch, response, request);
                break;
            default:
                break;
        }
    } else if (response.data.responseCode === 11 && (task === TASK_SEARCH_EVENT_LIST || task === TASK_GET_EVENT_LIST || task === TASK_GET_FILTER_EVENT_LIST)) {
        // Not showing any popups if no data found.
        dispatch(stopLoader());
    } else {
        onTaskError(dispatch, response, task);
    }
};
