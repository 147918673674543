import brandingDetails from '../../interfaces/brandingDetails.Interface';
import { getCustomBrandingDetailsMiddleware } from '../middlewares/brandingDetails.Middleware';
import customTypes from '../../interfaces/customTypes.Interface';
import { brandingIS } from '../initialState';

/*
 * action types
 */

export const SET_BRANDING_DETAILS = 'SET_BRANDING_DETAILS';
export const RESET_BRANDING_DETAILS = 'RESET_BRANDING_DETAILS';

/*
 * action creators
 */

export function setBrandingInfo(brandingDetails: brandingDetails) {
    return {
        type: SET_BRANDING_DETAILS,
        brandingDetails
    };
}

export function getCustomBrandingDetailsAction(customerId: string) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return getCustomBrandingDetailsMiddleware(dispatch, getState, customerId);
    };
}

export function resetBrandingDetails() {
    return {
        type: RESET_BRANDING_DETAILS,
        brandingIS
    };
}
