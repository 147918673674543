import { orderHistoryInterface, orderHistoryRequestObject } from '../../interfaces/orderHistory.Interface';
import { getOrderHistoryMiddleWare, getOrderHistoryByIdMiddleware } from '../middlewares/orderHistory.Middleware';
import customTypes from '../../interfaces/customTypes.Interface';

/**
 * action types
 */
export const SET_ORDER_HISTORY = 'SET_ORDER_HISTORY';
export const RESET_ORDER_HISTORY = 'RESET_ORDER_HISTORY';

/**
 * action creators
 */
export function setOrderHistory(orderHistory: orderHistoryInterface) {
    return {
        type: SET_ORDER_HISTORY,
        orderHistory
    };
}

export function resetOrderHistory() {
    return {
        type: RESET_ORDER_HISTORY
    };
}

export function getOrderHistoryAction(orderNumber: string, dateFrom: Date, dateTo: Date, pageNumber: number) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        let fromDate = dateFrom ? new Date(dateFrom.setHours(0, 0, 0, 0)) : dateTo ? new Date(new Date('1970-01-01').setHours(0, 0, 0, 0)) : null;
        let toDate = dateTo ? new Date(dateTo.setHours(23, 59, 59, 999)) : dateFrom ? new Date(new Date().setHours(23, 59, 59, 999)) : null;
        const OrderHistoryObject: orderHistoryRequestObject = {
            orderNumber,
            dateFrom: fromDate,
            dateTo: toDate,
            page: pageNumber
        };
        return getOrderHistoryMiddleWare(dispatch, getState, OrderHistoryObject);
    };
}

export function getOrderHistoryByIdAction(orderNumber: string, dateFrom: Date, dateTo: Date, pageNumber: number) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        const OrderHistoryObject: orderHistoryRequestObject = {
            orderNumber,
            dateFrom,
            dateTo,
            page: pageNumber
        };
        return getOrderHistoryByIdMiddleware(dispatch, getState, OrderHistoryObject);
    };
}
