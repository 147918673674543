import { SET_EVENT_LIST, SEARCH_EVENT, SET_FILTER, SET_PAGE_NUMBER, SET_VIEWTYPE, ADD_EVENTS, RESET_EVENT_LIST, SET_EVENT_COUNT } from '../actions/eventList';
import store from '../../interfaces/store.Interface';
import { eventListIS } from '../initialState';

function eventListHandler(state: store['eventListData'], action: any): store['eventListData'] {
    switch (action.type) {
        case SET_EVENT_LIST:
            return { ...state, eventList: [...action.eventList] };
        case ADD_EVENTS:
            return { ...state, eventList: [...state.eventList, ...action.eventList] };
        case SEARCH_EVENT:
            return { ...state, eventList: [...action.eventList] };
        case SET_FILTER:
            return { ...state, filter: action.filter };
        case SET_PAGE_NUMBER:
            return { ...state, pageNumber: action.pageNumber };
        case SET_VIEWTYPE:
            return { ...state, viewType: action.viewType };
        case RESET_EVENT_LIST:
            if (action.excludeView) {
                return { ...eventListIS, viewType: state.viewType };
            } else {
                return { ...eventListIS };
            }
        case SET_EVENT_COUNT:
            return { ...state, eventCount: action.eventCount };
        default:
            return { ...state };
    }
}

export default eventListHandler;
