import { SET_RESPONSE, SHOW_CONFIRMATION, RESET_SIGNUP_FORM, SET_FIELDS } from '../actions/signUp.action';
import store from '../../interfaces/store.Interface';

function saveSignUpDetails(state: store['signUp'], action: any): store['signUp'] {
    switch (action.type) {
        case SET_RESPONSE:
            return { ...state, reCaptchaResponse: action.response };
        case SHOW_CONFIRMATION:
            return { ...state, signupConfirm: action.confirm };
        case RESET_SIGNUP_FORM:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    firstName: '',
                    lastName: '',
                    username: '',
                    password: '',
                    email: '',
                    confirmPassword: ''
                }
            };
        case SET_FIELDS:
            return { ...state, fields: { ...action.signUpDetails } };
        default:
            return { ...state };
    }
}
export default saveSignUpDetails;
