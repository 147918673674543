import { TOGGLE_SHARE_WINDOW, SET_MEDIA } from '../actions/checkoutSummary.action';
import store from '../../interfaces/store.Interface';

function checkoutSummaryHandler(state: store['checkoutSummary'], action: any): store['checkoutSummary'] {
    switch (action.type) {
        case TOGGLE_SHARE_WINDOW:
            return { ...state, showShareWindowDialog: action.flag };
        case SET_MEDIA:
            return { ...state, media: action.media };
        default:
            return { ...state };
    }
}

export default checkoutSummaryHandler;
