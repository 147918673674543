import {
    startLoader,
    stopLoader,
    setValidationMessage,
    toggleSigninCartViewAction,
    setGoogleAnalyticsParam,
    setTimerVisibilityAction,
    setToastMessage,
    setTickeListRedirectionDetailsAction
} from '../actions/app.action';
import {
    setFeeTaxDetails,
    setPromoCodeDetails,
    setDeliveryOptions,
    setPreferredPayment,
    setCustomerSalePermission,
    setSelectedDeliveryOption,
    reserveTicketsAction,
    resetPromo
} from '../actions/checkout.action';
import {
    getFeeTaxDetails,
    placeOrder,
    placeOrderGuest,
    getDeliveryOption,
    getCustomerSalePermission,
    getPreferedPaymentType,
    reserveCart,
    placeOrderCartReservation,
    placeOrderGuestCartReservation,
    applyPromo,
    releasePromo
} from '../../utils/ApiUrlMapping';
import {
    TASK_GET_FEE_TAX,
    TASK_PLACE_ORDER,
    TASK_PLACE_ORDER_GUEST,
    ERROR_PLACE_ORDER_TIMEOUT,
    ERROR_PLACE_ORDER,
    ERROR_PAYMENT_SALE,
    TASK_GET_DELIVERY_OPTION,
    TASK_GET_SALE_PERMISSION,
    TASK_GET_PREFERRED_PAYMENT,
    TASK_PLACE_ORDER_CART_RESERVATION,
    TASK_PLACE_ORDER_GUEST_CART_RESERVATION,
    TASK_RESERVE_CART,
    TASK_APPLY_PROMO,
    TASK_RELEASE_PROMO
} from '../../utils/commonConstants';
import { onTaskError } from './common.Middleware';
import {
    getFromSessionStorage,
    setToSessionStorage,
    getRedirectionUrl,
    isCartReservationEnabled,
    getCartReservationId,
    setCartReservationId,
    getPromoReservationId,
    getPromoReservationDetails,
    setPromoReservationDetails,
    getFormattedCartReservationTime,
    setReservedTickets,
    getAvailableCapacity,
    setPromoList
} from '../../utils/utility';
import history from '../../utils/history';
import { isValidMinOrderAction, resetPurchasedTicketListAction } from '../actions/ticketList';
import { PATH_CHECKOUT, PATH_CHECKOUT_SUMMARY } from '../../utils/routes';
import customTypes from '../../interfaces/customTypes.Interface';
import { setHostedTransactionId, incrementPaymentRetryCountAction, resetPaymentAction, setPropayPaymentStatus } from '../actions/payments.action';
import clonedeep from 'lodash.clonedeep';
import { getFormattedTicketList } from '../../utils/ticketListUtil';
import { releaseCartAction } from '../actions/cartReservation.action';

export function getFeeTaxMiddleware(dispatch: CallableFunction, getState: customTypes['getState']) {
    dispatch(startLoader());
    const feeTaxRequest = getFeeDetailsRequest(getState);
    return getFeeTaxDetails(feeTaxRequest)
        .then((response) => {
            onTaskSuccess(dispatch, response, TASK_GET_FEE_TAX, getState);
        })
        .catch((error) => {
            onTaskError(dispatch, error, TASK_GET_FEE_TAX);
        });
}

export function setPromoCodeMiddleware(dispatch: CallableFunction, getState: customTypes['getState'], promoCode?: any) {
    if (promoCode) {
        setPromoList(promoCode);
    }
    applyPromoMiddleware(dispatch, getState);
}

export function placeOrderMiddleware(dispatch: CallableFunction, getState: customTypes['getState']) {
    const grandTotal = getState().checkout.feeTaxDetails.grandTotal;
    const preferredPayment = getState().checkout.preferredPayment;
    // Validate the capacity of tickets in cart.
    // Propay payment is excluded from this check as user will be charged already
    // and place order API need to be triggered to void that transaction
    if (preferredPayment === 'PROPAY' || isValidCartMiddleware(dispatch, getState)) {
        if (grandTotal === 0) {
            performPlaceOrder(dispatch, getState, 2);
        } else {
            performPlaceOrder(dispatch, getState, 1);
        }
    }
}

export function getDeliveryOptionMiddleware(dispatch: CallableFunction, getState: customTypes['getState']) {
    dispatch(startLoader());
    const deliveryOptionRequest = getDeliveryOptionRequest();
    return getDeliveryOption(deliveryOptionRequest)
        .then((response) => {
            onTaskSuccess(dispatch, response, TASK_GET_DELIVERY_OPTION, getState);
        })
        .catch((error) => {
            onTaskError(dispatch, error, TASK_GET_DELIVERY_OPTION);
        });
}

export function getCustomerSalePermissionMiddleware(dispatch: CallableFunction, getState: customTypes['getState']) {
    dispatch(startLoader());
    let customerId = btoa(getFromSessionStorage('CUSTOMER_ID'));
    return getCustomerSalePermission(customerId)
        .then((response) => {
            onTaskSuccess(dispatch, response, TASK_GET_SALE_PERMISSION, getState);
        })
        .catch((error) => {
            onTaskError(dispatch, error, TASK_GET_SALE_PERMISSION);
        });
}

export function getPreferedPaymentTypeMiddleware(dispatch: CallableFunction, getState: customTypes['getState']) {
    dispatch(startLoader());
    const customerId = Number(getFromSessionStorage('CUSTOMER_ID'));
    return getPreferedPaymentType(customerId)
        .then((response) => {
            onTaskSuccess(dispatch, response, TASK_GET_PREFERRED_PAYMENT, getState);
        })
        .catch((error) => {
            onTaskError(dispatch, error, TASK_GET_PREFERRED_PAYMENT);
        });
}

export function setSelectedDeliveryOptionMiddleware(dispatch: CallableFunction, getState: customTypes['getState'], targetOption: any, eventId: any, ticketDetails: any) {
    let selectedDeliveryOptions = clonedeep(getState().checkout.ticketDeliveryOptions);
    if (selectedDeliveryOptions && selectedDeliveryOptions.ticketDeliveryDetails && selectedDeliveryOptions.ticketDeliveryOptionType) {
        Object.values(selectedDeliveryOptions.ticketDeliveryDetails).forEach((eventTicketObj: any) => {
            if (eventTicketObj && eventTicketObj.eventId && eventTicketObj.eventId === eventId && eventTicketObj.ticketDeliveryOptions) {
                eventTicketObj.ticketDeliveryOptions.forEach((ticketObj: any) => {
                    if (ticketObj.ticketId === ticketDetails.ticketId && ticketObj.isCombo === ticketDetails.isCombo) {
                        ticketObj.selectedDeliveryType = targetOption;
                    }
                });
            }
        });
    }
    dispatch(dispatch(setSelectedDeliveryOption(selectedDeliveryOptions)));
}

export function isValidCartMiddleware(dispatch: CallableFunction, getState: customTypes['getState']): boolean {
    let isValid = false;
    let msg: string = validateCart(getState);
    if (!msg) {
        isValid = true;
    } else {
        dispatch(setValidationMessage(msg, true));
    }
    return isValid;
}

export function applyPromoMiddleware(dispatch: CallableFunction, getState: customTypes['getState']) {
    dispatch(startLoader());
    const promoRequest = getApplyPromoRequest(getState);
    return applyPromo(promoRequest)
        .then((response) => {
            onTaskSuccess(dispatch, response, TASK_APPLY_PROMO, getState);
        })
        .catch((error) => {
            onTaskError(dispatch, error, TASK_APPLY_PROMO);
        });
}

export const releasePromoMiddleware = (dispatch: CallableFunction, getState: customTypes['getState']) => {
    dispatch(startLoader());
    const promoReservationId = getPromoReservationId();
    let customerId = getFromSessionStorage('CUSTOMER_ID');
    if (promoReservationId) {
        return releasePromo(customerId, promoReservationId)
            .then((response: any) => {
                onTaskSuccess(dispatch, response, TASK_RELEASE_PROMO, getState);
            })
            .catch((error: any) => {
                onTaskError(dispatch, error, TASK_RELEASE_PROMO);
            });
    }
};

export function validateCart(getState: customTypes['getState']): string {
    let msg = '';
    let ticketNames = '';
    const orderData = getState().ticketDetails.purchasedTicketDetails;
    orderData.purchasedTicketList.forEach((ticketObj: any): void => {
        let availableCapacity = getAvailableCapacity(ticketObj);
        if (!availableCapacity || (availableCapacity !== -1 && ticketObj.count > availableCapacity)) {
            ticketNames = ticketNames + '<div class="align-name breaks-word"><span>&bull; </span>' + ticketObj.ticketName + '</div>';
        }
    });
    if (ticketNames && ticketNames.length > 0) {
        msg = '<span> Sorry, the following ticket(s) are no longer available: <br>' + ticketNames + '</span>';
    }
    return msg;
}

const goToEventList = () => {
    let customerId = btoa(getFromSessionStorage('CUSTOMER_ID'));
    history.push(`/${customerId}/event-list`);
};

const processFeeDetailsResponse = (feeResponse: any): any => {
    feeResponse.feeTaxEventsList.forEach((eventFee: any): void => {
        eventFee.totalFee =
            eventFee.ticketFee +
            eventFee.taxOnTicketFee +
            eventFee.transactionFee +
            eventFee.taxOnTransactionFee +
            eventFee.salesTax +
            eventFee.accessFee +
            eventFee.accessFeeTax +
            eventFee.merchantFee +
            eventFee.willCallFee;
    });

    return feeResponse;
};

const getTicketObjectForFeeTax = (eventTicketObj: any): Object => {
    const promoDetails = getPromoReservationDetails();
    let tempTicketObj = {
        isCombo: eventTicketObj.isCombo,
        originalPrice: eventTicketObj.originalPrice,
        showId: eventTicketObj.showId,
        appliedPromoDetails: null,
        ticketId: eventTicketObj.ticketId,
        ticketName: eventTicketObj.ticketName,
        ticketPrice: eventTicketObj.price,
        ticketQuantity: eventTicketObj.count,
        venueId: eventTicketObj.venueDetails.venueId,
        venueName: eventTicketObj.venueDetails.venueName,
        venueTicketId: eventTicketObj.venueTicketId,
        showDateTime: null,
        showEndDateTime: null
    };
    if (promoDetails && promoDetails.tickets) {
        promoDetails.tickets.forEach((ticket: any) => {
            if (eventTicketObj.ticketId === ticket.ticketId && eventTicketObj.isCombo === ticket.combo) {
                ticket.shows.forEach((show: any) => {
                    if (eventTicketObj.showId === show.showId) {
                        tempTicketObj.appliedPromoDetails = show.appliedPromoDetails;
                    }
                });
            }
        });
    }
    if (eventTicketObj.showId && eventTicketObj.showId !== 0) {
        let showObj = eventTicketObj.eventDetails.showSchedules[0].shows.find((showElement: any) => {
            return showElement.showId === eventTicketObj.showId;
        });
        if (showObj) {
            tempTicketObj.showDateTime = showObj.fromTime;
            tempTicketObj.showEndDateTime = showObj.toTime;
        }
    } else {
        tempTicketObj.showDateTime = eventTicketObj.eventDetails.startDateTime;
        tempTicketObj.showEndDateTime = eventTicketObj.eventDetails.endDateTime;
    }

    return tempTicketObj;
};

const getTicketObjectForPromo = (eventTicketObj: any): Object => {
    let tempTicketObj = {
        combo: eventTicketObj.isCombo,
        ticketId: eventTicketObj.ticketId,
        ticketPrice: eventTicketObj.price,
        shows: [
            {
                showId: eventTicketObj.showId,
                ticketCount: eventTicketObj.count
            }
        ]
    };

    return tempTicketObj;
};

const getEventTicketList = (type: string) => {
    let eventTicketList: Array<any> = [];
    const orderSummaryContent = getFromSessionStorage('PURCHASED_TICKET_DETAILS');
    if (orderSummaryContent && orderSummaryContent.purchasedTicketList && orderSummaryContent.purchasedTicketList.length) {
        orderSummaryContent.purchasedTicketList.forEach((eventTicketObj: any) => {
            let tempEventObject: any = eventTicketList.find((event: any) => {
                return eventTicketObj.eventDetails.id === event.eventId;
            });
            if (type === 'feeDetails') {
                if (tempEventObject && tempEventObject.tickets && tempEventObject.tickets.length) {
                    tempEventObject.tickets.push(getTicketObjectForFeeTax(eventTicketObj));
                } else {
                    let tempEventObject = {
                        eventId: eventTicketObj.eventDetails.id,
                        eventName: eventTicketObj.eventDetails.name,
                        feeTemplateId: eventTicketObj.eventDetails.eventFeeTemplateId,
                        status: eventTicketObj.eventDetails.eventState,
                        tickets: [getTicketObjectForFeeTax(eventTicketObj)]
                    };
                    eventTicketList.push(tempEventObject);
                }
            } else if (type === 'promoDetails') {
                if (tempEventObject && tempEventObject.tickets && tempEventObject.tickets.length) {
                    let tempticketObject: any = tempEventObject.tickets.find((ticketObj: any) => {
                        return eventTicketObj.ticketId === ticketObj.ticketId && ticketObj.combo === eventTicketObj.isCombo;
                    });
                    if (tempticketObject) {
                        tempticketObject.shows.push({
                            showId: eventTicketObj.showId,
                            ticketCount: eventTicketObj.count
                        });
                    } else {
                        tempEventObject.tickets.push(getTicketObjectForPromo(eventTicketObj));
                    }
                } else {
                    let tempEventObject = {
                        eventId: eventTicketObj.eventDetails.id,
                        tickets: [getTicketObjectForPromo(eventTicketObj)]
                    };
                    eventTicketList.push(tempEventObject);
                }
            } else {
                if (tempEventObject && tempEventObject.ticketOptions && tempEventObject.ticketOptions.length) {
                    tempEventObject.ticketOptions.push(getTicketObjectDeliveryOption(eventTicketObj));
                } else {
                    let tempEventObject = {
                        eventId: eventTicketObj.eventDetails.id,
                        eventName: eventTicketObj.eventDetails.name,
                        ticketOptions: [getTicketObjectDeliveryOption(eventTicketObj)]
                    };
                    eventTicketList.push(tempEventObject);
                }
            }
        });
    } else {
        goToEventList();
    }
    return eventTicketList;
};

const getDeliveryOptionRequest = (): Object => {
    const deliveryOptionRequest: any = {};
    const customerId = Number(getFromSessionStorage('CUSTOMER_ID'));
    deliveryOptionRequest.eventTicketOptions = getEventTicketList('deliveryOptions');
    deliveryOptionRequest.customerId = customerId;

    return deliveryOptionRequest;
};

const getTicketObjectDeliveryOption = (eventTicketObj: any): Object => {
    let tempTicketObj = {
        isCombo: eventTicketObj.isCombo,
        ticketId: eventTicketObj.ticketId
    };
    return tempTicketObj;
};

const getFeeDetailsRequest = (getState: customTypes['getState']): Object => {
    const feeDetailsRequest: any = {};
    const customerId = Number(getFromSessionStorage('CUSTOMER_ID'));
    feeDetailsRequest.customerId = customerId;
    feeDetailsRequest.buyerEvents = getEventTicketList('feeDetails');
    feeDetailsRequest.willCall = true;
    if (getState().checkout.viewStatus.deliveryMethodComponent === 'completed' || 
    getState().checkout.viewStatus.deliveryMethodComponent === 'intermediate') {
        const selectedDeliveryOptions = getState().checkout.ticketDeliveryOptions;
        if (selectedDeliveryOptions && selectedDeliveryOptions.ticketDeliveryOptionType) {
            const willCallSalePermission = getState().checkout.customerSalePermission.willCall;
            feeDetailsRequest.ticketDeliveryOptions = getDeliveryOptionList(selectedDeliveryOptions, willCallSalePermission);
        }
    }
    return feeDetailsRequest;
};

const getDeliveryOptionList = (selectedDeliveryOptions: any, willCallSalePermission: any) => {
    let selectedDeliveryOptionsRequest: Array<any> = [];
    Object.values(selectedDeliveryOptions.ticketDeliveryDetails).forEach((eventTicketObj: any) => {
        if (eventTicketObj && eventTicketObj.ticketDeliveryOptions) {
            eventTicketObj.ticketDeliveryOptions.forEach((ticketObj: any) => {
                if (selectedDeliveryOptions.ticketDeliveryOptionType === 'normal') {
                    if (ticketObj.selectedDeliveryType && ticketObj.selectedDeliveryType === 'Will Call') {
                        if (willCallSalePermission && willCallSalePermission > 1) {
                            let tempObject = selectedDeliveryOptionsRequestSetter(ticketObj);
                            selectedDeliveryOptionsRequest.push(tempObject);
                        }
                    } else {
                        let tempObject = selectedDeliveryOptionsRequestSetter(ticketObj);
                        selectedDeliveryOptionsRequest.push(tempObject);
                    }
                } else {
                    if (selectedDeliveryOptions.ticketDeliveryOptionType === 'WILL_CALL') {
                        if (willCallSalePermission && willCallSalePermission > 1) {
                            let tempObject = selectedDeliveryOptionsRequestSetter(ticketObj);
                            selectedDeliveryOptionsRequest.push(tempObject);
                        }
                    } else if (selectedDeliveryOptions.ticketDeliveryOptionType === 'PRINT_AT_HOME') {
                        let tempObject = selectedDeliveryOptionsRequestSetter(ticketObj);
                        selectedDeliveryOptionsRequest.push(tempObject);
                    }
                }
            });
        }
    });

    return selectedDeliveryOptionsRequest;
};

const selectedDeliveryOptionsRequestSetter = (ticketObj: any) => {
    let tempObject: any = {};
    tempObject.ticketId = ticketObj.ticketId;
    tempObject.ticketName = ticketObj.ticketName;
    tempObject.isCombo = ticketObj.isCombo;
    if (ticketObj.selectedDeliveryType) {
        ticketObj.deliveryOptions.forEach((deliveryOptions: any) => {
            if (ticketObj.selectedDeliveryType === deliveryOptions.deliveryOptionName) {
                tempObject.deliveryOptionId = deliveryOptions.deliveryOptionId;
                tempObject.deliveryOptionTitle = deliveryOptions.deliveryOptionTitle;
                tempObject.deliveryOptionName = deliveryOptions.deliveryOptionName;
                tempObject.deliveryOptionStatus = deliveryOptions.deliveryOptionStatus;
                tempObject.isEnabled = deliveryOptions.isEnabled;
            }
        });
    } else {
        ticketObj.deliveryOptions.forEach((deliveryOptions: any) => {
            tempObject.deliveryOptionId = deliveryOptions.deliveryOptionId;
            tempObject.deliveryOptionTitle = deliveryOptions.deliveryOptionTitle;
            tempObject.deliveryOptionName = deliveryOptions.deliveryOptionName;
            tempObject.deliveryOptionStatus = deliveryOptions.deliveryOptionStatus;
            tempObject.isEnabled = deliveryOptions.isEnabled;
        });
    }

    return tempObject;
};

const getApplyPromoRequest = (getState: customTypes['getState']): Object => {
    const promoRequest: any = {};
    const customerId = Number(getFromSessionStorage('CUSTOMER_ID'));
    const reservedPromoDetails = getPromoReservationDetails();
    const appliedPromocode = reservedPromoDetails.promoList;
    promoRequest.customerId = customerId;
    promoRequest.promoReservationId = reservedPromoDetails.promoReservationId;
    promoRequest.promoCodeSet = appliedPromocode;
    promoRequest.events = getEventTicketList('promoDetails');

    return promoRequest;
};

const dispatchPromoCodeStatus = (dispatch: CallableFunction, isPromoApplied: boolean, errorMsg: string) => {
    const promoObj = {
        isPromoApplied,
        errorMessage: errorMsg
    };
    dispatch(setPromoCodeDetails(promoObj));
};

const performPlaceOrder = (dispatch: CallableFunction, getState: customTypes['getState'], paymentId: number) => {
    dispatch(startLoader());
    const isLoggedIn = getState().userDetails.isUserLoggedIn;
    let cartReservationId = getCartReservationId();
    let promoReservationId = getPromoReservationId();
    if (isCartReservationEnabled()) {
        if (cartReservationId) {
            // Cart reservation feature is enabled for this user. It has a different place order api,
            // compared to the users with cart reservation disabled.
            const placeOrderCartReservationRequest = getPlaceOrderRequest(getState, paymentId, isLoggedIn, promoReservationId, cartReservationId);
            if (isLoggedIn) {
                placeOrderTaskCartReservation(placeOrderCartReservationRequest, dispatch, getState);
            } else {
                placeOrderGuestTaskCartReservation(placeOrderCartReservationRequest, dispatch, getState);
            }
        } else {
            // This is an error scenario because if cart reservation is enabled, then ideally
            // cartReservationId should not be empty or zero during place order.
            // This needs to be handled appropriately.
        }
    } else {
        const placeOrderRequest = getPlaceOrderRequest(getState, paymentId, isLoggedIn, promoReservationId);
        if (isLoggedIn) {
            placeOrderTask(placeOrderRequest, dispatch, getState);
        } else {
            placeOrderGuestTask(placeOrderRequest, dispatch, getState);
        }
    }
};

const placeOrderTask = (placeOrderRequest: any, dispatch: CallableFunction, getState: customTypes['getState']) => {
    placeOrder(placeOrderRequest)
        .then((response) => {
            onTaskSuccess(dispatch, response, TASK_PLACE_ORDER, getState);
        })
        .catch((error) => {
            onTaskError(dispatch, error, TASK_PLACE_ORDER, '', false, true);
        });
};

const placeOrderGuestTask = (placeOrderRequest: any, dispatch: CallableFunction, getState: customTypes['getState']) => {
    placeOrderGuest(placeOrderRequest)
        .then((response) => {
            onTaskSuccess(dispatch, response, TASK_PLACE_ORDER_GUEST, getState);
        })
        .catch((error) => {
            onTaskError(dispatch, error, TASK_PLACE_ORDER_GUEST, '', false, true);
        });
};

const placeOrderTaskCartReservation = (placeOrderCartReservationRequest: any, dispatch: CallableFunction, getState: customTypes['getState']) => {
    placeOrderCartReservation(placeOrderCartReservationRequest)
        .then((response) => {
            onTaskSuccess(dispatch, response, TASK_PLACE_ORDER_CART_RESERVATION, getState);
        })
        .catch((error) => {
            onTaskError(dispatch, error, TASK_PLACE_ORDER_CART_RESERVATION, '', false, true);
        });
};

const placeOrderGuestTaskCartReservation = (placeOrderCartReservationRequest: any, dispatch: CallableFunction, getState: customTypes['getState']) => {
    placeOrderGuestCartReservation(placeOrderCartReservationRequest)
        .then((response) => {
            onTaskSuccess(dispatch, response, TASK_PLACE_ORDER_GUEST_CART_RESERVATION, getState);
        })
        .catch((error) => {
            onTaskError(dispatch, error, TASK_PLACE_ORDER_GUEST_CART_RESERVATION, '', false, true);
        });
};

const getPlaceOrderRequest = (getState: customTypes['getState'], paymentId: number, isLoggedIn: boolean, promoReservationId: any, cartReservationId = 0): any => {
    let placeOrderRequest: any;
    let selectedAddress: any = {};
    if (isLoggedIn) {
        let addressList = getState()['billing']['billingAddress'];
        let selectedAddressId = getState()['billing']['selectedAddress'];
        let tempAddressList = addressList.filter((element: any) => {
            return element.addressId === selectedAddressId.addressId;
        });
        selectedAddress = tempAddressList && tempAddressList.length > 0 ? tempAddressList[0] : {};
    } else {
        selectedAddress = getState()['billing']['guestBillingAddress'];
    }
    if (isLoggedIn) {
        placeOrderRequest = {
            addressId: selectedAddress.addressId,
            isGuest: false,
            paymentId
        };
    } else {
        placeOrderRequest = {
            addressId: 0,
            isGuest: true,
            paymentId,
            address1: selectedAddress.address1,
            address2: selectedAddress.address2,
            city: selectedAddress.city,
            guestCompanyName: selectedAddress.companyName,
            guestEmail: selectedAddress.email,
            guestFirstName: selectedAddress.firstName,
            guestLastName: selectedAddress.lastName,
            guestPaymentId: paymentId,
            guestPhoneNo: selectedAddress.telephone ? selectedAddress.telephone : null,
            state: selectedAddress.state,
            country: selectedAddress.country,
            zipCode: selectedAddress.postalCode
        };
    }

    if (cartReservationId) {
        placeOrderRequest.isCam = false;
        placeOrderRequest.cartReservationId = cartReservationId;
    }

    if (promoReservationId) {
        placeOrderRequest.promoReservationId = promoReservationId;
    }

    const feeTaxRequest = getFeeDetailsRequest(getState);
    Object.assign(placeOrderRequest, feeTaxRequest);

    if (paymentId === 1) {
        let preferredPayment = getState().checkout.preferredPayment;
        if (preferredPayment === 'PROPAY') {
            placeOrderRequest.hostedTransactionId = getState().payments.propay.hostedTransactionId;
        } else if (preferredPayment === 'SQUARE') {
            placeOrderRequest.squarePaymentNonce = getState().payments.square.nonce;
        } else if (preferredPayment === 'BLUEFIN') {
            placeOrderRequest.payConexToken = getState().payments.bluefin.paymentToken;
            placeOrderRequest.deviceTransactionId = getState().payments.bluefin.deviceTransId;
        }
    }
    return placeOrderRequest;
};

const getTicketDeliveryOptionType = (TicketDeliveryObject: any): any => {
    let ticketDeliveryOptionType = '';
    TicketDeliveryObject.forEach((eventList: any) => {
        eventList.ticketDeliveryOptions.forEach((ticket: any) => {
            if (ticketDeliveryOptionType !== 'normal') {
                if (ticket.deliveryOptions.length > 1) {
                    ticketDeliveryOptionType = 'normal';
                } else {
                    if (ticketDeliveryOptionType) {
                        if (ticketDeliveryOptionType !== ticket.deliveryOptions[0].deliveryOptionTitle) {
                            ticketDeliveryOptionType = 'normal';
                        }
                    } else {
                        ticketDeliveryOptionType = ticket.deliveryOptions[0].deliveryOptionTitle;
                    }
                }
            }
        });
    });
    return ticketDeliveryOptionType;
};

const getSelectedDeliveryType = (TicketDeliveryObject: any): any => {
    TicketDeliveryObject.ticketDeliveryDetails.forEach((event: any) => {
        event.ticketDeliveryOptions.forEach((ticket: any) => {
            if (ticket.deliveryOptions && ticket.deliveryOptions.length === 1) {
                ticket.selectedDeliveryType = ticket.deliveryOptions[0].deliveryOptionName;
            } else if (ticket.deliveryOptions && ticket.deliveryOptions.length > 1) {
                ticket.selectedDeliveryType = 'Print at Home';
            }
        });
    });

    return TicketDeliveryObject;
};

export function reserveTicketsMiddleware(dispatch: CallableFunction, getState: customTypes['getState']): any {
    dispatch(startLoader());
    let ticketDetail = getState().ticketDetails.purchasedTicketDetails.purchasedTicketList;
    let eventTicketList = getFormattedTicketList(ticketDetail);
    const customerId = Number(getFromSessionStorage('CUSTOMER_ID'));
    let cartReserveRequest: any = {};
    let reservationId = getCartReservationId();
    if (reservationId && !getState().app.showTimer) {
        // expired cart reservation id. Removing from session storage.
        setCartReservationId(0);
        reservationId = 0;
    }
    cartReserveRequest = {
        customerId,
        reservationId: reservationId ? reservationId : null,
        events: getCartReserveEventObject(eventTicketList)
    };
    reserveCartTask(cartReserveRequest, dispatch, getState);
}

const getCartReserveEventObject = (eventTicketList: any): any => {
    let cartReserveRequestArray: Array<any> = [];
    eventTicketList.forEach((eventTicket: any) => {
        let cartReserveRequestObj: any = {};
        cartReserveRequestObj.eventId = eventTicket.eventId;
        cartReserveRequestObj.eventName = eventTicket.eventName;
        eventTicket.showList.forEach((show: any) => {
            let tempObj = {
                showId: show.showId,
                tickets: getTicketFormatted(show)
            };
            if (cartReserveRequestObj.shows) {
                cartReserveRequestObj.shows.push(tempObj);
            } else {
                cartReserveRequestObj.shows = [tempObj];
            }
        });

        cartReserveRequestArray.push(cartReserveRequestObj);
    });
    return cartReserveRequestArray;
};

export function getTicketFormatted(ticketDetails: any): any {
    let ticketArray: Array<any> = [];
    ticketDetails.ticketList.forEach((ticket: any) => {
        let ticketObj = {
            combo: ticket.isCombo,
            count: ticket.count,
            ticketId: ticket.ticketId,
            ticketName: ticket.ticketName
        };
        if (ticketArray.length > 0) {
            ticketArray.push(ticketObj);
        } else {
            ticketArray = [ticketObj];
        }
    });
    return ticketArray;
}

const reserveCartTask = (reserveRequest: any, dispatch: CallableFunction, getState: customTypes['getState']) => {
    reserveCart(reserveRequest)
        .then((response) => {
            onTaskSuccess(dispatch, response, TASK_RESERVE_CART, getState, reserveRequest);
        })
        .catch((error) => {
            onTaskError(dispatch, error, TASK_RESERVE_CART);
        });
};

const onTaskSuccess = (dispatch: CallableFunction, response: any, task: number, getState: customTypes['getState'], request?: any): void => {
    dispatch(stopLoader());
    switch (task) {
        case TASK_GET_FEE_TAX:
            handleFeeTaxResponse(dispatch, response, task, getState);
            break;
        case TASK_PLACE_ORDER:
        case TASK_PLACE_ORDER_CART_RESERVATION:
        case TASK_PLACE_ORDER_GUEST:
        case TASK_PLACE_ORDER_GUEST_CART_RESERVATION:
            handlePlaceOrderResponse(dispatch, response, task, getState);
            break;
        case TASK_GET_DELIVERY_OPTION:
            let eventTicketDeliveryDetails = {
                ticketDeliveryDetails: response.data.responseObject,
                ticketDeliveryOptionType: getTicketDeliveryOptionType(response.data.responseObject)
            };
            if (eventTicketDeliveryDetails.ticketDeliveryOptionType === 'normal') {
                eventTicketDeliveryDetails = getSelectedDeliveryType(eventTicketDeliveryDetails);
            }
            dispatch(dispatch(setDeliveryOptions(eventTicketDeliveryDetails)));
            break;
        case TASK_GET_SALE_PERMISSION:
            dispatch(dispatch(setCustomerSalePermission(response.data.responseObject)));
            break;
        case TASK_GET_PREFERRED_PAYMENT:
            if (response.data.responseCode === 404) {
                dispatch(setValidationMessage(response.data.responseMessage));
            } else {
                dispatch(dispatch(setPreferredPayment(response.data.responseObject.preferredPayment)));
            }
            break;
        case TASK_RESERVE_CART:
            handleCartReservationResponse(request, response, dispatch, getState);
            break;
        case TASK_APPLY_PROMO:
            handleApplyPromoResponse(dispatch, response, getState, task);
            break;
        case TASK_RELEASE_PROMO:
            setPromoReservationDetails({});
            dispatch(resetPromo());
            getFeeTaxMiddleware(dispatch, getState);
            break;
        default:
            break;
    }
};

function handleCartReservationResponse(request: any, response: any, dispatch: CallableFunction, getState: customTypes['getState']) {
    if (response.data.responseObject) {
        let proceedToCheckout = true;
        if (isCartReservationEnabled() && response.data.responseObject.cartReservationId) {
            if (!getCartReservationId()) {
                // Initiating timer and setting cart reservation id
                if (!getState().app.showTimer) {
                    dispatch(setToastMessage(`The items in your cart will expire in ${getFormattedCartReservationTime()}`));
                    dispatch(setTimerVisibilityAction(true));
                    setCartReservationId(response.data.responseObject.cartReservationId);
                    setReservedTickets(request);
                } else {
                    // TODO - need to show an appropriate error message.
                    // some error occured because if cart reservation is enabled and no cart reservation id
                    // is saved already, it means that timer should have been hidden.
                    proceedToCheckout = false;
                }
            } else {
                setReservedTickets(request);
            }
        } else if (isCartReservationEnabled() && !response.data.responseObject.cartReservationId) {
            // error scenario - if cart reservation is enabled then cartReservationId should always be present in
            // the response. Otherwise it means some ticket in the request body of this api is not available now.
            proceedToCheckout = false;
            dispatch(setValidationMessage(response.data.responseMessage, true));
        }
        if (proceedToCheckout) {
            let customerId = btoa(getFromSessionStorage('CUSTOMER_ID'));
            history.push(getRedirectionUrl(PATH_CHECKOUT, { customerId }));
        }
    } else {
        dispatch(setValidationMessage(response.data.responseMessage, true));
    }
}

export function proceedToCheckoutMiddleware(dispatch: CallableFunction, getState: customTypes['getState']) {
    let tickets = getState().ticketDetails.purchasedTicketDetails.purchasedTicketList;
    dispatch(isValidMinOrderAction(tickets));
    if (!getState().app.validationMessage) {
        if (isValidCartMiddleware(dispatch, getState)) {
            if (getState().userDetails.isUserLoggedIn) {
                dispatch(reserveTicketsAction());
            } else {
                dispatch(toggleSigninCartViewAction());
            }
        }
    }
}

function handlePlaceOrderResponse(dispatch: CallableFunction, response: any, task: number, getState: customTypes['getState']) {
    if (response.data.responseCode === 0) {
        setToSessionStorage('PLACE_ORDER_DETAILS', response.data.responseObject);
        setToSessionStorage('PURCHASED_TICKET_DETAILS', {});
        if (task === TASK_PLACE_ORDER_CART_RESERVATION || task === TASK_PLACE_ORDER_GUEST_CART_RESERVATION) {
            dispatch(setTimerVisibilityAction(false));
            setCartReservationId(0);
            setPromoReservationDetails({});
            dispatch(resetPromo());
            // If the timer expiry happens just after place order has been triggered
            // There is a possibility that the place order might get through even if we show the timer
            // expired message on the front end. This is an edge case scenario, so we are hiding the message
            // and removing the redirection to event list if the place order got through.
            dispatch(setValidationMessage(''));
            dispatch(setTickeListRedirectionDetailsAction({ eventId: 0, showId: 0 }));
        }
        dispatch(setHostedTransactionId(''));
        dispatch(resetPaymentAction());
        dispatch(resetPurchasedTicketListAction());
        // set metric1 in GA - used to track number of place orders
        dispatch(setGoogleAnalyticsParam({ metric1: 1 }));
        let customerId = btoa(getFromSessionStorage('CUSTOMER_ID'));
        history.push(getRedirectionUrl(PATH_CHECKOUT_SUMMARY, { customerId }));
    } else {
        handlePlaceOrderErrorResponse(dispatch, response, getState, task);
        onTaskError(dispatch, response, task, '', false, true);
    }
}

function handlePlaceOrderErrorResponse(dispatch: CallableFunction, errorResponse: any, getState: customTypes['getState'], task: number): void {
    let feeDetails: any = getState().checkout.feeTaxDetails;
    let preferredPayment = getState().checkout.preferredPayment;
    if (errorResponse.data.responseCode === 504) {
        dispatch(setValidationMessage(ERROR_PLACE_ORDER_TIMEOUT, true));
    } else if (preferredPayment === 'PROPAY' && feeDetails.grandTotal && (errorResponse.data.responseCode === 17 || errorResponse.data.responseCode === 400)) {
        dispatch(setPropayPaymentStatus(true));
    } else if ([14, 15, 400].includes(errorResponse.data.responseCode)) {
        dispatch(setValidationMessage(errorResponse.data.responseMessage ? errorResponse.data.responseMessage : ERROR_PAYMENT_SALE));
    } else if ((task === TASK_PLACE_ORDER_CART_RESERVATION || task === TASK_PLACE_ORDER_GUEST_CART_RESERVATION) && errorResponse.data.responseCode === 112) {
        // 112 - CART_EXPIRED
        dispatch(releaseCartAction(true));
    } else if ((task === TASK_PLACE_ORDER_CART_RESERVATION || task === TASK_PLACE_ORDER_GUEST_CART_RESERVATION) && errorResponse.data.responseCode === 113) {
        // 113 - UNAUTH_ACCESS_CART_RESERVATION_ENABLED
        // resetting transaction details along with cart.
        dispatch(releaseCartAction(true, true));
    } else {
        dispatch(setValidationMessage(errorResponse.data.responseMessage ? errorResponse.data.responseMessage : ERROR_PLACE_ORDER));
    }
    if (feeDetails.grandTotal) {
        dispatch(incrementPaymentRetryCountAction());
    }
}

function handleFeeTaxResponse(dispatch: CallableFunction, response: any, task: number, getState: customTypes['getState']) {
    if (response.data.responseCode === 0) {
        let totalDiscount = 0;
        response.data.responseObject.feeTaxEventsList.forEach((event: any): void => {
            totalDiscount += event.discount;
        });
        response.data.responseObject.totalDiscount = totalDiscount;
        const feeTaxDetails = processFeeDetailsResponse(response.data.responseObject);
        dispatch(dispatch(setFeeTaxDetails(feeTaxDetails)));
    } else {
        onTaskError(dispatch, response, task);
    }
}

function handleApplyPromoResponse(dispatch: CallableFunction, response: any, getState: customTypes['getState'], task: number) {
    if (response.data.responseCode === 0) {
        const responseObj = response.data.responseObject;
        const reservedPromoDetails = getPromoReservationDetails();
        const appliedPromocode = reservedPromoDetails.promoList;
        setPromoReservationDetails(responseObj);
        if (responseObj.invalidPromoCodes.length) {
            const msg = responseObj.invalidPromoCodes.toString() + ' cannot be applied to the selected tickets. You may still proceed with your tickets';
            dispatchPromoCodeStatus(dispatch, true, msg);
            const validPromoSet = appliedPromocode.filter((x: string) => !responseObj.invalidPromoCodes.includes(x));
            setPromoList(validPromoSet);
        } else {
            dispatchPromoCodeStatus(dispatch, true, '');
        }
        getFeeTaxMiddleware(dispatch, getState);
    } else {
        onTaskError(dispatch, response, task);
    }
}
