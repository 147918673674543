import { GLOBAL_CONFIG } from './config';
import { BLUEFIN_SCRIPT_URL } from './apiEndPoints';

export function getBaseUrl() {
    return GLOBAL_CONFIG.ENV.BASE_URL;
}

export function getGoogleMapsUrl(): string {
    return GLOBAL_CONFIG.ENV.GOOGLE_MAP_URL;
}
export function getGooglePlacesUrl(): string {
    return GLOBAL_CONFIG.ENV.GOOGLE_PLACES_URL;
}
export function getCognitoSessionExpiryTime(): number {
    return GLOBAL_CONFIG.COGNITO_SESSION_EXPIRY_TIME;
}

export function getCognitoRefreshExpiryTime(): number {
    return GLOBAL_CONFIG.COGNITO_REFRESH_EXPIRY_TIME;
}

export function getIdentityPoolId(): string {
    return GLOBAL_CONFIG.ENV.AWS_IDENTITY_POOL_ID;
}

export function getCongnitoPoolData(): Object {
    return {
        UserPoolId: GLOBAL_CONFIG.ENV.COGNITO_POOL_DATA.USER_POOL_ID,
        ClientId: GLOBAL_CONFIG.ENV.COGNITO_POOL_DATA.CLIENT_ID
    };
}

export function getReCaptchaKey(): string {
    return GLOBAL_CONFIG.ENV.RECAPTCHA_KEY;
}

export function getAmqpBaseUrl(): string {
    return GLOBAL_CONFIG.ENV.AMQP_BASE_URL;
}

export function getAmqpUsername(): string {
    return GLOBAL_CONFIG.ENV.AMQP_USER_NAME;
}

export function getAmqpPassword(): string {
    return GLOBAL_CONFIG.ENV.AMQP_PASSWORD;
}

export function getSquareScriptUrl(): string {
    return GLOBAL_CONFIG.ENV.SQUARE_SCRIPT_URL;
}

export function getGATrackingId(): string {
    return GLOBAL_CONFIG.ENV.GA_TRACKING_ID;
}

export function getShareBaseUrl(): string {
    return GLOBAL_CONFIG.ENV.SHARE_BASE_URL;
}

/**
 * @name getPropayPaymentCssUrl
 * @desc get the payment css base url for hpp
 */
export function getPropayPaymentCssUrl(): string {
    return GLOBAL_CONFIG.ENV.PROPAY_PAYMENT_CSS_URL;
}

/**
 * @name getPropayPaymentReturnURL
 * @desc get the payment return url for propay
 */
export function getPropayPaymentReturnURL(): string {
    return `${window.location.origin}${window.location.pathname}#/paymentProgress`;
}

/**
 * @name getPropayBaseUrl
 * @desc get the propay base url for hpp
 */
export function getPropayBaseUrl(): string {
    return GLOBAL_CONFIG.ENV.PROPAY_BASE_URL;
}

export function getBluefinBaseUrl(): string {
    return GLOBAL_CONFIG.ENV.BLUEFIN_BASE_URL;
}

export function getBluefinScriptUrl(): string {
    return getBluefinBaseUrl() + BLUEFIN_SCRIPT_URL;
}

export function getFacebookShareBaseUrl(): string {
    return 'https://www.facebook.com/sharer/sharer.php';
}

export function getTwitterShareBaseUrl(): string {
    return 'https://twitter.com/share';
}

export function getFacebookAppId(): string {
    return '483960802288050';
}
