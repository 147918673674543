import {
    SAVE_CUSTOMER_ID,
    TOGGLE_SIGNUP,
    TOGGLE_SIGNIN,
    SET_VALIDATION_MESSAGE,
    INC_SERVICE_CALL_COUNT,
    DEC_SERVICE_CALL_COUNT,
    TOGGLE_SEARCH,
    SET_BACKGROUND_MESSAGE,
    TOGGLE_IS_CART_LOGIN,
    TOGGLE_FORGOT_PASSWORD,
    TOGGLE_CHANGE_PASSWORD,
    SET_GA_PARAM,
    RESET_GA_PARAM,
    SET_IS_RESET_TICKET_LIST_REQUIRED,
    RESET_SUPPORT_FORM,
    SET_TIMER_VISIBILITY,
    SET_TICKET_LIST_REDIRECTION_DETAILS
} from '../actions/app.action';
import store from '../../interfaces/store.Interface';

function saveHandler(state: store['app'], action: any): store['app'] {
    switch (action.type) {
        case SAVE_CUSTOMER_ID:
            return { ...state, customerId: action.customerId };
        case TOGGLE_SIGNUP:
            return { ...state, showSignupDialog: !state.showSignupDialog };
        case TOGGLE_SIGNIN:
            return { ...state, showSigninDialog: !state.showSigninDialog };
        case SET_VALIDATION_MESSAGE:
            return { ...state, validationMessage: action.validationMessage, htmlAlert: action.htmlAlert };
        case INC_SERVICE_CALL_COUNT:
            return { ...state, serviceCallProgressCount: state.serviceCallProgressCount + 1 };
        case DEC_SERVICE_CALL_COUNT:
            return { ...state, serviceCallProgressCount: state.serviceCallProgressCount <= 0 ? 0 : state.serviceCallProgressCount - 1 };
        case TOGGLE_SEARCH:
            return { ...state, toggleSearch: action.expandSearch };
        case SET_BACKGROUND_MESSAGE:
            return { ...state, message: action.message };
        default:
            return saveHandlerMore(state, action);
    }
}

function saveHandlerMore(state: store['app'], action: any): store['app'] {
    switch (action.type) {
        case TOGGLE_IS_CART_LOGIN:
            return { ...state, isCartLogin: !state.isCartLogin };
        case TOGGLE_FORGOT_PASSWORD:
            return { ...state, showForgotPasswordDialog: !state.showForgotPasswordDialog };
        case TOGGLE_CHANGE_PASSWORD:
            return { ...state, showChangePasswordDialog: !state.showChangePasswordDialog };
        case SET_GA_PARAM:
            return { ...state, googleAnalytics: action.gaObject };
        case RESET_GA_PARAM:
            return { ...state, googleAnalytics: {} };
        case SET_IS_RESET_TICKET_LIST_REQUIRED:
            return { ...state, isResetTicketListRequired: action.flag };
        case RESET_SUPPORT_FORM:
            return { ...state, resetSupportForm: action.status };
        case SET_TIMER_VISIBILITY:
            return { ...state, showTimer: action.status };
        case SET_TICKET_LIST_REDIRECTION_DETAILS:
            return { ...state, ticketListRedirectDetails: { ...action.data } };
        default:
            return { ...state };
    }
}

export default saveHandler;
