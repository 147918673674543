import { doLogin } from '../../core/middlewares/login.Middleware';
import { buyerDetails } from '../../interfaces/userDetails.Inerface';
import customTypes from '../../interfaces/customTypes.Interface';
/**
 * action types
 */
export const DO_LOGIN = 'DO_LOGIN';
export const SET_LOGGEDIN_STATUS = 'SET_LOGGEDIN_STATUS';
export const SET_SESSION_STATUS = 'SET_SESSION_STATUS';
export const SET_BUYER_DETAILS = 'SET_BUYER_DETAILS';

/**
 * action creators
 */
export function login() {
    return {
        type: DO_LOGIN
    };
}

export function setLoggedInStatus(loggedInStatus: boolean) {
    return {
        type: SET_LOGGEDIN_STATUS,
        loggedInStatus
    };
}

export function setSessionStatus(sessionStatus: boolean) {
    return {
        type: SET_SESSION_STATUS,
        sessionStatus
    };
}

export function setBuyerDetails(buyerDetails: buyerDetails) {
    return {
        type: SET_BUYER_DETAILS,
        buyerDetails
    };
}

export function loginAction(isCartLogin: boolean, userData: any) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        return doLogin(dispatch, isCartLogin, false, userData);
    };
}

export function setLoggedInStatusAction(loggedInStatus: boolean) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        dispatch(setLoggedInStatus(loggedInStatus));
        if (!loggedInStatus) {
            dispatch(setBuyerDetails({ buyerName: '', name: '', email: '', role: '' }));
        }
    };
}

export function setSessionStatusAction(sessionStatus: boolean) {
    return (dispatch: CallableFunction, getState: customTypes['getState']) => {
        dispatch(setSessionStatus(sessionStatus));
    };
}
